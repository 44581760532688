import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  > header {
    height: 144px;
    background: #28262e;

    display: flex;
    align-items: center;

    div {
      width: 100%;
      max-width: 1120px;
      margin: 0 auto;

      svg {
        color: #999591;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const Panel = styled.div`
  background: var(--background-panel);

  top: -50px;
  border-radius: 8px;
  position: relative;
  margin: 0px auto;
  height: fit-content;
  width: 100%;
  max-width: 600px;
  padding: 24px;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
  & > form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
  }
`;

export const HeaderPageDatail = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  & div + div {
    margin: 8px 32px 0px 32px;
    justify-content: flex-start;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  margin: -176px auto 0;

  width: 100%;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 24px;
      font-size: 2rem;
      text-align: left;
    }

    a {
      display: block;
      color: #f4ede8;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }
`;

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;

  img {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: var(--primary);
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    transition: background-color 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: var(--text);
    }

    &:hover {
      background: 'var(--primary)';
    }
  }
`;
