import { IPaginate, IParams } from 'shared/interfaces';
import { IConta, IContaForm } from 'pages/Conta/interfaces';

import api from '../../api';

const getConta = async (id: string): Promise<IConta> => {
  const response = await api.get<IConta>(`/contas/${id}`);

  return response.data;
};

const getContas = async (params: IParams): Promise<IPaginate<IConta>> => {
  const response = await api.get<IPaginate<IConta>>('/contas', { params });

  return response.data;
};

const createConta = async (conta: IContaForm): Promise<void> => {
  await api.post('/contas', conta);
};

const updateConta = async (id: string, conta: IContaForm): Promise<IConta> => {
  const response = await api.put<IConta>(`/contas/${id}`, conta);

  return response.data;
};

const deleteConta = async (id: string): Promise<void> => {
  await api.delete(`/contas/${id}`);
};

export { getConta, getContas, createConta, updateConta, deleteConta };
