import styled, { css } from 'styled-components';

interface ContainerProps {
  preview: string;
}

export const Container = styled.label<ContainerProps>`
  margin-bottom: 20px;
  border: 1px dashed #ddd;
  cursor: pointer;
  height: 160px;

  ${({ preview }) =>
    preview
      ? css`
          background-image: url(${preview});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        `
      : ''}

  display: flex;
  justify-content: center;
  align-items: center;

  > input {
    display: none;
  }

  > svg {
    fill: var(--text);
  }
`;
