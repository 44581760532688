import styled from 'styled-components';

export const Container = styled.div`
  > h3 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 8px;
  }

  > p {
    font-size: 1.4rem;
    margin-bottom: 8px;
  }
`;
