import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ContasPagarPessoalList } from './List';
import { ContasPagarPessoalForm } from './Form';
import { ContasPagarPessoalPrint } from './Print';

export const ContasPagarPessoal: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/contas-pagar-pessoal"
        component={ContasPagarPessoalList}
        isPrivate
        exact
      />
      <Route
        path="/contas-pagar-pessoal/new"
        component={ContasPagarPessoalForm}
        isPrivate
      />
      <Route
        path="/contas-pagar-pessoal/print"
        component={ContasPagarPessoalPrint}
        isPrivate
      />
      <Route
        path="/contas-pagar-pessoal/edit/:id"
        component={ContasPagarPessoalForm}
        isPrivate
      />
    </Switch>
  );
};
