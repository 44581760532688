import React from 'react';

import { AuthProvider } from '../AuthProvider';
import { ToastProvider } from '../ToastProvider';
import { ThemeProvider } from '../ThemeProvider';

export const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ThemeProvider>
      <ToastProvider>{children}</ToastProvider>
    </ThemeProvider>
  </AuthProvider>
);
