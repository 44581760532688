import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ContasReceberList } from './List';
import { ContasReceberForm } from './Form';
import { ContasReceberPrint } from './Print';

export const ContasReceber: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/contas-receber"
        component={ContasReceberList}
        isPrivate
        exact
      />
      <Route
        path="/contas-receber/new"
        component={ContasReceberForm}
        isPrivate
      />
      <Route
        path="/contas-receber/print"
        component={ContasReceberPrint}
        isPrivate
      />
      <Route
        path="/contas-receber/edit/:id"
        component={ContasReceberForm}
        isPrivate
      />
    </Switch>
  );
};
