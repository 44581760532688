import { ISignIn } from '../../../pages/SignIn/interfaces/index';
import api from '../../api';

export const singIn = async ({ email, password }: ISignIn) => {
  return api
    .post('sessions', { email, password })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
