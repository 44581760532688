import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdCheckCircle, MdError, MdLink } from 'react-icons/md';

import * as S from './styles';

interface UploadFile {
  file: File;
  id: string;
  name: string;
  readableSize: string;
  preview: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  url: string | undefined;
}

interface Props {
  files: UploadFile[];
  onDelete(id: string): Promise<void>;
}

export const FileList: React.FC<Props> = ({ files, onDelete }) => {
  return (
    <S.Container>
      {files.map((uploadedFile) => (
        <li key={uploadedFile.id}>
          <S.FileInfo>
            <S.Preview src={encodeURI(uploadedFile.preview)} />
            <div>
              <strong>{uploadedFile.name}</strong>
              <span>
                {uploadedFile.readableSize}{' '}
                {!!uploadedFile.url && (
                  <button
                    type="button"
                    onClick={() => onDelete(uploadedFile.id)}
                  >
                    Excluir
                  </button>
                )}
              </span>
            </div>
          </S.FileInfo>

          <div>
            {!uploadedFile.uploaded && !uploadedFile.error && (
              <CircularProgressbar
                value={uploadedFile.progress}
                styles={{
                  root: { width: 48 },
                  text: {
                    fill: 'var(--primary)',
                    fontSize: 32,
                  },
                  path: { stroke: 'var(--primary)' },
                }}
                text={`${uploadedFile.progress}%`}
                strokeWidth={10}
              />
            )}

            {uploadedFile.url && (
              <a
                href={uploadedFile.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdLink
                  style={{ marginRight: 8 }}
                  size={24}
                  color="var(--text)"
                />
              </a>
            )}

            {uploadedFile.uploaded && (
              <MdCheckCircle size={24} color="var(--success)" />
            )}
            {uploadedFile.error && <MdError size={24} color="var(--danger)" />}
          </div>
        </li>
      ))}
    </S.Container>
  );
};
