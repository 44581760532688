import React, { useState, useEffect, useCallback } from 'react';
import { models, IEmbedConfiguration, service } from 'powerbi-client';
import 'powerbi-report-authoring';

import { embedded } from 'services/api/analytics';

import * as S from './styles';
import './style.css';

export const RaioX: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const workspace_id = process.env.REACT_APP_ANALYTICS_WORKSPACE_ID || '';
  const report_id = process.env.REACT_APP_ANALYTICS_RAIOX || '';

  const [reportConfig, setReportConfig] = useState<IEmbedConfiguration>({
    type: 'report',
    id: report_id,
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: '',
    settings: {
      panes: {
        pageNavigation: {
          visible: false,
        },
        filters: {
          expanded: false,
          visible: false,
        },
      },
      // background: models.BackgroundType.Transparent,
    },
  });

  const embeddedToken = useCallback(async () => {
    setLoading(true);

    try {
      const response = await embedded({ report_id, workspace_id });

      setReportConfig({
        ...reportConfig,
        id: report_id,
        accessToken: response.token,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report_id, workspace_id]);

  useEffect(() => {
    embeddedToken();
  }, [embeddedToken]);

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    [
      'loaded',
      () => {
        // Quando relatorio esta carregando
        //  console.log('Report has loaded');
      },
    ],
    [
      'rendered',
      () => {
        // Quando o relatorio finaliza
        // console.log('The report is rendered');
      },
    ],
    [
      'error',
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  return (
    <S.Container>
      {!loading && (
        <S.Embedded
          embedConfig={reportConfig}
          eventHandlers={eventHandlersMap}
          cssClassName="report-style-class"
          // getEmbeddedComponent={(embedObject: Embed) => {
          //   console.log(
          //     `Embedded object of type "${embedObject.embedtype}" received`,
          //   );
          // }}
        />
      )}
    </S.Container>
  );
};
