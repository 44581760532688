import React, { useEffect, useMemo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { GrDocumentPdf } from 'react-icons/gr';

import * as S from './styles';
import './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  fileUrl?: string;
  fileSelected?: FileList;
}

const UploadBase: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { fileUrl = '', fileSelected = null, ...rest },
  ref,
) => {
  const [preview, setPreview] = React.useState<string | ArrayBuffer>();

  useEffect(() => {
    setPreview('');
    if (fileSelected && fileSelected.length > 0) {
      const file = fileSelected[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);

      // eslint-disable-next-line func-names
      reader.onload = function () {
        if (reader.result) {
          setPreview(reader.result);
        }
      };
    }
  }, [fileSelected, ref]);

  const renderPdf = useMemo(() => {
    if (preview) {
      return (
        <Document file={preview}>
          <Page pageNumber={1} height={360} />
        </Document>
      );
    }

    if (!preview && fileUrl) {
      return (
        <Document file={fileUrl}>
          <Page pageNumber={1} height={360} />
        </Document>
      );
    }

    return <GrDocumentPdf size={80} />;
  }, [preview, fileUrl]);

  return (
    <S.Container>
      {renderPdf}

      <input type="file" accept="application/pdf" ref={ref} {...rest} />
    </S.Container>
  );
};

export const UploadPdf = React.forwardRef(UploadBase);
