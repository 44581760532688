import styled, { css } from 'styled-components';

const dragActive = css`
  border-color: var(--success);
`;

const dragReject = css`
  border-color: var(--danger);
`;

interface DropContainerProps {
  isDragActive: boolean;
  isDragReject: boolean;
}

export const DropContainer = styled.div<DropContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;
  ${({ isDragActive }) => isDragActive && dragActive};
  ${({ isDragReject }) => isDragReject && dragReject};
`;

const messageColors = {
  default: '#999',
  error: 'var(--danger)',
  success: 'var(--success)',
};

interface UploadMessageProps {
  type: 'default' | 'error' | 'success';
}

export const UploadMessage = styled.p<UploadMessageProps>`
  display: flex;
  color: ${({ type }) => messageColors[type]};
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;
