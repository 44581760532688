import { IOptionReactSelect } from '../shared/interfaces/index';

interface IValeus {
  id: string;
  name: string;
}

export const reactSelectMapper = (values: IValeus[]): IOptionReactSelect[] => {
  if (values.length <= 0) {
    return [];
  }

  return values?.map((item) => ({ label: item.name, value: item.id }));
};
