export const plansMock = [
  { label: 'Plano Bronze', value: '1' },
  { label: 'Plano Prata', value: '2' },
  { label: 'Plano Ouro', value: '3' },
];

export const profilesMock = [
  { label: 'Administrador', value: '1' },
  { label: 'Gerente', value: '2' },
  { label: 'Supervisor', value: '3' },
  { label: 'Cliente', value: '4' },
];
