import React from 'react';

import * as S from './styles';

interface Props {
  title: string;
  description?: string;
}

export const ToastMsg: React.FC<Props> = ({ title, description }) => {
  return (
    <S.Container>
      <h3>{title}</h3>
      <p>{description}</p>
    </S.Container>
  );
};
