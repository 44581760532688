import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { ImScissors } from 'react-icons/im';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';
import axios from 'axios';
import FileDownload from 'js-file-download';

import { onDelay } from 'utils/debounce';

import { IOptionReactSelect } from 'shared/interfaces';

import { getCostureiras } from 'services/api/fornecedor';
import { getProdutos } from 'services/api/produto';
import { getRelatorioEnvioCorte } from 'services/api/cortes';

import { useToast } from 'contexts';
import * as C from 'components';

import * as S from './styles';

import { IRelatorioEnvioCorteForm } from '../interfaces';

export const EnvioCortePrint: React.FC = () => {
  const { addToast } = useToast();

  const currentDate = new Date();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<FieldValues>({
    defaultValues: {
      data_envio_inicial: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
      ),
      data_envio_final: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0,
      ),
    },
  });

  const [loading, setLoading] = useState(false);

  const loadOptionsProdutos = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getProdutos({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsFornecedores = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getCostureiras({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const onSubmit = useCallback(
    async (data: IRelatorioEnvioCorteForm) => {
      try {
        setLoading(true);

        const response = await getRelatorioEnvioCorte(data);

        if (response.link) {
          axios({
            url: `${response.link}`,
            method: 'GET',
            responseType: 'blob',
          }).then((relatorio) => {
            FileDownload(relatorio.data, 'Envio corte.pdf');
          });
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/envio-corte">
              <MdKeyboardBackspace size={24} color="#fff" />
              Envio corte
            </Link>
          </S.BackLink>

          <C.HeaderInfo title="Relatorios envio de corte" icon={ImScissors} />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <C.FormGroup>
              <C.Label>Data envio inicial</C.Label>
              <C.InputDatePicker
                {...register('data_envio_inicial')}
                control={control}
                errors={errors.data_envio_inicial}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_envio_inicial')}
                name="data_envio_inicial"
                placeholder="Data envio"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Data envio final</C.Label>
              <C.InputDatePicker
                {...register('data_envio_final')}
                control={control}
                errors={errors.data_envio_final}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_envio_final')}
                name="data_envio_final"
                placeholder="Data envio"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Costureira</C.Label>
              <C.ReactSelectAsync
                {...register('fornecedor_id')}
                control={control}
                errors={errors.fornecedor_id}
                loadOptions={loadOptionsFornecedores}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum costureira localizada"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Produto</C.Label>
              <C.ReactSelectAsync
                {...register('produto_id')}
                control={control}
                errors={errors.produto_id}
                loadOptions={loadOptionsProdutos}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum produto localizado"
              />
            </C.FormGroup>
          </S.FormContainer>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Gerar relatorio"
          >
            Gerar relatório
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
