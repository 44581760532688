import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import { IRelatorioReceberConsolidado } from 'pages/ContasReceberRelatorios/interfaces';

import { formatCurrencyBR } from 'utils/helpers';

const getConsolidado = async (
  params: IParams,
): Promise<IPaginate<IRelatorioReceberConsolidado>> => {
  const response = await api.get<IPaginate<IRelatorioReceberConsolidado>>(
    '/relatorios/contas-receber/consolidado',
    {
      params,
    },
  );

  const titulosFormatado = response.data.data.map(
    (item: IRelatorioReceberConsolidado) => {
      return {
        ...item,
        valor_titulo: formatCurrencyBR(Number(item.valor_titulo)),
        valor_juros: formatCurrencyBR(Number(item.valor_juros)),
        valor_acrescimo: formatCurrencyBR(Number(item.valor_acrescimo)),
        valor_desconto: formatCurrencyBR(Number(item.valor_desconto)),
        valor_pago: formatCurrencyBR(Number(item.valor_pago)),
        valor_saldo: formatCurrencyBR(
          parseFloat(item.valor_pago) - parseFloat(item.valor_titulo),
        ),
      };
    },
  );

  return {
    ...response.data,
    data: titulosFormatado,
  };
};

export { getConsolidado };
