import React, { createContext, useContext, useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ToastMsg } from 'components';
import { IToastConextData, IToastMessage } from './types';

const ToastContext = createContext<IToastConextData>({} as IToastConextData);

const ToastProvider: React.FC = ({ children }) => {
  const addToast = useCallback(
    ({ type, title, description }: Omit<IToastMessage, 'id'>) => {
      const options: ToastOptions = {
        toastId: uuid(),
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };

      switch (type) {
        case 'success':
          toast.success(
            <ToastMsg title={title} description={description} />,
            options,
          );
          break;
        case 'error':
          toast.error(
            <ToastMsg title={title} description={description} />,
            options,
          );
          break;
        case 'info':
          toast.info(
            <ToastMsg title={title} description={description} />,
            options,
          );
          break;
        case 'warning':
          toast.warn(
            <ToastMsg title={title} description={description} />,
            options,
          );
          break;
        case 'dark':
          toast.dark(
            <ToastMsg title={title} description={description} />,
            options,
          );
          break;

        default:
          toast(<ToastMsg title={title} description={description} />, options);
          break;
      }
    },
    [],
  );

  return (
    <ToastContext.Provider
      value={{
        addToast,
      }}
    >
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
};

function useToast(): IToastConextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
