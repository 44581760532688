import styled from 'styled-components';

export const Container = styled.div``;
export const PopUpButton = styled.button`
  background: none;
  border: none;
  height: 25px;
  width: 20px;
  /* align-self: center;
  margin-left: 75%; */
`;

export const PopUpMenuConainer = styled.div`
  button {
    color: var(--text);
    width: 100%;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    padding: 8px;
    transition: background 0.2s;
    &:hover {
      color: var(--text-item-menu-hover) !important;
      background: var(--backgrount-item-menu-hover);
      font-weight: 700;

      svg {
        margin-right: 8px;
        color: var(--text-item-menu-hover) !important;
        font-weight: 700;
      }
    }
  }

  a {
    text-decoration: none;
    color: var(--text);
    width: 100%;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    padding: 8px;
    transition: background 0.2s;
    &:hover {
      color: var(--text-item-menu-hover) !important;
      background: var(--backgrount-item-menu-hover);
      font-weight: 700;

      svg {
        margin-right: 8px;
        color: var(--text-item-menu-hover) !important;
        font-weight: 700;
      }
    }
  }
`;
