import styled from 'styled-components';

export const Container = styled.div`
  /* padding: 16px 24px 10px; */
  background: var(--background);
  color: var(--text);
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2rem;
`;
