import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FiLogIn, FiMail } from 'react-icons/fi';
import * as Yup from 'yup';

import { forgotPassword } from 'services/api/ForgotPassword';
import { useToast } from 'contexts';

import * as C from 'components';
import logoImg from 'assets/logo.png';
import * as S from './styles';

import { IForgotPassword } from './interfaces';

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Email obrigatório')
    .email('Digite um e-mail válido'),
});

export const ForgotPassword: React.FC = () => {
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPassword>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (data: IForgotPassword) => {
      setLoading(true);
      try {
        forgotPassword(data).then(() => {
          addToast({
            type: 'success',
            title: 'E-mail de recuperação enviando',
            description:
              'Enviamos um e-mail para confirmar a recuperação de senha, cheque sua caixa de entrada',
          });
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro na recuperação de senha',
          description:
            'Ocorreu um erro ao tentar realizar a recuperação de senha, tente novamente',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <S.Container>
      <S.Content>
        <S.AnimationContainer>
          <img src={logoImg} alt="Zilook Lingerie" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Recuperar senha</h1>
            <C.Input
              type="text"
              {...register('email')}
              errors={errors.email}
              name="email"
              icon={FiMail}
              placeholder="E-mail"
            />
            <C.Button loading={loading} type="submit">
              Recuperar
            </C.Button>
          </form>

          <Link to="/signin">
            <FiLogIn />
            Voltar ao login
          </Link>
        </S.AnimationContainer>
      </S.Content>
      <S.Background />
    </S.Container>
  );
};
