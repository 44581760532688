import React from 'react';

import * as S from './styles';

interface LoaderProps {
  loading?: boolean;
  size?: number;
  color?: string;
  align?: 'left' | 'center' | 'right';
  message?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  loading = false,
  size = 60,
  color = '',
  align = 'left',
  message = '',
}) => {
  if (!loading) return null;
  return (
    <S.Container align={align}>
      <S.Spinner size={size} color={color} />
      <span>{message}</span>
    </S.Container>
  );
};
