import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaBoxOpen } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import { createProduto, getProduto, updateProduto } from 'services/api/produto';
import { getCores } from 'services/api/produtoCor';
import { getFamilias } from 'services/api/produtoFamilia';

import { useToast } from 'contexts';

import * as C from 'components';
import * as S from './styles';

import { IProduto, IProdutoForm } from '../interfaces';

const schema = Yup.object().shape({
  nome: Yup.string().required('Descrição obrigatória'),
  preco: Yup.string()
    .typeError('Preço é obrigatório')
    .required('Preço é obrigatório'),
  preco_custo: Yup.string(),
  preco_catalogo: Yup.string(),
  preco_custo_costureira: Yup.string(),
  valor_lucro: Yup.string(),
  foto_upload: Yup.mixed(),
  quantidade_kit: Yup.string()
    .typeError('Quantidade é obrigatório')
    .required('Quantidade é obrigatório'),
});

export const ProdutosForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      quantidade_kit: '1,00',
    },
  });

  const [loading, setLoading] = useState(false);
  const [produto, setProduto] = useState<IProduto>();

  useEffect(() => {
    if (id) {
      getProduto(id).then((data) => {
        setProduto(data);

        reset(data);
      });
    }
  }, [id, reset]);

  const loadOptionsProdutoCores = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getCores({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsProdutoFamilias = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getFamilias({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const onSubmit = useCallback(
    async (data: IProdutoForm) => {
      try {
        setLoading(true);

        if (id) {
          const response = await updateProduto(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/produtos');
          }
        } else {
          await createProduto(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/produtos');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/produtos">
              <MdKeyboardBackspace size={24} color="#fff" />
              Produtos
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={id ? `Editando: ${produto?.nome}` : 'Novo Produto'}
            icon={FaBoxOpen}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.Upload
            {...register('foto_upload')}
            imageUrl={produto?.foto_url}
            fileSelected={watch('foto_upload')}
          />

          <S.FormContainer>
            <C.FormGroup>
              <C.Label>Descrição</C.Label>
              <C.Input
                type="text"
                {...register('nome')}
                errors={errors.nome}
                name="nome"
                placeholder="Descrição"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Familia</C.Label>
              <C.ReactSelectAsync
                {...register('familia_id')}
                control={control}
                errors={errors.familia_id}
                loadOptions={loadOptionsProdutoFamilias}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum registro localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Cor</C.Label>
              <C.ReactSelectAsync
                {...register('cor_id')}
                control={control}
                errors={errors.cor_id}
                loadOptions={loadOptionsProdutoCores}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum registro localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Preço</C.Label>
              <C.InputCurrency
                {...register('preco')}
                errors={errors.preco}
                name="preco"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Preço Catálogo</C.Label>
              <C.InputCurrency
                {...register('preco_catalogo')}
                errors={errors.preco_catalogo}
                name="preco_catalogo"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Preço de Custo</C.Label>
              <C.InputCurrency
                {...register('preco_custo')}
                errors={errors.preco_custo}
                name="preco_custo"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Preço de Custo Costureira</C.Label>
              <C.InputCurrency
                {...register('preco_custo_costureira')}
                errors={errors.preco_custo_costureira}
                name="preco_custo_costureira"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor Lucro</C.Label>
              <C.InputCurrency
                {...register('valor_lucro')}
                errors={errors.valor_lucro}
                name="valor_lucro"
                placeholder="Ex: 1,00"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Quantidade Kit</C.Label>
              <C.InputCurrency
                {...register('quantidade_kit')}
                errors={errors.quantidade_kit}
                name="quantidade_kit"
                placeholder="Ex: 1,00"
              />
            </C.FormGroup>
          </S.FormContainer>

          <C.FormGroup>
            <C.Checkbox
              {...register('envia_mobile')}
              name="envia_mobile"
              items={[{ id: 1, name: 'Envia Mobile?' }]}
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Checkbox
              {...register('grade')}
              name="grade"
              items={[{ id: 1, name: 'Possui Grade?' }]}
            />
          </C.FormGroup>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
