import React from 'react';

import imgDefault from 'assets/image.png';

import * as S from './styles';

interface ImageProps {
  src?: string;
  alt?: string;
  width?: string;
  height?: string;
  rounded?: boolean;
  roundedCircle?: boolean;
}

export const Image: React.FC<ImageProps> = ({
  src,
  alt,
  width = '50',
  height = '50',
  rounded = false,
  roundedCircle = false,
  ...rest
}) => {
  return (
    <S.Container rounded={rounded} roundedCircle={roundedCircle}>
      <img
        {...rest}
        width={width}
        height={height}
        src={src || imgDefault}
        alt={alt}
      />
    </S.Container>
  );
};
