import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ImScissors } from 'react-icons/im';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import { createCorte, getEnvioCorte, updateCorte } from 'services/api/cortes';

import { getCostureiras } from 'services/api/fornecedor';
import { getProdutos } from 'services/api/produto';

import { useToast } from 'contexts';
import { formatCurrencyDataBase, formatCurrencyBR } from 'utils/helpers';

import * as C from 'components';
import * as S from './styles';

import { IEnvioCorte, IEnvioCorteForm } from '../interfaces';

const schema = Yup.object().shape({
  numero_corte: Yup.string()
    .typeError('Número do corte obrigatório')
    .required('Número do corte obrigatório'),
  fornecedor_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Costureira obrigatorio')
    .typeError('Costureira obrigatorio'),
  produto_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Produto obrigatorio')
    .typeError('Produto obrigatorio'),
  data_envio: Yup.string()
    .typeError('Data envio obrigatório')
    .required('Data envio obrigatório'),
  quantidade_envio: Yup.string()
    .typeError('Quantidade é obrigatório')
    .required('Quantidade é obrigatório'),
  preco_custo_costureira: Yup.string()
    .typeError('Preço custo obrigatório')
    .required('Preço custo obrigatório'),
  valor_total_envio: Yup.string()
    .typeError('Valor total obrigatório')
    .required('Valor total obrigatório'),
});

export const EnvioCorteForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
    setError,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [corte, setCorte] = useState<IEnvioCorte>();

  const quantidadeEnvio = watch('quantidade_envio');
  const precoCusto = watch('preco_custo_costureira');
  const produtoId = watch('produto_id');

  useEffect(() => {
    if (produtoId?.preco) {
      setValue('preco_custo_costureira', produtoId.preco);
      setValue('valor_total_envio', produtoId.preco * quantidadeEnvio);
    } else {
      setValue('preco_custo_costureira', '0,00');
      setValue('valor_total_envio', '0,00');
    }
  }, [produtoId, quantidadeEnvio, setValue]);

  useEffect(() => {
    const total =
      formatCurrencyDataBase(quantidadeEnvio) *
      formatCurrencyDataBase(precoCusto);

    setValue('valor_total_envio', formatCurrencyBR(total, false));
  }, [precoCusto, quantidadeEnvio, setValue]);

  const loadOptionsProdutos = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getProdutos({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
            preco: item.preco_custo_costureira,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsCostureiras = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getCostureiras({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  useEffect(() => {
    if (id) {
      getEnvioCorte(id).then((data) => {
        setCorte(data);

        reset(data);
      });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data: IEnvioCorteForm) => {
      try {
        setLoading(true);

        if (id) {
          if (!data.fornecedor_id?.value) {
            setError('fornecedor_id', { message: 'Fornecedor obrigatorio' });

            return;
          }

          if (!data.produto_id?.value) {
            setError('produto_id', {
              message: 'Produto obrigatorio',
            });

            return;
          }

          if (data.quantidade_envio === '0,00') {
            setError('quantidade_envio', {
              message: 'Quantidade é obrigatório',
            });

            return;
          }

          if (data.preco_custo_costureira === '0,00') {
            setError('preco_custo_costureira', {
              message: 'Preço custo é obrigatório',
            });

            return;
          }

          if (data.valor_total_envio === '0,00') {
            setError('valor_total_envio', {
              message: 'Valor total maior que 0 obrigatório',
            });

            return;
          }

          const response = await updateCorte(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/envio-corte');
          }
        } else {
          if (!data.fornecedor_id?.value) {
            setError('fornecedor_id', { message: 'Fornecedor obrigatorio' });

            return;
          }

          if (!data.produto_id?.value) {
            setError('produto_id', {
              message: 'Produto obrigatorio',
            });

            return;
          }

          if (data.quantidade_envio === '0,00') {
            setError('quantidade_envio', {
              message: 'Quantidade é obrigatório',
            });

            return;
          }

          if (data.preco_custo_costureira === '0,00') {
            setError('preco_custo_costureira', {
              message: 'Preço custo é obrigatório',
            });

            return;
          }

          if (data.valor_total_envio === '0,00') {
            setError('valor_total_envio', {
              message: 'Valor total maior que 0 obrigatório',
            });

            return;
          }

          await createCorte(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/envio-corte');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset, setError],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/envio-corte">
              <MdKeyboardBackspace size={24} color="#fff" />
              Envio Corte
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={id ? `Editando corte: ${corte?.numero_corte}` : 'Novo corte'}
            icon={ImScissors}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Numero Corte</C.Label>
            <C.Input
              type="text"
              {...register('numero_corte')}
              errors={errors.numero_corte}
              name="numero_corte"
              placeholder="Número corte"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Costureira</C.Label>
            <C.ReactSelectAsync
              {...register('fornecedor_id')}
              control={control}
              errors={errors.fornecedor_id}
              loadOptions={loadOptionsCostureiras}
              isClearable
              isSearchable
              loadingMessage="Buscando..."
              noOptionsMessage="Nenhuma costureira localizada"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Produto</C.Label>
            <C.ReactSelectAsync
              {...register('produto_id')}
              control={control}
              errors={errors.produto_id}
              loadOptions={loadOptionsProdutos}
              isClearable
              isSearchable
              loadingMessage="Buscando..."
              noOptionsMessage="Nenhum produto localizado"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Data Envio</C.Label>
            <C.InputDatePicker
              {...register('data_envio')}
              control={control}
              errors={errors.data_envio}
              dateFormat="dd/MM/yyyy"
              selected={watch('data_envio')}
              name="data_envio"
              placeholder="Data Envio"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Preço custo</C.Label>
            <C.InputCurrency
              {...register('preco_custo_costureira')}
              errors={errors.preco_custo_costureira}
              name="preco_custo_costureira"
              placeholder="Ex: 19,90"
              readOnly
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Quantidade</C.Label>
            <C.InputCurrency
              {...register('quantidade_envio')}
              errors={errors.quantidade_envio}
              name="quantidade_envio"
              placeholder="Ex: 50"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Valor total</C.Label>
            <C.InputCurrency
              {...register('valor_total_envio')}
              errors={errors.valor_total_envio}
              name="valor_total_envio"
              placeholder="Quantidade * Preço custo"
              readOnly
            />
          </C.FormGroup>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
