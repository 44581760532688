import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import {
  IRelatorioVendasForm,
  IVendas,
  IVendasForm,
} from 'pages/Vendas/interfaces';

import { formatCurrencyBR, formatCurrencyDataBase } from 'utils/helpers';
import { CustomFormats, toFormatDate } from '../../../utils/dateHelpers';

const createVendaData = (venda: IVendasForm) => {
  const itens = venda.itens_venda.map((item) => {
    return {
      produto_id: item.produto_id.value,
      quantidade: formatCurrencyDataBase(item.quantidade),
      preco: formatCurrencyDataBase(item.preco),
      valor_total: formatCurrencyDataBase(item.valor_total),
    };
  });

  const data = {
    cliente_id: venda.cliente_id.value,
    forma_pagamento_id: venda.forma_pagamento_id.value,
    data_emissao: venda.data_emissao,
    data_vencimento: venda.data_vencimento,
    itens_venda: itens,
  };

  return data;
};

const getVenda = async (id: string): Promise<IVendas> => {
  const { data } = await api.get<IVendas>(`/vendas/${id}`);

  const itens = data.itens_venda.map((item) => {
    return {
      produto_id: item?.produto?.id
        ? {
            label: String(item?.produto?.nome) || '',
            value: String(item?.produto?.id) || '',
            preco: formatCurrencyBR(Number(item.preco), false),
          }
        : '',
      quantidade: formatCurrencyBR(Number(item.quantidade), false),
      preco: formatCurrencyBR(Number(item.preco), false),
      valor_total: formatCurrencyBR(Number(item.valor_total), false),
    };
  });

  const vendaFormatada = {
    ...data,
    valor_total: formatCurrencyBR(Number(data.valor_total), false),
    data_emissao: data.data_emissao ? new Date(data.data_emissao) : null,

    data_vencimento: data.contas_receber[0]?.data_vencimento
      ? new Date(data.contas_receber[0]?.data_vencimento)
      : null,

    cliente_id: data?.cliente?.id
      ? {
          label: String(data?.cliente?.nome) || '',
          value: String(data?.cliente?.id) || '',
        }
      : '',
    forma_pagamento_id: data?.forma_pagamento?.id
      ? {
          label: String(data?.forma_pagamento?.nome) || '',
          value: String(data?.forma_pagamento?.id) || '',
        }
      : '',
    itens_venda: itens,
  };

  return vendaFormatada;
};

const getVendas = async (params: IParams): Promise<IPaginate<IVendas>> => {
  const response = await api.get<IPaginate<IVendas>>('/vendas', {
    params,
  });

  const vendasFormatada = response.data.data.map((item: IVendas) => {
    return {
      ...item,
      valor_total: formatCurrencyBR(Number(item.valor_total)),

      data_emissao: item.data_emissao
        ? toFormatDate(new Date(item.data_emissao), CustomFormats.dd_MM_yyyy)
        : null,
    };
  });

  return {
    ...response.data,
    data: vendasFormatada,
  };
};

const createVenda = async (venda: IVendasForm): Promise<void> => {
  const data = createVendaData(venda);

  await api.post('/vendas', data);
};

const updateVenda = async (
  id: string,
  venda: IVendasForm,
): Promise<IVendas> => {
  const data = createVendaData(venda);

  const response = await api.put<IVendas>(`/vendas/${id}`, data);

  return response.data;
};

const deleteVenda = async (id: string): Promise<void> => {
  await api.delete(`/vendas/${id}`);
};

const getRelatorioVendas = async (
  params: IRelatorioVendasForm,
): Promise<{ link: string }> => {
  const data = {
    ...params,
    cliente_id: params.cliente_id ? params.cliente_id.value : '',
    forma_pagamento_id: params.forma_pagamento_id
      ? params.forma_pagamento_id.value
      : '',
    data_venda_inicial: params.data_venda_inicial
      ? new Date(params.data_venda_inicial)
      : '',
    data_venda_final: params.data_venda_final
      ? new Date(params.data_venda_final)
      : '',
  };

  const response = await api.post(`/relatorios/vendas/vendas-sintetica`, data);

  return response.data;
};

export {
  getVenda,
  getVendas,
  createVenda,
  updateVenda,
  deleteVenda,
  getRelatorioVendas,
};
