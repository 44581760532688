import styled from 'styled-components';

export const Container = styled.label`
  margin-bottom: 20px;
  border: 1px dashed #ddd;
  cursor: pointer;
  height: 360px;

  display: flex;
  justify-content: center;
  align-items: center;

  > input {
    display: none;
  }

  > svg {
    fill: var(--text);
  }
`;
