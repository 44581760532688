import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 80px;
`;

export const Panel = styled.div`
  background: var(--background-panel);

  top: -50px;
  border-radius: 8px;
  position: relative;
  margin: 0px auto;
  height: fit-content;
  width: 100%;
  max-width: 800px;
  padding: 24px;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
  & > form {
    display: flex;
    flex-direction: column;
    max-width: 800px;
  }
`;
