import React, { useState, useCallback, useEffect } from 'react';

import * as S from './styles';

interface Search {
  page: number;
  per_page: number;
  data_inicial: Date;
  data_final: Date;
  fornecedor: string;
  com_saldo: string;
}

interface PaginationProps {
  page: number;
  per_page: number;
  total: number;
  last_page: number;
  data_inicial: Date;
  data_final: Date;
  fornecedor: string;
  com_saldo: string;
  onSearch: ({
    page,
    per_page,
    data_final,
    data_inicial,
    fornecedor,
    com_saldo,
  }: Search) => void;
}

export const CustomPagination: React.FC<PaginationProps> = ({
  page,
  per_page,
  total,
  last_page,
  onSearch,
  data_inicial,
  data_final,
  fornecedor,
  com_saldo,
}) => {
  const [perPage, setPerPage] = useState(per_page);
  const [disabledNextPage, setDisabledNextPage] = useState(false);
  const [disabledPreviousPage, setDisabledPreviousPage] = useState(false);

  useEffect(() => {
    setDisabledPreviousPage(page <= 1);
    setDisabledNextPage(page >= last_page);
  }, [page, last_page]);

  const handleNextPage = useCallback(async () => {
    const nextPage = page + 1;
    if (nextPage <= last_page) {
      onSearch({
        page: nextPage,
        per_page: perPage,
        data_inicial,
        data_final,
        fornecedor,
        com_saldo,
      });
    }
  }, [
    data_final,
    data_inicial,
    last_page,
    onSearch,
    fornecedor,
    com_saldo,
    page,
    perPage,
  ]);

  const handlePreviousPage = useCallback(async () => {
    const previousPage = page - 1;
    if (previousPage > 0) {
      onSearch({
        page: previousPage,
        per_page: perPage,
        data_inicial,
        data_final,
        fornecedor,
        com_saldo,
      });
    }
  }, [
    data_final,
    data_inicial,
    onSearch,
    fornecedor,
    com_saldo,
    page,
    perPage,
  ]);

  const handleSetPerPage = useCallback(
    (perPageNumber: number) => {
      setPerPage(perPageNumber);
      onSearch({
        page: 1,
        per_page: perPageNumber,
        data_inicial,
        data_final,
        fornecedor,
        com_saldo,
      });
    },
    [data_final, data_inicial, onSearch, fornecedor, com_saldo],
  );

  return (
    <S.Container>
      <S.PaginantionInfo>
        <div />

        <span>
          <strong> Página:</strong> {page}/{last_page} - Total itens: {total}
        </span>

        <S.SelectPerPage
          onChange={(e) => handleSetPerPage(Number(e.target.value))}
          value={perPage}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </S.SelectPerPage>
      </S.PaginantionInfo>

      <S.PaginationButtons>
        <S.IconPreviusPage
          disabled={disabledPreviousPage}
          onClick={handlePreviousPage}
          title="Anterior"
        />
        <S.IconNextPage
          disabled={disabledNextPage}
          onClick={handleNextPage}
          title="Próxima"
        />
      </S.PaginationButtons>
    </S.Container>
  );
};
