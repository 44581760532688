import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PermissionList } from './List';
import { PermissionForm } from './Form';

export const Permission: React.FC = () => {
  return (
    <Switch>
      <Route path="/permissions" component={PermissionList} isPrivate exact />
      <Route path="/permissions/new" component={PermissionForm} isPrivate />
      <Route
        path="/permissions/edit/:id"
        component={PermissionForm}
        isPrivate
      />
    </Switch>
  );
};
