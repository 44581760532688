import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 24px 10px;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2rem;
  background: var(--background);
  color: var(--text);

  border-bottom: 1px solid var(--border);
`;
