import React, { useCallback, useState, useEffect } from 'react';
// import axios from 'axios';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaUsers } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';

import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import {
  createFornecedor,
  getFornecedor,
  updateFornecedor,
} from 'services/api/fornecedor';

import { getCentroCustos } from 'pages/CentroCusto/api';
import { getPlanoContas } from 'pages/PlanoContas/api';
import { getFormasPagamento } from 'services/api/formaPagamento';
import { getContas } from 'services/api/contas';

import { useToast } from 'contexts';
// import { maskCep, maskCpf, maskCnpj } from 'utils/masks';

import * as C from 'components';
import * as S from './styles';

import { tipoPessoaOptions, tipoFornecedorOptions } from './options';

import { IFornecedor, IFornecedorForm } from '../interfaces';

const schema = Yup.object().shape({
  nome: Yup.string().required('Descrição obrigatória'),
});

export const FornecedorForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    // setValue,
    // getValues,
    // watch,
  } = useForm<FieldValues>({
    defaultValues: {
      tipo_pessoa: { label: 'Jurídica', value: 'J' },
      tipo_fornecedor: { label: 'Fornecedor', value: 'F' },
    },
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [fornecedor, setFornecedor] = useState<IFornecedor>();

  // const tipoPessoa = watch('tipo_pessoa');

  useEffect(() => {
    if (id) {
      getFornecedor(id).then((data) => {
        setFornecedor(data);

        reset(data);
      });
    }
  }, [id, reset]);

  // const loadCNPJ = useCallback(
  //   async (cnpj: string) => {
  //     const current_pessoa = getValues('tipo_pessoa');

  //     const newCnpj = cnpj.replace(/\D/g, '');

  //     if (newCnpj.length === 14 && current_pessoa?.value === 'J') {
  //       try {
  //         const response = await axios.get(
  //           `https://brasilapi.com.br/api/cnpj/v1/${newCnpj}`,
  //         );

  //         if (response) {
  //           const {
  //             cep,
  //             bairro,
  //             ddd_telefone_1,
  //             logradouro,
  //             municipio,
  //             razao_social,
  //             uf,
  //             numero,
  //             nome_fantasia,
  //           } = response.data;

  //           setValue('cep', cep);
  //           setValue('rua', logradouro);
  //           setValue('nome', razao_social);
  //           setValue('bairro', bairro);
  //           setValue('uf', uf);
  //           setValue('fantasia', nome_fantasia);
  //           setValue('numero', numero);
  //           setValue('municipio', municipio);
  //           setValue('telefone', ddd_telefone_1.split('  ')[1]);
  //         }
  //       } catch (error) {
  //         addToast({
  //           type: 'error',
  //           title: 'Ops, Erro',
  //           description: 'Erro ao buscar o cnpj',
  //         });
  //       }
  //     }
  //   },
  //   [addToast, getValues, setValue],
  // );

  // const loadCEP = useCallback(
  //   async (cep: string) => {
  //     const current_street = getValues('rua').trim();

  //     const newCep = cep.replace(/\D/g, '');

  //     if (newCep.length === 8) {
  //       try {
  //         // https://viacep.com.br/ws/60878045/json/ >> esse retorna o codigo IBGE, remover o estado

  //         const endereco = await axios.get(
  //           `https://viacep.com.br/ws/${newCep}/json/`,
  //         );

  //         // setMunicipio(endereco.data.ibge.substring(2, 7));

  //         // const endereco = await axios.get(
  //         //   `https://brasilapi.com.br/api/cep/v2/${cep}`
  //         // );

  //         if (endereco && !current_street) {
  //           const { bairro, localidade, logradouro, uf, complemento } =
  //             endereco.data;

  //           setValue('rua', logradouro);
  //           setValue('bairro', bairro);
  //           setValue('uf', uf);
  //           setValue('municipio', localidade);
  //           setValue('complemento', complemento);
  //         }
  //       } catch (error) {
  //         addToast({
  //           type: 'error',
  //           title: 'Ops, Erro',
  //           description: 'Erro ao buscar o cep',
  //         });
  //       }
  //     }
  //   },
  //   [addToast, getValues, setValue],
  // );

  const loadOptionsCentroCusto = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getCentroCustos({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsPlanoContas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getPlanoContas({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsFormas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getFormasPagamento({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsContas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getContas({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const onSubmit = useCallback(
    async (data: IFornecedorForm) => {
      try {
        setLoading(true);

        if (id) {
          const response = await updateFornecedor(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/fornecedores');
          }
        } else {
          await createFornecedor(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/fornecedores');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/fornecedores">
              <MdKeyboardBackspace size={24} color="#fff" />
              Fornecedores
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={id ? `Editando: ${fornecedor?.nome}` : 'Novo Fornecedor'}
            icon={FaUsers}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <C.FormGroup>
              <C.Label>Razão Social</C.Label>
              <C.Input
                type="text"
                {...register('nome')}
                errors={errors.nome}
                name="nome"
                placeholder="Razão Social"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Fantasia</C.Label>
              <C.Input
                type="text"
                {...register('fantasia')}
                errors={errors.fantasia}
                name="fantasia"
                placeholder="Fantasia"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Tipo Pessoa</C.Label>
              <C.ReactSelect
                {...register('tipo_pessoa')}
                name="tipo_pessoa"
                control={control}
                errors={errors.tipo_pessoa}
                options={tipoPessoaOptions}
                isClearable
                isSearchable
                noOptionsMessage="Nenhuma tipo localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Tipo Fornecedor</C.Label>
              <C.ReactSelect
                {...register('tipo_fornecedor')}
                name="tipo_fornecedor"
                control={control}
                errors={errors.tipo_fornecedor}
                options={tipoFornecedorOptions}
                isClearable
                isSearchable
                noOptionsMessage="Nenhuma tipo localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Centro Custo</C.Label>
              <C.ReactSelectAsync
                {...register('centro_custo_id')}
                control={control}
                errors={errors.centro_custo_id}
                loadOptions={loadOptionsCentroCusto}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum centro custo localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Plano Contas</C.Label>
              <C.ReactSelectAsync
                {...register('plano_contas_id')}
                control={control}
                errors={errors.plano_contas_id}
                loadOptions={loadOptionsPlanoContas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma plano de contas localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Forma</C.Label>
              <C.ReactSelectAsync
                {...register('forma_pagamento_id')}
                control={control}
                errors={errors.forma_pagamento_id}
                loadOptions={loadOptionsFormas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma forma pagamento localizada"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Conta</C.Label>
              <C.ReactSelectAsync
                {...register('conta_id')}
                control={control}
                errors={errors.conta_id}
                loadOptions={loadOptionsContas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma conta foi localizada"
              />
            </C.FormGroup>

            {/* <C.FormGroup>
              <C.Label>Cnpj</C.Label>
              <C.Input
                type="text"
                {...register('cnpj')}
                errors={errors.cnpj}
                name="cnpj"
                placeholder="CNPJ"
                onChange={(e) => {
                  loadCNPJ(e.target.value);

                  const oldValue = getValues('cnpj');

                  if (tipoPessoa?.value === 'F') {
                    const newValue = maskCpf(oldValue, e.target.value);

                    setValue('cnpj', newValue);
                  } else {
                    const newValue = maskCnpj(oldValue, e.target.value);

                    setValue('cnpj', newValue);
                  }
                }}
                onInput={(e) => {
                  if (tipoPessoa?.value === 'F') {
                    e.currentTarget.value = e.currentTarget.value.slice(0, 14);
                  } else {
                    e.currentTarget.value = e.currentTarget.value.slice(0, 18);
                  }
                }}
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Incrição Estadual</C.Label>
              <C.Input
                type="text"
                {...register('ie')}
                errors={errors.ie}
                name="ie"
                placeholder="Inscrição Estadual"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>E-mail</C.Label>
              <C.Input
                type="email"
                {...register('email')}
                errors={errors.email}
                name="email"
                placeholder="E-mail"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Telefone</C.Label>
              <C.Input
                type="text"
                {...register('telefone')}
                errors={errors.telefone}
                name="telefone"
                placeholder="Telefone"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Contato</C.Label>
              <C.Input
                type="text"
                {...register('contato')}
                errors={errors.contato}
                name="contato"
                placeholder="Contato"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Cep</C.Label>
              <C.Input
                type="text"
                {...register('cep')}
                errors={errors.cep}
                name="cep"
                placeholder="Cep"
                onChange={(e) => {
                  loadCEP(e.target.value);

                  const oldValue = getValues('cep');

                  const newValue = maskCep(oldValue, e.target.value);

                  setValue('cep', newValue);
                }}
                onInput={(e) => {
                  e.currentTarget.value = e.currentTarget.value.slice(0, 9);
                }}
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Rua</C.Label>
              <C.Input
                type="text"
                {...register('rua')}
                errors={errors.rua}
                name="rua"
                placeholder="Rua"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Numero</C.Label>
              <C.Input
                type="text"
                {...register('numero')}
                errors={errors.numero}
                name="numero"
                placeholder="EX: 1212 ou SN"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Complemento</C.Label>
              <C.Input
                type="text"
                {...register('complemento')}
                errors={errors.complemento}
                name="complemento"
                placeholder="Complemento"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Bairro</C.Label>
              <C.Input
                type="text"
                {...register('bairro')}
                errors={errors.bairro}
                name="bairro"
                placeholder="Bairro"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Estado</C.Label>
              <C.Input
                type="text"
                {...register('uf')}
                errors={errors.uf}
                name="uf"
                placeholder="Estado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Municipio</C.Label>
              <C.Input
                type="text"
                {...register('municipio')}
                errors={errors.municipio}
                name="municipio"
                placeholder="Municipio"
              />
            </C.FormGroup> */}
          </S.FormContainer>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
