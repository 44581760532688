import styled, { css } from 'styled-components';
import { MdMenu, MdPowerSettingsNew, MdNotifications } from 'react-icons/md';
import { FiMoon, FiSun } from 'react-icons/fi';

export const Container = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 48px;
  background: var(--primary);
  color: var(--text-header);
  padding: 0px 16px 0px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
`;

export const WrapperLeft = styled.div`
  display: flex;
  align-items: center;
`;

const IconCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 4px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const IconMenu = styled(MdMenu)`
  ${IconCss}
`;

export const Brand = styled.img`
  margin-right: 8px;
`;

export const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 400px;
  border-radius: 50%;
`;

export const WrapperRight = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
  }
`;

export const IconDarkTheme = styled(FiSun)`
  ${IconCss}
`;
export const IconLightTheme = styled(FiMoon)`
  ${IconCss}
`;

export const IconSettings = styled(MdPowerSettingsNew)`
  ${IconCss}
`;
export const IconNotifications = styled(MdNotifications)`
  ${IconCss}
`;

export const AvatarInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  /* justify-content: space-between; */
`;

export const Avatar = styled.img`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
`;

export const UserName = styled.strong`
  display: block;
  font-size: 1.2rem;
`;
export const RoleName = styled.span`
  display: block;
  font-size: 1rem;
`;
