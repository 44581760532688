import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 150px;
  background: linear-gradient(
    to left,
    var(--background-header-page-2) 0%,
    var(--background-header-page-1) 100%
  );
`;
