import api from '../../api';

interface IEmbeddedToken {
  report_id: string;
  workspace_id: string;
}

export const embedded = async ({ report_id, workspace_id }: IEmbeddedToken) => {
  return api
    .get(`/embedded/${workspace_id}/${report_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
