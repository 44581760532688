import styled, { css } from 'styled-components';
import media from 'styled-media-query';

interface SaldoProps {
  isDebit: boolean;
}

export const Container = styled.div``;

export const ContainerSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 16px;

  & > div {
    max-width: 400px;
    transition: max-width 0.2s;
  }
  & > div:hover {
    max-width: 500px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 24px 0 24px;

  form {
    display: flex;
    flex-direction: row;

    ${media.lessThan('medium')`
      flex-direction: column;
    `}
  }

  ${media.lessThan('medium')`
      justify-content: center;
  `}
`;

export const Panel = styled.div`
  background: var(--background-panel);
  top: -50px;
  border-radius: 8px;
  position: relative;
  margin: 0px 32px 0px 32px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;

  .tbl-content {
    max-height: 500px;
    overflow-x: auto;
    margin-top: 0px;
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
    border-radius: 8px;
  }
`;

export const PaginationContainer = styled.div`
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--background);
  padding: 0px 16px;
  /* border-top: 1px solid #e0e0e0fc; */
`;

export const Saldo = styled.td<SaldoProps>`
  text-align: right;

  ${(props) =>
    props.isDebit &&
    css`
      color: #c53030;
    `}
`;

export const FormGroup = styled.div`
  > label {
    margin-bottom: 4px !important;
    color: white;
  }

  > div {
    margin: 0 8px 0 0;
  }
`;
