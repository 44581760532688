import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: var(--background-input);
  border-radius: 4px;
  padding: 1.2rem;
  width: 100%;

  /* border: 2px solid var(--border-input); */
  color: #999;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isFocused &&
    css`
      color: var(--selected-input);
      border-color: var(--selected-input);
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: var(--selected-input);
    `}


  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: var(--text);
    &::placeholder {
      color: var(--placeholder);
    }
  }

  svg {
    margin-right: 16px;
  }
`;
