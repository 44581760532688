import styled from 'styled-components';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';

export const Item = styled.li`
  position: relative;
  margin-left: -24px;
  margin-right: -24px;

  > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px 8px 0px;

    text-decoration: none;
  }

  > a,
  svg,
  span {
    margin-top: 2px;
  }

  > a > svg {
    min-width: 56px;
    flex-shrink: 0;
  }

  > a:last-child {
    flex-shrink: 0;
  }

  transition: background-color 0.2s;

  & a.sideBarLinkActive {
    text-decoration: none;
    background-color: var(--backgrount-item-menu-hover);
    border-right: 3px solid var(--link-border-active-sidebar);
  }

  & a:hover {
    text-decoration: none;
    background-color: var(--backgrount-item-menu-hover);
    /* border-right: 3px solid var(--link-border-active-sidebar); */
  }

  & > a:hover {
    > svg,
    > span {
      color: var(--text-item-menu-hover) !important;
      font-weight: 700;
    }
  }

  &:hover .submenu {
    display: block;
    max-height: 500px;
  }
`;

/* display: ${(props) => (props.isMenuOpened ? 'inline' : 'none')}; */
export const LabelMenu = styled.span`
  margin-left: 64px;
  position: absolute;
  left: 0;
  /* display: 'inline-block'; */
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--text-item-menu);
`;

export const SubMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IconArrowRight = styled(MdKeyboardArrowRight)`
  font-size: 2.4rem;
  fill: #707070;
`;
export const IconArrowDown = styled(MdKeyboardArrowDown)`
  font-size: 2.4rem;
  fill: #707070;
`;

export const SubMenu = styled.ul`
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.5s ease-out;
  background: var(--background-color-submenu);
`;
