import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import {
  IRecebimentoCorte,
  IRecebimentoCorteForm,
  IRelatorioRecebimentoCorteForm,
} from 'pages/RecebimentoCorte/interfaces';

import { formatCurrencyBR, formatCurrencyDataBase } from 'utils/helpers';
import { CustomFormats, toFormatDate } from '../../../utils/dateHelpers';

const createCorteData = (corte: IRecebimentoCorteForm) => {
  const data = {
    ...corte,
    numero_corte: corte.numero_corte?.value,
    fornecedor_id: corte.fornecedor_id.value,
    forma_pagamento_id: corte.forma_pagamento_id.value,
    produto_id: corte.produto_id.value,
    quantidade_envio: formatCurrencyDataBase(corte.quantidade_envio),
    quantidade_recebimento: formatCurrencyDataBase(
      corte.quantidade_recebimento,
    ),
    saldo:
      corte.saldo < '0'
        ? -formatCurrencyDataBase(corte.saldo)
        : formatCurrencyDataBase(corte.saldo),
    preco_custo_costureira: formatCurrencyDataBase(
      corte.preco_custo_costureira,
    ),
    valor_total_recebimento: formatCurrencyDataBase(
      corte.valor_total_recebimento,
    ),
  };

  return data;
};

const getRecebimentoCorte = async (id: string): Promise<IRecebimentoCorte> => {
  const { data } = await api.get<IRecebimentoCorte>(`cortes/recebimento/${id}`);

  const corteFormatadado = {
    ...data,
    quantidade_envio: formatCurrencyBR(Number(data.quantidade_envio), false),
    quantidade_recebimento: formatCurrencyBR(
      Number(data.quantidade_recebimento),
      false,
    ),
    saldo: formatCurrencyBR(Number(data.saldo), false),
    preco_custo_costureira: formatCurrencyBR(
      Number(data.preco_custo_costureira),
      false,
    ),
    valor_total_recebimento: formatCurrencyBR(
      Number(data.valor_total_recebimento),
      false,
    ),
    data_recebimento: data.data_recebimento
      ? new Date(data.data_recebimento)
      : null,
    data_vencimento: data.contas_pagar[0]?.data_vencimento
      ? new Date(data.contas_pagar[0]?.data_vencimento)
      : null,
    fornecedor_id: data?.fornecedor?.id
      ? {
          label: String(data?.fornecedor?.nome) || '',
          value: String(data?.fornecedor?.id) || '',
        }
      : '',
    produto_id: data?.produto?.id
      ? {
          label: String(data?.produto?.nome) || '',
          value: String(data?.produto?.id) || '',
        }
      : '',
    numero_corte: data?.numero_corte
      ? {
          label: String(data?.numero_corte) || '',
          value: String(data?.numero_corte) || '',
        }
      : '',
    forma_pagamento_id: data?.contas_pagar[0]?.forma_pagamento
      ? {
          label: String(data?.contas_pagar[0].forma_pagamento?.nome) || '',
          value: String(data?.contas_pagar[0].forma_pagamento?.id) || '',
        }
      : '',
  };

  return corteFormatadado;
};

const getRecebimentoCortes = async (
  params: IParams,
): Promise<IPaginate<IRecebimentoCorte>> => {
  const response = await api.get<IPaginate<IRecebimentoCorte>>(
    'cortes/recebimento',
    {
      params,
    },
  );

  const cortesFormatado = response.data.data.map((item: IRecebimentoCorte) => {
    return {
      ...item,
      quantidade_envio: formatCurrencyBR(Number(item.quantidade_envio), false),
      quantidade_recebimento: formatCurrencyBR(
        Number(item.quantidade_recebimento),
        false,
      ),
      saldo: formatCurrencyBR(Number(item.saldo), false),
      preco_custo_costureira: formatCurrencyBR(
        Number(item.preco_custo_costureira),
      ),
      valor_total_recebimento: formatCurrencyBR(
        Number(item.valor_total_recebimento),
      ),
      data_recebimento: item.data_recebimento
        ? toFormatDate(
            new Date(item.data_recebimento),
            CustomFormats.dd_MM_yyyy,
          )
        : null,
    };
  });

  return {
    ...response.data,
    data: cortesFormatado,
  };
};

const createRecebimentoCorte = async (
  corte: IRecebimentoCorteForm,
): Promise<void> => {
  const data = createCorteData(corte);

  await api.post('cortes/recebimento', data);
};

const updateRecebimentoCorte = async (
  id: string,
  corte: IRecebimentoCorteForm,
): Promise<IRecebimentoCorte> => {
  const data = createCorteData(corte);

  const response = await api.put<IRecebimentoCorte>(
    `cortes/recebimento/${id}`,
    data,
  );

  return response.data;
};

const deleteRecebimentoCorte = async (id: string): Promise<void> => {
  await api.delete(`cortes/recebimento/${id}`);
};

const getRelatorioRecebimentoCorte = async (
  params: IRelatorioRecebimentoCorteForm,
): Promise<{ link: string }> => {
  const data = {
    ...params,
    produto_id: params.produto_id ? params.produto_id.value : '',
    fornecedor_id: params.fornecedor_id ? params.fornecedor_id.value : '',
    data_recebimento_inicial: params.data_recebimento_inicial
      ? new Date(params.data_recebimento_inicial)
      : '',
    data_recebimento_final: params.data_recebimento_final
      ? new Date(params.data_recebimento_final)
      : '',
    saldo: params.saldo ? params.saldo.value : '',
  };

  const response = await api.post(`/relatorios/cortes/recebimento`, data);

  return response.data;
};

export {
  getRecebimentoCorte,
  getRecebimentoCortes,
  createRecebimentoCorte,
  updateRecebimentoCorte,
  deleteRecebimentoCorte,
  getRelatorioRecebimentoCorte,
};
