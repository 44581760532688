import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--pdf-header);
  color: #fff;
  margin-bottom: 8px;
  padding: 16px;

  span {
    width: 25%;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
`;

export const ControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: #fff;
    transition: background 0.2s;
    margin: 4px;
    &:hover {
      color: #fff !important;
      background: var(--pdf-header-button-hover);
      font-weight: 700;
      width: 36px;
      height: 36px;
      border-radius: 18px;

      svg {
        /* margin-right: 8px; */
        /* color: var(--text-item-menu-hover) !important; */
        font-weight: 700;
      }
    }
  }
`;

export const IconConainter = styled.div`
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: flex-end;
  align-items: center;

  button {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    color: #fff;
    transition: background 0.2s;
    margin: 4px;
    &:hover {
      color: #fff !important;
      background: var(--pdf-header-button-hover);
      font-weight: 700;
      width: 36px;
      height: 36px;
      border-radius: 18px;

      svg {
        /* margin-right: 8px; */
        /* color: var(--text-item-menu-hover) !important; */
        font-weight: 700;
      }
    }
  }
`;
