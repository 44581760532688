import React, { InputHTMLAttributes, useState, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import * as S from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  icon?: React.ComponentType<IconBaseProps>;
  errors: FieldError | undefined;
}

const InputBase: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> =
  ({ name, containerStyle = {}, icon: Icon, errors, ...rest }, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    const handleKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d)(\d{2})$/, '$1,$2');
      value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

      e.currentTarget.value = value;
    }, []);

    return (
      <S.Container
        style={containerStyle}
        isErrored={!!errors}
        isFocused={isFocused}
      >
        {Icon && <Icon size={20} />}

        <input
          type="text"
          name={name}
          onKeyUp={handleKeyUp}
          onFocus={() => setIsFocused(true)}
          onBlur={handleInputBlur}
          ref={ref}
          {...rest}
        />

        {errors && (
          <S.Error title={errors.message || ''}>
            <FiAlertCircle color="#c53030" size={20} />
          </S.Error>
        )}
      </S.Container>
    );
  };

export const InputCurrency = React.forwardRef(InputBase);
