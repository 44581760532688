// eslint-disable-next-line import/no-duplicates
import { parseISO, format } from 'date-fns';
import { utcToZonedTime, format as formatTZ } from 'date-fns-tz';

// eslint-disable-next-line import/no-duplicates
import pt from 'date-fns/locale/pt-BR';

// eslint-disable-next-line no-shadow
export enum CustomFormats {
  dd_MM_yyyy = 'dd/MM/yyyy',
  ddMMMyyyy = 'dd MMM yyyy',
  yyyy_MM_dd = 'yyyy-MM-dd',
  HH_mm = 'HH:mm',
  HHh_mm = "HH'h' mm",
}

export const toFormatDate = (
  date: string | Date,
  customFormat = CustomFormats.dd_MM_yyyy,
) => {
  if (!date) return '';

  const currentDate = typeof date === 'object' ? date : parseISO(date);

  if (!(typeof currentDate === 'object')) return '';

  const dateFormatted = format(currentDate, customFormat, {
    locale: pt,
  });

  return dateFormatted;
};

export const toFormatDateTZ = (
  data: string,
  formato = 'yyyy/MM/dd',
  time_zone = 'America/Fortaleza',
) => {
  const zonedDate = utcToZonedTime(data, time_zone);
  return formatTZ(zonedDate, formato, { timeZone: time_zone });
};
