import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaMoneyBill } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import {
  createBaixa,
  getBaixa,
  updateBaixa,
} from 'services/api/contasReceberBaixas';

import { getClientes } from 'services/api/cliente';
import { getFormasPagamento } from 'services/api/formaPagamento';
import { getContas } from 'services/api/contas';

import { useToast } from 'contexts';

import * as C from 'components';
import * as S from './styles';

import { IContasReceberBaixas, IContasReceberBaixasForm } from '../interfaces';

const schema = Yup.object().shape({
  cliente_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Cliente obrigatorio')
    .typeError('Cliente obrigatorio'),
  forma_pagamento_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Forma pagamento obrigatorio')
    .typeError('Forma pagamento obrigatorio'),
  conta_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Conta obrigatoria')
    .typeError('Conta obrigatoria'),
  valor_titulo: Yup.string(),
  valor_pago: Yup.string()
    .typeError('Valor pago obrigatório')
    .required('Valor pago obrigatório'),
  data_pagamento: Yup.string()
    .typeError('Data pagamento obrigatório')
    .required('Data pagamento obrigatório'),
});

export const ContasReceberBaixasForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setError,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [titulo, setTitulo] = useState<IContasReceberBaixas>();

  const loadOptionsClientes = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getClientes({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsFormas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getFormasPagamento({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsContas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getContas({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  useEffect(() => {
    if (id) {
      getBaixa(id).then((data) => {
        setTitulo(data);

        reset(data);
      });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data: IContasReceberBaixasForm) => {
      try {
        setLoading(true);

        if (id) {
          if (!data.cliente_id?.value) {
            setError('cliente_id', { message: 'Cliente obrigatorio' });

            return;
          }

          if (!data.forma_pagamento_id?.value) {
            setError('forma_pagamento_id', {
              message: 'Forma pagamento obrigatorio',
            });

            return;
          }

          if (!data.conta_id?.value) {
            setError('conta_id', {
              message: 'Conta obrigatoria',
            });

            return;
          }

          if (data.valor_pago === '0,00') {
            setError('valor_compra', {
              message: 'Valor pago obrigatório',
            });

            return;
          }

          const response = await updateBaixa(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/baixas/contas-receber');
          }
        } else {
          if (!data.cliente_id?.value) {
            setError('cliente_id', { message: 'Cliente obrigatorio' });

            return;
          }

          if (!data.forma_pagamento_id?.value) {
            setError('forma_pagamento_id', {
              message: 'Forma pagamento obrigatorio',
            });

            return;
          }

          if (!data.conta_id?.value) {
            setError('conta_id', {
              message: 'Conta obrigatoria',
            });

            return;
          }

          if (data.valor_pago === '0,00') {
            setError('valor_compra', {
              message: 'Valor pago obrigatório',
            });

            return;
          }

          await createBaixa(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/baixas/contas-receber');
        }
      } catch (err: any) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description:
            err.response.data.message ||
            'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset, setError],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/baixas/contas-receber">
              <MdKeyboardBackspace size={24} color="#fff" />
              Recebimento de títulos
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={
              id
                ? `Editando recebimento cliente: ${titulo?.cliente.nome}`
                : 'Novo recebimento'
            }
            icon={FaMoneyBill}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <C.FormGroup>
              <C.Label>Cliente</C.Label>
              <C.ReactSelectAsync
                {...register('cliente_id')}
                control={control}
                errors={errors.cliente_id}
                loadOptions={loadOptionsClientes}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum cliente localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor Titulo</C.Label>
              <C.InputCurrency
                {...register('valor_titulo')}
                errors={errors.valor_titulo}
                name="valor_titulo"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Conta</C.Label>
              <C.ReactSelectAsync
                {...register('conta_id')}
                control={control}
                errors={errors.conta_id}
                loadOptions={loadOptionsContas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma conta foi localizada"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor Acréscimo</C.Label>
              <C.InputCurrency
                {...register('valor_acrescimo')}
                errors={errors.valor_acrescimo}
                name="valor_acrescimo"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Data Pagamento</C.Label>
              <C.InputDatePicker
                {...register('data_pagamento')}
                control={control}
                errors={errors.data_pagamento}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_pagamento')}
                name="data_pagamento"
                placeholder="Data Pagamento"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor Desconto</C.Label>
              <C.InputCurrency
                {...register('valor_desconto')}
                errors={errors.valor_desconto}
                name="valor_desconto"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Hora Pagamento</C.Label>
              <C.Input
                type="text"
                {...register('hora_pagamento')}
                errors={errors.hora_pagamento}
                name="hora_pagamento"
                placeholder="Ex: 12:50"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor Juros</C.Label>
              <C.InputCurrency
                {...register('valor_juros')}
                errors={errors.valor_juros}
                name="valor_juros"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Forma</C.Label>
              <C.ReactSelectAsync
                {...register('forma_pagamento_id')}
                control={control}
                errors={errors.forma_pagamento_id}
                loadOptions={loadOptionsFormas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma forma pagamento localizada"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Observação</C.Label>
              <C.Input
                type="text"
                {...register('observacao')}
                errors={errors.observacao}
                name="observacao"
                placeholder="Observação"
              />
            </C.FormGroup>
          </S.FormContainer>

          <C.FormGroup>
            <C.Label>Valor Pago</C.Label>
            <C.InputCurrency
              {...register('valor_pago')}
              errors={errors.valor_pago}
              name="valor_pago"
              placeholder="Ex: 19,90"
            />
          </C.FormGroup>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
