import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { useHistory, Link } from 'react-router-dom';
import { ImScissors } from 'react-icons/im';
import { MdSearch } from 'react-icons/md';
import { IPaginate } from 'shared/interfaces';
import { useToast } from 'contexts';
import { ModalHandles } from 'components/Modal';

import {
  getRecebimentoCortes,
  deleteRecebimentoCorte,
} from 'services/api/cortesRecebimento';

import * as C from 'components';
import * as S from './styles';

import { IRecebimentoCorte } from '../interfaces';

export const RecebimentoCorteList: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [cortes, setCortes] = useState<IPaginate<IRecebimentoCorte>>();
  const [corteAtual, setCorteAtual] = useState<IRecebimentoCorte>();
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const modalRef = useRef<ModalHandles>(null);
  const [modalType, setModalType] = useState('remove');

  const handleSearch = useCallback(
    ({ page, per_page }) => {
      setLoading(true);

      const params = {
        page,
        per_page,
        term,
      };

      getRecebimentoCortes(params)
        .then((data) => {
          setCortes(data);

          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'Ops, Erro',
            description: e.response?.data?.message,
          });

          setLoading(false);
        });
    },
    [addToast, term],
  );

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 20,
    };

    handleSearch(params);
  }, [handleSearch]);

  const handleRemove = useCallback(async () => {
    try {
      await deleteRecebimentoCorte(corteAtual!.id);

      if (!cortes) return;

      const newList = cortes.data.filter((item) => item.id !== corteAtual?.id);
      setCortes({ ...cortes, data: newList });

      modalRef.current?.closeModal();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Registro removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao remover o registro',
      });
    }
  }, [addToast, corteAtual, cortes]);

  const handleOpenModal = useCallback(
    (corteSelected: IRecebimentoCorte, type: string): void => {
      setModalType(type);

      setCorteAtual(corteSelected);

      modalRef.current?.openModal();
    },
    [],
  );

  const renderModal = useMemo(() => {
    if (modalType === 'remove') {
      return (
        <C.Modal ref={modalRef} options={{ width: '400px' }}>
          <C.ModalHeader>
            <C.ModalTitle>Você Confirma a EXCLUSÃO?</C.ModalTitle>
          </C.ModalHeader>
          <C.ModalBody>
            <p>O registro será removido completamente!</p>
          </C.ModalBody>
          <C.ModalFooter>
            <C.Position align="right">
              <C.Button
                variant="outline-secondary"
                onClick={() => modalRef.current?.closeModal()}
              >
                Cancelar
              </C.Button>
              <C.Button
                variant="primary"
                onClick={() => handleRemove()}
                style={{ marginLeft: '16px' }}
              >
                SIM, confirmo a exclusão
              </C.Button>
            </C.Position>
          </C.ModalFooter>
        </C.Modal>
      );
    }

    return <></>;
  }, [handleRemove, modalType]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <C.HeaderPage>
          <C.HeaderInfo title="Recebimento de corte" icon={ImScissors} />

          <S.ContainerSearch>
            <C.InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) => {
                e.key === 'Enter'
                  ? handleSearch({
                      page: 1,
                      per_page: cortes?.per_page,
                    })
                  : {};
              }}
            />
          </S.ContainerSearch>

          <S.HeaderButtonContainer>
            <Link to="/recebimento-corte/print">
              <S.IconPrint />
            </Link>
            <C.Can checkPermission="recebimento_corte_create">
              <C.ButtonLink title="Adicionar Corte" to="/recebimento-corte/new">
                Adicionar
              </C.ButtonLink>
            </C.Can>
          </S.HeaderButtonContainer>
        </C.HeaderPage>
      </C.HeaderBackground>

      <S.Panel>
        {loading ? (
          <C.Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <div className="tbl-content">
              <C.Table>
                <thead>
                  <tr>
                    <th className="text-center">Número</th>
                    <th className="text-center">Data Recebimento</th>
                    <th className="text-left">Fornecedor</th>
                    <th className="text-left">Produto</th>
                    <th className="text-center">Quantidade Envio</th>
                    <th className="text-center">Quantidade Recebida</th>
                    <th className="text-center">Saldo</th>
                    <th className="text-center">Total Pagar</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {cortes &&
                    cortes.data.map((item: IRecebimentoCorte) => (
                      <tr key={item.id}>
                        <td className="text-center">{item.numero_corte}</td>
                        <td className="text-center">{item.data_recebimento}</td>
                        <td>{item.fornecedor.nome}</td>
                        <td>{item.produto.nome}</td>
                        <td className="text-center">{item.quantidade_envio}</td>
                        <td className="text-center">
                          {item.quantidade_recebimento}
                        </td>
                        <S.Saldo isDebit={String(item.saldo)?.includes('-')}>
                          {item.saldo}
                        </S.Saldo>

                        <td className="text-center">
                          {item.valor_total_recebimento}
                        </td>

                        <td className="text-center">
                          <C.PopUpMenu>
                            <C.Can checkPermission="recebimento_corte_update">
                              <button
                                type="button"
                                onClick={() =>
                                  history.push(
                                    `/recebimento-corte/edit/${item.id}`,
                                  )
                                }
                              >
                                <S.IconEdit
                                  title="Editar"
                                  size={24}
                                  color="#707070"
                                />
                                Editar
                              </button>
                            </C.Can>

                            <C.Can checkPermission="recebimento_corte_delete">
                              <button
                                type="button"
                                onClick={() => handleOpenModal(item, 'remove')}
                              >
                                <S.IconDelete
                                  title="Remover"
                                  size={24}
                                  color="#707070"
                                />
                                Remover
                              </button>
                            </C.Can>
                          </C.PopUpMenu>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </C.Table>
            </div>
          </>
        )}
        <S.PaginationContainer>
          {cortes && (
            <C.Pagination
              page={cortes?.page}
              total={cortes?.total}
              per_page={cortes?.per_page}
              last_page={cortes?.last_page}
              onSearch={handleSearch}
            />
          )}
        </S.PaginationContainer>
      </S.Panel>

      {renderModal}
    </S.Container>
  );
};
