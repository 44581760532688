import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import {
  IContasPagarCostureira,
  IContasPagarCostureiraForm,
  IRelatorioContasPagarCostureiraForm,
} from 'pages/ContasPagarCostureira/interfaces';
import { IRelatorioContasPagarCostureiraBaixasForm } from 'pages/ContasPagarCostureiraBaixas/interfaces';

import { formatCurrencyBR, formatCurrencyDataBase } from 'utils/helpers';
import { CustomFormats, toFormatDate } from '../../../utils/dateHelpers';

const createTituloData = (titulo: IContasPagarCostureiraForm) => {
  const data = {
    ...titulo,
    fornecedor_id: titulo.fornecedor_id.value,
    forma_pagamento_id: titulo.forma_pagamento_id.value,
    centro_custo_id: titulo.centro_custo_id
      ? titulo.centro_custo_id.value
      : null,
    plano_contas_id: titulo.plano_contas_id
      ? titulo.plano_contas_id.value
      : null,
    valor_compra: formatCurrencyDataBase(titulo.valor_romaneio),
    valor_romaneio: formatCurrencyDataBase(titulo.valor_romaneio),
  };

  return data;
};

const getTitulo = async (id: string): Promise<IContasPagarCostureira> => {
  const { data } = await api.get<IContasPagarCostureira>(
    `/contas-pagar-costureira/${id}`,
  );

  const tituloFormatadado = {
    ...data,
    valor_romaneio: formatCurrencyBR(Number(data.valor_romaneio), false),
    data_compra: data.data_compra ? new Date(data.data_compra) : null,
    data_vencimento: data.data_vencimento
      ? new Date(data.data_vencimento)
      : null,
    fornecedor_id: data?.fornecedor?.id
      ? {
          label: String(data?.fornecedor?.nome) || '',
          value: String(data?.fornecedor?.id) || '',
        }
      : '',
    forma_pagamento_id: data?.forma_pagamento?.id
      ? {
          label: String(data?.forma_pagamento?.nome) || '',
          value: String(data?.forma_pagamento?.id) || '',
        }
      : '',
    centro_custo_id: data?.centro_custo?.id
      ? {
          label: String(data?.centro_custo?.nome) || '',
          value: String(data?.centro_custo?.id) || '',
        }
      : '',
    plano_contas_id: data?.plano_contas?.id
      ? {
          label: String(data?.plano_contas?.nome) || '',
          value: String(data?.plano_contas?.id) || '',
        }
      : '',
  };

  return tituloFormatadado;
};

const getTitulos = async (
  params: IParams,
): Promise<IPaginate<IContasPagarCostureira>> => {
  const response = await api.get<IPaginate<IContasPagarCostureira>>(
    '/contas-pagar-costureira',
    {
      params,
    },
  );

  const titulosFormatado = response.data.data.map(
    (item: IContasPagarCostureira) => {
      return {
        ...item,
        valor_romaneio: formatCurrencyBR(Number(item.valor_romaneio)),
        data_compra: item.data_compra
          ? toFormatDate(new Date(item.data_compra), CustomFormats.dd_MM_yyyy)
          : null,
        data_vencimento: item.data_vencimento
          ? toFormatDate(
              new Date(item.data_vencimento),
              CustomFormats.dd_MM_yyyy,
            )
          : null,
      };
    },
  );

  return {
    ...response.data,
    data: titulosFormatado,
  };
};

const createTitulo = async (
  titulo: IContasPagarCostureiraForm,
): Promise<void> => {
  const data = createTituloData(titulo);

  await api.post('/contas-pagar-costureira', data);
};

const updateTitulo = async (
  id: string,
  titulo: IContasPagarCostureiraForm,
): Promise<IContasPagarCostureira> => {
  const data = createTituloData(titulo);

  const response = await api.put<IContasPagarCostureira>(
    `/contas-pagar-costureira/${id}`,
    data,
  );

  return response.data;
};

const deleteTitulo = async (titulo: {
  id: string;
  motivo_cancelamento?: string;
}): Promise<void> => {
  await api.delete(`/contas-pagar-costureira/${titulo.id}`, {
    data: { motivo_cancelamento: titulo.motivo_cancelamento },
  });
};

const baixarTitulo = async (id: string): Promise<void> => {
  await api.get(`/contas-pagar-costureira/status/${id}`);
};

const getRelatorioTitulo = async (
  params: IRelatorioContasPagarCostureiraForm,
): Promise<{ link: string }> => {
  const data = {
    ...params,
    fornecedor_id: params.fornecedor_id ? params.fornecedor_id.value : '',
    forma_pagamento_id: params.forma_pagamento_id
      ? params.forma_pagamento_id.value
      : '',
    centro_custo_id: params.centro_custo_id ? params.centro_custo_id.value : '',
    plano_contas_id: params.plano_contas_id ? params.plano_contas_id.value : '',
    data_compra_inicial: params.data_compra_inicial
      ? new Date(params.data_compra_inicial)
      : '',
    data_compra_final: params.data_compra_final
      ? new Date(params.data_compra_final)
      : '',
    data_vencimento_inicial: params.data_vencimento_inicial
      ? new Date(params.data_vencimento_inicial)
      : '',
    data_vencimento_final: params.data_vencimento_final
      ? new Date(params.data_vencimento_final)
      : '',
    status: params.status ? params.status.value : 'ALL',
  };

  const response = await api.post(
    `/relatorios/contas-pagar-costureira/titulos`,
    data,
  );

  return response.data;
};

const getRelatorioBaixas = async (
  params: IRelatorioContasPagarCostureiraBaixasForm,
): Promise<{ link: string }> => {
  const data = {
    ...params,
    fornecedor_id: params.fornecedor_id ? params.fornecedor_id.value : '',
    cliente_id: params.cliente_id ? params.cliente_id.value : '',
    forma_pagamento_id: params.forma_pagamento_id
      ? params.forma_pagamento_id.value
      : '',
    centro_custo_id: params.centro_custo_id ? params.centro_custo_id.value : '',
    plano_contas_id: params.plano_contas_id ? params.plano_contas_id.value : '',
    conta_id: params.conta_id ? params.conta_id.value : '',
    data_pagamento_inicial: params.data_pagamento_inicial
      ? new Date(params.data_pagamento_inicial)
      : '',
    data_pagamento_final: params.data_pagamento_final
      ? new Date(params.data_pagamento_final)
      : '',
    comprovante: params.comprovante ? params.comprovante.value : '',
  };

  const response = await api.post(
    `/relatorios/contas-pagar-costureira/baixas`,
    data,
  );

  return response.data;
};

export {
  getTitulo,
  getTitulos,
  createTitulo,
  updateTitulo,
  deleteTitulo,
  baixarTitulo,
  getRelatorioTitulo,
  getRelatorioBaixas,
};
