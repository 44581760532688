import React, { InputHTMLAttributes, useState, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { IconBaseProps } from 'react-icons';
// import { FiAlertCircle } from 'react-icons/fi';

import * as S from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  icon?: React.ComponentType<IconBaseProps>;
  errors: FieldError | undefined;
}

const InputBase: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> =
  ({ name, containerStyle = {}, icon: Icon, errors, ...rest }, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    return (
      <>
        <S.Container
          style={containerStyle}
          isErrored={!!errors}
          isFocused={isFocused}
        >
          {Icon && <Icon size={20} />}
          <input
            onFocus={() => setIsFocused(true)}
            onBlur={handleInputBlur}
            name={name}
            ref={ref}
            {...rest}
          />

          {/* {errors && (
          <S.Error title={errors.message || ''}>
            <FiAlertCircle color="#c53030" size={20} />
          </S.Error>
        )} */}
        </S.Container>
        {errors && <S.ErrorMsg>{errors.message || ''}</S.ErrorMsg>}
      </>
    );
  };

export const Input = React.forwardRef(InputBase);
