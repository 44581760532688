import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ReceberConsolidado } from './Consolidado';

export const ContasReceberRelatorios: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/relatorios/contas-receber/consolidado"
        component={ReceberConsolidado}
        isPrivate
        exact
      />
    </Switch>
  );
};
