import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { useHistory } from 'react-router-dom';
import { FiTruck } from 'react-icons/fi';
import { MdSearch } from 'react-icons/md';
import { IPaginate } from 'shared/interfaces';
import { useToast } from 'contexts';
import { ModalHandles } from 'components/Modal';

import {
  getAjustesEstoque,
  deleteAjusteEstoque,
} from 'services/api/ajusteEstoque';

import * as C from 'components';
import * as S from './styles';

import { IAjusteEstoque } from '../interfaces';

export const EnvioAjusteEstoqueList: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [ajustes, setAjustes] = useState<IPaginate<IAjusteEstoque>>();
  const [ajusteAtual, setAjusteAtual] = useState<IAjusteEstoque>();
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const modalRef = useRef<ModalHandles>(null);
  const [modalType, setModalType] = useState('remove');

  const handleSearch = useCallback(
    ({ page, per_page }) => {
      setLoading(true);

      const params = {
        page,
        per_page,
        term,
      };

      getAjustesEstoque(params)
        .then((data) => {
          setAjustes(data);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          addToast({
            type: 'error',
            title: 'Ops, Erro',
            description: e.response?.data?.message,
          });
          setLoading(false);
        });
    },
    [addToast, term],
  );

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 20,
    };

    handleSearch(params);
  }, [handleSearch]);

  const handleRemove = useCallback(async () => {
    try {
      if (ajusteAtual) {
        await deleteAjusteEstoque(ajusteAtual.id);

        if (ajustes) {
          const newList = ajustes.data.filter(
            (item) => item.id !== ajusteAtual.id,
          );
          setAjustes({ ...ajustes, data: newList });
        }

        modalRef.current?.closeModal();

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Registro removido com sucesso',
        });
      }
    } catch (err) {
      let errorMessage = 'Ocorreu um erro ao remover o registro';

      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        errorMessage = err.response.data.message;
      }

      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: errorMessage,
      });
    }
  }, [addToast, ajusteAtual, ajustes]);

  const handleOpenModal = useCallback(
    (ajusteSelected: IAjusteEstoque, type: string): void => {
      setModalType(type);
      setAjusteAtual(ajusteSelected);
      modalRef.current?.openModal();
    },
    [],
  );

  const renderModal = useMemo(() => {
    if (modalType === 'remove') {
      return (
        <C.Modal ref={modalRef} options={{ width: '400px' }}>
          <C.ModalHeader>
            <C.ModalTitle>Você Confirma a EXCLUSÃO?</C.ModalTitle>
          </C.ModalHeader>
          <C.ModalBody>
            <p>O registro será removido completamente!</p>
          </C.ModalBody>
          <C.ModalFooter>
            <C.Position align="right">
              <C.Button
                variant="outline-secondary"
                onClick={() => modalRef.current?.closeModal()}
              >
                Cancelar
              </C.Button>
              <C.Button
                variant="primary"
                onClick={() => handleRemove()}
                style={{ marginLeft: '16px' }}
              >
                SIM, confirmo a exclusão
              </C.Button>
            </C.Position>
          </C.ModalFooter>
        </C.Modal>
      );
    }

    return <></>;
  }, [handleRemove, modalType]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <C.HeaderPage>
          <C.HeaderInfo title="Ajuste estoque" icon={FiTruck} />

          <S.ContainerSearch>
            <C.InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch({
                    page: 1,
                    per_page: ajustes?.per_page,
                  });
                }
              }}
            />
          </S.ContainerSearch>

          <div>
            <C.Can checkPermission="ajuste_estoque_create">
              <C.ButtonLink title="Adicionar Estoque" to="/ajuste-estoque/new">
                Adicionar
              </C.ButtonLink>
            </C.Can>
          </div>
        </C.HeaderPage>
      </C.HeaderBackground>

      <S.Panel>
        {loading ? (
          <C.Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <div className="tbl-content">
              <C.Table>
                <thead>
                  <tr>
                    <th className="text-center">Data</th>
                    <th className="text-center">Número</th>
                    <th className="text-left">Observação</th>
                    <th className="text-center">Total Itens</th>
                    <th className="text-center">Usuário</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {ajustes && ajustes.data ? (
                    ajustes.data.map((item: IAjusteEstoque) => (
                      <tr key={item.id}>
                        <td className="text-center">{item.data_ajuste}</td>
                        <td className="text-center">{item.numero_ajuste}</td>
                        <td className="text-left">{item.observacao || ''}</td>
                        <td className="text-center">{item.total_itens}</td>
                        <td className="text-center">
                          {item.user_created?.name}
                        </td>
                        <td className="text-center">
                          <C.PopUpMenu>
                            <C.Can checkPermission="ajuste_estoque_update">
                              <button
                                type="button"
                                onClick={() =>
                                  history.push(
                                    `/ajuste-estoque/edit/${item.id}`,
                                  )
                                }
                              >
                                <S.IconEdit
                                  title="Editar"
                                  size={24}
                                  color="#707070"
                                />
                                Editar
                              </button>
                            </C.Can>

                            <C.Can checkPermission="ajuste_estoque_delete">
                              <button
                                type="button"
                                onClick={() => handleOpenModal(item, 'remove')}
                              >
                                <S.IconDelete
                                  title="Remover"
                                  size={24}
                                  color="#707070"
                                />
                                Remover
                              </button>
                            </C.Can>
                          </C.PopUpMenu>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        Nenhum dado encontrado.
                      </td>
                    </tr>
                  )}
                </tbody>
              </C.Table>
            </div>
          </>
        )}
        <S.PaginationContainer>
          {ajustes && (
            <C.Pagination
              page={ajustes?.page}
              total={ajustes?.total}
              per_page={ajustes?.per_page}
              last_page={ajustes?.last_page}
              onSearch={handleSearch}
            />
          )}
        </S.PaginationContainer>
      </S.Panel>

      {renderModal}
    </S.Container>
  );
};
