import React from 'react';

import { SiedeBarProvider } from 'contexts';
import * as C from 'components';
import * as S from './styles';

import Content from './Content';

const PrivateLayout: React.FC = ({ children }) => {
  return (
    <S.Container>
      <SiedeBarProvider>
        <C.Header />
        <S.Main>
          <C.SideBar />
          <Content>{children}</Content>
        </S.Main>
      </SiedeBarProvider>
    </S.Container>
  );
};

export default PrivateLayout;
