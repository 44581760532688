import React from 'react';

import * as S from './styles';

interface Props {
  align?: 'left' | 'center' | 'right';
}

export const Position: React.FC<Props> = ({
  align = 'center',
  children,
  ...rest
}) => {
  return (
    <S.Container {...rest} align={align}>
      {children}
    </S.Container>
  );
};
