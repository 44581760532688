import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { RecebimentoCorteList } from './List';
import { RecebimentoCorteForm } from './Form';
import { RecebimentoCortePrint } from './Print';

export const RecebimentoCorte: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/recebimento-corte"
        component={RecebimentoCorteList}
        isPrivate
        exact
      />
      <Route
        path="/recebimento-corte/new"
        component={RecebimentoCorteForm}
        isPrivate
      />
      <Route
        path="/recebimento-corte/print"
        component={RecebimentoCortePrint}
        isPrivate
      />
      <Route
        path="/recebimento-corte/edit/:id"
        component={RecebimentoCorteForm}
        isPrivate
      />
    </Switch>
  );
};
