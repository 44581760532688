import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaMoneyBill } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';

import { IRouterParams } from 'shared/interfaces';

import {
  createFormaPagamento,
  getFormaPagamento,
  updateFormaPagamento,
} from 'services/api/formaPagamento';

import { useToast } from 'contexts';

import * as C from 'components';
import * as S from './styles';

import { IFormaPagamento, IFormaPagamentoForm } from '../interfaces';

const schema = Yup.object().shape({
  nome: Yup.string().required('Descrição obrigatória'),
});

export const FormaPagamentoForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormaPagamentoForm>({
    defaultValues: {
      nome: '',
    },
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [formaPagamento, setFormaPagamento] = useState<IFormaPagamento>();

  useEffect(() => {
    if (id) {
      getFormaPagamento(id).then((data) => {
        setFormaPagamento(data);

        reset(data);
      });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data: IFormaPagamento) => {
      try {
        setLoading(true);

        if (id) {
          const response = await updateFormaPagamento(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/formas-pagamento');
          }
        } else {
          await createFormaPagamento(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/formas-pagamento');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/formas-pagamento">
              <MdKeyboardBackspace size={24} color="#fff" />
              Formas de pagamento
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={
              id ? `Editando: ${formaPagamento?.nome}` : 'Nova Forma Pagamento'
            }
            icon={FaMoneyBill}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Descrição</C.Label>
            <C.Input
              type="text"
              {...register('nome')}
              errors={errors.nome}
              name="nome"
              placeholder="Descrição"
            />
          </C.FormGroup>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
