function maskCard(oldValue: string, newValue: string): string {
  // Retornar mesma formação ao deletar
  if (oldValue?.length > newValue?.length) {
    return newValue;
  }

  return newValue
    .replace(/\W/gi, '')
    .replace(/(.{4})/g, '$1 ')
    .substring(0, 19);
}

function maskExpirationDate(oldValue: string, newValue: string): string {
  // user is deleting so return without formatting
  if (oldValue?.length > newValue?.length) {
    return newValue;
  }

  return newValue
    .replace(/\W/gi, '')
    .replace(/(.{2})/g, '$1/')
    .substring(0, 5);
}

function maskCep(oldValue: string, newValue: string): string {
  if (oldValue?.length > newValue?.length) {
    return newValue;
  }

  return newValue
    .replace(/\D/g, '') // 1239856
    .replace(/^(\d{5})(\d)/, '$1-$2');
}

function maskPhone(oldValue: string, newValue: string): string {
  if (oldValue?.length > newValue?.length) {
    return newValue;
  }

  return newValue
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2');
}

function maskCurrency(oldValue: string, newValue: string): string {
  if (oldValue?.length > newValue?.length) {
    return newValue;
  }

  return newValue
    .replace(/\D/g, '')
    .replace(/(\d)(\d{2})$/, '$1,$2')
    .replace(/(?=(\d{3})+(\D))\B/g, '.');
}

const maskCpf = (oldValue: string, newValue: string): string => {
  if (oldValue?.length > newValue?.length) {
    return newValue;
  }

  return newValue
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

const maskCnpj = (oldValue: string, newValue: string): string => {
  if (oldValue?.length > newValue?.length) {
    return newValue;
  }

  return newValue
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export {
  maskCep,
  maskPhone,
  maskCurrency,
  maskCard,
  maskExpirationDate,
  maskCpf,
  maskCnpj,
};
