import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaMoneyBill } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import {
  createBaixa,
  getBaixa,
  updateBaixa,
} from 'services/api/contasPagarBaixas';

import { getFornecedores, getFornecedor } from 'services/api/fornecedor';
import { getFormasPagamento } from 'services/api/formaPagamento';
import { getContas } from 'services/api/contas';
import { getClientes } from 'services/api/cliente';
import { getCentroCustos } from 'pages/CentroCusto/api';
import { getPlanoContas } from 'pages/PlanoContas/api';

import { useToast } from 'contexts';

import * as C from 'components';
import * as S from './styles';

import { IContasPagarBaixas, IContasPagarBaixasForm } from '../interfaces';

const schema = Yup.object().shape({
  fornecedor_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Fornecedor obrigatorio')
    .typeError('Fornecedor obrigatorio'),
  forma_pagamento_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Forma pagamento obrigatorio')
    .typeError('Forma pagamento obrigatorio'),
  conta_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Conta obrigatoria')
    .typeError('Conta obrigatoria'),
  valor_titulo: Yup.string(),
  valor_pago: Yup.string()
    .typeError('Valor pago obrigatório')
    .required('Valor pago obrigatório'),
  data_pagamento: Yup.string()
    .typeError('Data vencimento obrigatório')
    .required('Data vencimento obrigatório'),
});

export const ContasPagarBaixasForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setError,
    setValue,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [titulo, setTitulo] = useState<IContasPagarBaixas>();

  const loadOptionsClientes = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getClientes({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsFormas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getFormasPagamento({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsCentroCusto = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getCentroCustos({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsPlanoContas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getPlanoContas({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsFornecedores = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getFornecedores({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsContas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getContas({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const fornecedorSelected = watch('fornecedor_id');
  const centroCustoSelected = watch('centro_custo_id');
  const planoContasSelected = watch('plano_contas_id');
  const formaPagamentoSelected = watch('forma_pagamento_id');
  const contaSelected = watch('conta_id');

  useEffect(() => {
    if (id) {
      getBaixa(id).then((data) => {
        setTitulo(data);

        reset(data);
      });
    }
  }, [id, reset]);

  useEffect(() => {
    if (fornecedorSelected) {
      const getCustos = async () => {
        const forn = await getFornecedor(fornecedorSelected.value);

        if (forn && !centroCustoSelected) {
          setValue('centro_custo_id', forn.centro_custo_id);
        }

        if (forn && !planoContasSelected) {
          setValue('plano_contas_id', forn.plano_contas_id);
        }

        if (forn && !formaPagamentoSelected) {
          setValue('forma_pagamento_id', forn.forma_pagamento_id);
        }

        if (forn && !contaSelected) {
          setValue('conta_id', forn.conta_id);
        }
      };

      getCustos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fornecedorSelected]);

  const onSubmit = useCallback(
    async (data: IContasPagarBaixasForm) => {
      try {
        setLoading(true);

        if (id) {
          if (!data.fornecedor_id?.value) {
            setError('fornecedor_id', { message: 'Fornecedor obrigatorio' });

            return;
          }

          if (!data.forma_pagamento_id?.value) {
            setError('forma_pagamento_id', {
              message: 'Forma pagamento obrigatorio',
            });

            return;
          }

          if (!data.conta_id?.value) {
            setError('conta_id', {
              message: 'Conta obrigatoria',
            });

            return;
          }

          if (data.valor_pago === '0,00') {
            setError('valor_pago', {
              message: 'Valor pago obrigatório',
            });

            return;
          }

          const response = await updateBaixa(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/baixas/contas-pagar');
          }
        } else {
          if (!data.fornecedor_id?.value) {
            setError('fornecedor_id', { message: 'Fornecedor obrigatorio' });

            return;
          }

          if (!data.forma_pagamento_id?.value) {
            setError('forma_pagamento_id', {
              message: 'Forma pagamento obrigatorio',
            });

            return;
          }

          if (!data.conta_id?.value) {
            setError('conta_id', {
              message: 'Conta obrigatoria',
            });

            return;
          }

          if (data.valor_pago === '0,00') {
            setError('valor_pago', {
              message: 'Valor pago obrigatório',
            });

            return;
          }

          await createBaixa(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/baixas/contas-pagar');
        }
      } catch (err: any) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description:
            err.response.data.message ||
            'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset, setError],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/baixas/contas-pagar">
              <MdKeyboardBackspace size={24} color="#fff" />
              Pagamento de Títulos
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={
              id
                ? `Editando baixa fornecedor: ${titulo?.fornecedor.nome}`
                : 'Nova baixa'
            }
            icon={FaMoneyBill}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <C.FormGroup>
              <C.Label>Cliente</C.Label>
              <C.ReactSelectAsync
                {...register('cliente_id')}
                control={control}
                errors={errors.cliente_id}
                loadOptions={loadOptionsClientes}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum cliente localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Forma</C.Label>
              <C.ReactSelectAsync
                {...register('forma_pagamento_id')}
                control={control}
                errors={errors.forma_pagamento_id}
                loadOptions={loadOptionsFormas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma forma pagamento localizada"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Centro Custo</C.Label>
              <C.ReactSelectAsync
                {...register('centro_custo_id')}
                control={control}
                errors={errors.centro_custo_id}
                loadOptions={loadOptionsCentroCusto}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum centro custo localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Plano Contas</C.Label>
              <C.ReactSelectAsync
                {...register('plano_contas_id')}
                control={control}
                errors={errors.plano_contas_id}
                loadOptions={loadOptionsPlanoContas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma plano de contas localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Fornecedor</C.Label>
              <C.ReactSelectAsync
                {...register('fornecedor_id')}
                control={control}
                errors={errors.fornecedor_id}
                loadOptions={loadOptionsFornecedores}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum fornecedor localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor Titulo</C.Label>
              <C.InputCurrency
                {...register('valor_titulo')}
                errors={errors.valor_titulo}
                name="valor_titulo"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Conta</C.Label>
              <C.ReactSelectAsync
                {...register('conta_id')}
                control={control}
                errors={errors.conta_id}
                loadOptions={loadOptionsContas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma conta foi localizada"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor Acréscimo</C.Label>
              <C.InputCurrency
                {...register('valor_acrescimo')}
                errors={errors.valor_acrescimo}
                name="valor_acrescimo"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Data Pagamento</C.Label>
              <C.InputDatePicker
                {...register('data_pagamento')}
                control={control}
                errors={errors.data_pagamento}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_pagamento')}
                name="data_pagamento"
                placeholder="Data Pagamento"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor Desconto</C.Label>
              <C.InputCurrency
                {...register('valor_desconto')}
                errors={errors.valor_desconto}
                name="valor_desconto"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Hora Pagamento</C.Label>
              <C.Input
                type="text"
                {...register('hora_pagamento')}
                errors={errors.hora_pagamento}
                name="hora_pagamento"
                placeholder="Ex: 12:50"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor Juros</C.Label>
              <C.InputCurrency
                {...register('valor_juros')}
                errors={errors.valor_juros}
                name="valor_juros"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor Pago</C.Label>
              <C.InputCurrency
                {...register('valor_pago')}
                errors={errors.valor_pago}
                name="valor_pago"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Observação</C.Label>
              <C.Input
                type="text"
                {...register('observacao')}
                errors={errors.observacao}
                name="observacao"
                placeholder="Observação"
              />
            </C.FormGroup>
          </S.FormContainer>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
