import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import {
  IEnvioCorte,
  IEnvioCorteForm,
  IRelatorioEnvioCorteForm,
} from 'pages/EnvioCorte/interfaces';

import { formatCurrencyBR, formatCurrencyDataBase } from 'utils/helpers';
import { CustomFormats, toFormatDate } from '../../../utils/dateHelpers';

const createCorteData = (corte: IEnvioCorteForm) => {
  const data = {
    ...corte,
    fornecedor_id: corte.fornecedor_id.value,
    produto_id: corte.produto_id.value,
    quantidade_envio: formatCurrencyDataBase(corte.quantidade_envio),
    preco_custo_costureira: formatCurrencyDataBase(
      corte.preco_custo_costureira,
    ),
    valor_total_envio: formatCurrencyDataBase(corte.valor_total_envio),
  };

  return data;
};

const getEnvioCorte = async (id: string): Promise<IEnvioCorte> => {
  const { data } = await api.get<IEnvioCorte>(`cortes/envio/${id}`);

  const corteFormatadado = {
    ...data,
    quantidade_envio: formatCurrencyBR(Number(data.quantidade_envio), false),
    preco_custo_costureira: formatCurrencyBR(
      Number(data.preco_custo_costureira),
      false,
    ),
    valor_total_envio: formatCurrencyBR(Number(data.valor_total_envio), false),
    data_envio: data.data_envio ? new Date(data.data_envio) : null,
    fornecedor_id: data?.fornecedor?.id
      ? {
          label: String(data?.fornecedor?.nome) || '',
          value: String(data?.fornecedor?.id) || '',
        }
      : '',
    produto_id: data?.produto?.id
      ? {
          label: String(data?.produto?.nome) || '',
          value: String(data?.produto?.id) || '',
          preco: formatCurrencyBR(Number(data.preco_custo_costureira), false),
        }
      : '',
  };

  return corteFormatadado;
};

const getEnvioCorteByNumber = async (id: string): Promise<IEnvioCorte> => {
  const { data } = await api.get<IEnvioCorte>(`cortes/envio/by-numero/${id}`);

  const corteFormatadado = {
    ...data,
    quantidade_envio: formatCurrencyBR(Number(data.quantidade_envio), false),
    preco_custo_costureira: formatCurrencyBR(
      Number(data.preco_custo_costureira),
      false,
    ),
    valor_total_envio: formatCurrencyBR(Number(data.valor_total_envio), false),
    data_envio: data.data_envio ? new Date(data.data_envio) : null,
    fornecedor_id: data?.fornecedor?.id
      ? {
          label: String(data?.fornecedor?.nome) || '',
          value: String(data?.fornecedor?.id) || '',
        }
      : '',
    produto_id: data?.produto?.id
      ? {
          label: String(data?.produto?.nome) || '',
          value: String(data?.produto?.id) || '',
          preco: formatCurrencyBR(Number(data.preco_custo_costureira), false),
        }
      : '',
  };

  return corteFormatadado;
};

const getEnvioCortes = async (
  params: IParams,
): Promise<IPaginate<IEnvioCorte>> => {
  const response = await api.get<IPaginate<IEnvioCorte>>('cortes/envio', {
    params,
  });

  const cortesFormatado = response.data.data.map((item: IEnvioCorte) => {
    return {
      ...item,
      quantidade_envio: formatCurrencyBR(Number(item.quantidade_envio), false),
      preco_custo_costureira: formatCurrencyBR(
        Number(item.preco_custo_costureira),
      ),
      valor_total_envio: formatCurrencyBR(Number(item.valor_total_envio)),
      data_envio: item.data_envio
        ? toFormatDate(new Date(item.data_envio), CustomFormats.dd_MM_yyyy)
        : null,
    };
  });

  return {
    ...response.data,
    data: cortesFormatado,
  };
};

const createCorte = async (corte: IEnvioCorteForm): Promise<void> => {
  const data = createCorteData(corte);

  await api.post('cortes/envio', data);
};

const updateCorte = async (
  id: string,
  corte: IEnvioCorteForm,
): Promise<IEnvioCorte> => {
  const data = createCorteData(corte);

  const response = await api.put<IEnvioCorte>(`cortes/envio/${id}`, data);

  return response.data;
};

const deleteCorte = async (id: string): Promise<void> => {
  await api.delete(`cortes/envio/${id}`);
};

const getRelatorioEnvioCorte = async (
  params: IRelatorioEnvioCorteForm,
): Promise<{ link: string }> => {
  const data = {
    ...params,
    produto_id: params.produto_id ? params.produto_id.value : '',
    fornecedor_id: params.fornecedor_id ? params.fornecedor_id.value : '',
    data_envio_inicial: params.data_envio_inicial
      ? new Date(params.data_envio_inicial)
      : '',
    data_envio_final: params.data_envio_final
      ? new Date(params.data_envio_final)
      : '',
  };

  const response = await api.post(`/relatorios/cortes/envio`, data);

  return response.data;
};

export {
  getEnvioCorte,
  getEnvioCortes,
  createCorte,
  getEnvioCorteByNumber,
  updateCorte,
  deleteCorte,
  getRelatorioEnvioCorte,
};
