import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { VendasList } from './List';
import { VendasForm } from './Form';
import { VendasPrint } from './Print';

export const VendasMobile: React.FC = () => {
  return (
    <Switch>
      <Route path="/mobile/vendas" component={VendasList} isPrivate exact />
      <Route path="/mobile/vendas/new" component={VendasForm} isPrivate />
      <Route path="/mobile/vendas/print" component={VendasPrint} isPrivate />
      <Route path="/mobile/vendas/edit/:id" component={VendasForm} isPrivate />
    </Switch>
  );
};
