import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ContasPagarCostureiraBaixasList } from './List';
import { ContasPagarCostureiraBaixasForm } from './Form';
import { ContasPagarCostureiraBaixasPrint } from './Print';

export const ContasPagarCostureiraBaixas: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/baixas/contas-pagar-costureira"
        component={ContasPagarCostureiraBaixasList}
        isPrivate
        exact
      />
      <Route
        path="/baixas/contas-pagar-costureira/new"
        component={ContasPagarCostureiraBaixasForm}
        isPrivate
      />
      <Route
        path="/baixas/contas-pagar-costureira/print"
        component={ContasPagarCostureiraBaixasPrint}
        isPrivate
      />
      <Route
        path="/baixas/contas-pagar-costureira/edit/:id"
        component={ContasPagarCostureiraBaixasForm}
        isPrivate
      />
    </Switch>
  );
};
