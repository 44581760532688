import api from 'services/api';
import { IPaginate, IParams } from 'shared/interfaces';
import {
  IAjusteEstoque,
  IAjusteEstoqueForm,
  IItemAjuste,
} from 'pages/AjusteEstoque/interfaces';
import { formatCurrencyBR } from 'utils/helpers';
import { CustomFormats, toFormatDate } from '../../../utils/dateHelpers';

const createAjusteEstoqueData = (ajuste: IAjusteEstoqueForm) => {
  const itens = ajuste.itens_ajuste.map((item: IItemAjuste) => ({
    produto_id: item.produto_id.value,
    quantidade: item.quantidade,
  }));

  return {
    data_ajuste: ajuste.data_ajuste
      ? toFormatDate(new Date(ajuste.data_ajuste), CustomFormats.yyyy_MM_dd)
      : null,
    observacao: ajuste.observacao || '',
    tipo: typeof ajuste.tipo === 'object' ? ajuste.tipo.value : ajuste.tipo,
    itens_ajuste: itens,
  };
};

const getAjusteEstoque = async (id: string): Promise<IAjusteEstoque> => {
  const { data } = await api.get<IAjusteEstoque>(`ajustes-estoque/${id}`);

  const ajusteFormatado: IAjusteEstoque = {
    ...data,
    data_ajuste: data.data_ajuste ? new Date(data.data_ajuste) : '',
    itens_ajuste: data.itens_ajuste.map((item: IItemAjuste) => ({
      ...item,
      quantidade: formatCurrencyBR(Number(item.quantidade), false),
    })),
  };

  return ajusteFormatado;
};

const getAjustesEstoque = async (
  params: IParams,
): Promise<IPaginate<IAjusteEstoque>> => {
  try {
    const response = await api.get<IPaginate<IAjusteEstoque>>(
      'ajustes-estoque',
      {
        params,
      },
    );

    // Garante que response.data.data é um array
    const data = Array.isArray(response.data.data) ? response.data.data : [];

    // Verifica e formata cada item
    const ajustesFormatados = data.map((item: IAjusteEstoque) => ({
      ...item,
      data_ajuste: item.data_ajuste
        ? toFormatDate(new Date(item.data_ajuste), CustomFormats.dd_MM_yyyy)
        : '',
      itens_ajuste: Array.isArray(item.itens_ajuste)
        ? item.itens_ajuste.map((ajusteItem) => ({
            ...ajusteItem,
            quantidade: formatCurrencyBR(Number(ajusteItem.quantidade), false),
          }))
        : [],
      total_itens: item.total_itens,
    }));

    return {
      ...response.data,
      data: ajustesFormatados,
    };
  } catch (error) {
    console.error('Erro ao buscar ajustes de estoque:', error);
    throw error;
  }
};

const createAjusteEstoque = async (
  ajuste: IAjusteEstoqueForm,
): Promise<void> => {
  const data = createAjusteEstoqueData(ajuste);
  await api.post('ajustes-estoque', data);
};

const updateAjusteEstoque = async (
  id: string,
  ajuste: IAjusteEstoqueForm,
): Promise<IAjusteEstoque> => {
  const data = createAjusteEstoqueData(ajuste);
  const response = await api.put<IAjusteEstoque>(`ajustes-estoque/${id}`, data);
  return response.data;
};

const deleteAjusteEstoque = async (id: string): Promise<void> => {
  await api.delete(`ajustes-estoque/${id}`);
};

export {
  getAjusteEstoque,
  getAjustesEstoque,
  createAjusteEstoque,
  updateAjusteEstoque,
  deleteAjusteEstoque,
};
