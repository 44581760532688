import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ContasReceberBaixasList } from './List';
import { ContasReceberBaixasForm } from './Form';
import { ContasReceberBaixasPrint } from './Print';

export const ContasReceberBaixas: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/baixas/contas-receber"
        component={ContasReceberBaixasList}
        isPrivate
        exact
      />
      <Route
        path="/baixas/contas-receber/new"
        component={ContasReceberBaixasForm}
        isPrivate
      />
      <Route
        path="/baixas/contas-receber/print"
        component={ContasReceberBaixasPrint}
        isPrivate
      />
      <Route
        path="/baixas/contas-receber/edit/:id"
        component={ContasReceberBaixasForm}
        isPrivate
      />
    </Switch>
  );
};
