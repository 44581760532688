import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import { IPlanoContasPai, IPlanoContasPaiForm } from './interfaces';

const getPlanoContasPai = async (id: string): Promise<IPlanoContasPai> => {
  const response = await api.get<IPlanoContasPai>(`/plano-contas-pai/${id}`);

  return response.data;
};

const getPlanoContasPais = async (
  params: IParams,
): Promise<IPaginate<IPlanoContasPai>> => {
  const response = await api.get<IPaginate<IPlanoContasPai>>(
    '/plano-contas-pai',
    {
      params,
    },
  );

  return response.data;
};

const createPlanoContasPai = async (
  conta: IPlanoContasPaiForm,
): Promise<void> => {
  await api.post('/plano-contas-pai', conta);
};

const updatePlanoContasPai = async (
  id: string,
  conta: IPlanoContasPaiForm,
): Promise<IPlanoContasPai> => {
  const response = await api.put<IPlanoContasPai>(
    `/plano-contas-pai/${id}`,
    conta,
  );

  return response.data;
};

const deletePlanoContasPai = async (id: string): Promise<void> => {
  await api.delete(`/plano-contas-pai/${id}`);
};

export {
  getPlanoContasPai,
  getPlanoContasPais,
  createPlanoContasPai,
  updatePlanoContasPai,
  deletePlanoContasPai,
};
