import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { RiPaletteFill } from 'react-icons/ri';
import { MdKeyboardBackspace } from 'react-icons/md';
import { HexColorPicker } from 'react-colorful';

import { IRouterParams } from 'shared/interfaces';

import { createCor, getCor, updateCor } from 'services/api/produtoCor';

import { useToast } from 'contexts';

import * as C from 'components';
import * as S from './styles';

import { IProdutoCor, IProdutoCorForm } from '../interfaces';

const schema = Yup.object().shape({
  nome: Yup.string().required('Descrição obrigatória'),
});

export const CoresForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<IProdutoCorForm>({
    defaultValues: {
      nome: '',
    },
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [cor, setCor] = useState<IProdutoCor>();
  const [color, setColor] = useState('#fff');

  useEffect(() => {
    setValue('cor', color);
  }, [color, setValue]);

  useEffect(() => {
    if (id) {
      getCor(id).then((data) => {
        setCor(data);

        if (data.cor) {
          setColor(data.cor);
        }

        reset(data);
      });
    }
  }, [id, reset, setValue]);

  const onSubmit = useCallback(
    async (data: IProdutoCor) => {
      try {
        setLoading(true);

        if (id) {
          const response = await updateCor(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/produto/cores');
          }
        } else {
          await createCor(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/produto/cores');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/produto/cores">
              <MdKeyboardBackspace size={24} color="#fff" />
              Cor
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={id ? `Editando: ${cor?.nome}` : 'Nova Cor'}
            icon={RiPaletteFill}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Descrição</C.Label>
            <C.Input
              type="text"
              {...register('nome')}
              errors={errors.nome}
              name="nome"
              placeholder="Descrição"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Cor</C.Label>
            <C.Input
              type="text"
              {...register('cor')}
              errors={errors.cor}
              name="cor"
              placeholder="cor"
              defaultValue="#fff"
              onChange={(e) => setColor(e.target.value)}
            />
          </C.FormGroup>

          <S.ColorPickerContainer>
            <HexColorPicker
              color={color}
              onChange={(value) => setColor(value)}
            />
          </S.ColorPickerContainer>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
