import styled, { keyframes } from 'styled-components';

export const Container = styled.button`
  position: relative;
  overflow: hidden;
  height: 48px;
  border-radius: 4px;
  border: 0;
  padding: 0 1rem;
  font-weight: 500;
  width: 100%;
  margin-top: 16px;

  transition: Background-color 0.2s;

  &:hover:not(:disabled) {
    background-color: var(--primary-hover);
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.primary {
    color: var(--button-text);
    background-color: var(--button-primary);
    border: 1px solid var(--button-primary);
  }
  &.primary:hover {
    color: var(--button-text);
    background-color: var(--button-primary-hover);
    border: 1px solid var(--button-primary-border);
  }

  &.outline-primary {
    color: var(--button-primary);
    background-color: transparent;
    border: 1px solid var(--button-primary);
  }
  &.outline-primary:hover {
    color: var(--button-text);
    background-color: var(--button-primary);
    border: 1px solid var(--button-primary);
  }

  &.secondary {
    color: var(--button-text);
    background-color: var(--button-secondary);
    border: 1px solid var(--button-secondary);
  }
  &.secondary:hover {
    color: var(--button-text);
    background-color: var(--button-secondary-hover);
    border: 1px solid var(--button-secondary-border);
  }

  &.outline-secondary {
    color: var(--button-secondary);
    background-color: transparent;
    border: 1px solid var(--button-secondary);
  }
  &.outline-secondary:hover {
    color: var(--button-text);
    background-color: var(--button-secondary);
    border: 1px solid var(--button-secondary);
  }

  &.success {
    color: var(--button-text);
    background-color: var(--button-success);
    border: 1px solid var(--button-success);
  }
  &.success:hover {
    color: var(--button-text);
    background-color: var(--button-success-hover);
    border: 1px solid var(--button-success-border);
  }

  &.outline-success {
    color: var(--button-success);
    background-color: transparent;
    border: 1px solid var(--button-success);
  }
  &.outline-success:hover {
    color: var(--button-text);
    background-color: var(--button-success);
    border: 1px solid var(--button-success);
  }

  &.warning {
    color: var(--button-text);
    background-color: var(--button-warning);
    border: 1px solid var(--button-warning);
  }
  &.warning:hover {
    color: var(--button-text);
    background-color: var(--button-warning-hover);
    border: 1px solid var(--button-warning-border);
  }

  &.outline-warning {
    color: var(--button-warning);
    background-color: transparent;
    border: 1px solid var(--button-warning);
  }
  &.outline-warning:hover {
    color: var(--button-text);
    background-color: var(--button-warning);
    border: 1px solid var(--button-warning);
  }
  &.danger {
    color: var(--button-text);
    background-color: var(--button-danger);
    border: 1px solid var(--button-danger);
  }
  &.danger:hover {
    color: var(--button-text);
    background-color: var(--button-danger-hover);
    border: 1px solid var(--button-danger-border);
  }

  &.outline-danger {
    color: var(--button-danger);
    background-color: transparent;
    border: 1px solid var(--button-danger);
  }
  &.outline-danger:hover {
    color: var(--button-text);
    background-color: var(--button-danger);
    border: 1px solid var(--button-danger);
  }
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid #eea08b;
  border-right: 2px solid #eea08b;
  border-bottom: 2px solid #eea08b;
  border-left: 4px solid #ef461c;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  &.loader {
    margin: 0 auto;
  }
`;
