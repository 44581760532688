import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  min-width: 160px;

  & > svg {
    font-size: 2rem;
    fill: var(--background-header-icon);
  }
`;

export const Title = styled.h1`
  font-size: 1.6rem;
  margin-left: 16px;
  color: var(--background-header-text);
`;
