import styled from 'styled-components';

export const Container = styled.div`
  height: 50vw;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    margin-top: 50px;
    font-size: 24px;
    color: var(--text);
  }
`;
