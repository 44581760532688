import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Vendas } from './Vendas';
import { RaioX } from './RaioX';
import { Financeiro } from './Financeiro';
import { Estoque } from './Estoque';

export const Analytics: React.FC = () => {
  return (
    <Switch>
      <Route path="/analytics/estoque" component={Estoque} isPrivate exact />
      <Route
        path="/analytics/financeiro"
        component={Financeiro}
        isPrivate
        exact
      />
      <Route path="/analytics/vendas" component={Vendas} isPrivate exact />
      <Route path="/analytics/raiox" component={RaioX} isPrivate exact />
    </Switch>
  );
};
