import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FiTruck } from 'react-icons/fi';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';
import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';
import {
  createAjusteEstoque,
  getAjusteEstoque,
  updateAjusteEstoque,
} from 'services/api/ajusteEstoque';
import { getProdutos } from 'services/api/produto';
import { useToast } from 'contexts';
import { formatCurrencyDataBase, formatCurrencyBR } from 'utils/helpers';

import * as C from 'components';
import { tipoEstoqueOptions } from './options';
import * as S from './styles';
import { IAjusteEstoqueForm, IItemAjuste } from '../interfaces';

const schema = Yup.object().shape({
  data_ajuste: Yup.date()
    .typeError('Data do ajuste é obrigatória')
    .required('Data do ajuste é obrigatória'),
  observacao: Yup.string(),
  tipo: Yup.object()
    .shape({
      label: Yup.string().required('Tipo é obrigatório'),
      value: Yup.string().required('Tipo é obrigatório'),
    })
    .typeError('Tipo é obrigatório')
    .required('Tipo é obrigatório'),
  itens_ajuste: Yup.array()
    .of(
      Yup.object().shape({
        produto_id: Yup.object()
          .shape({
            label: Yup.string().required('Produto é obrigatório'),
            value: Yup.string().required('Produto é obrigatório'),
          })
          .typeError('Produto é obrigatório')
          .required('Produto é obrigatório'),
        quantidade: Yup.mixed()
          .test(
            'is-number-or-string',
            'Quantidade é obrigatória',
            (value) =>
              typeof value === 'number' ||
              (typeof value === 'string' && value.trim() !== ''),
          )
          .required('Quantidade é obrigatória'),
      }),
    )
    .required('Pelo menos um item de ajuste é obrigatório'),
});

export const EnvioAjusteEstoqueForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id } = useParams<IRouterParams>();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      data_ajuste: new Date(),
      observacao: '',
      tipo: null,
      itens_ajuste: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'itens_ajuste',
  });

  const produtoAtual = watch('produto_atual');
  const quantidadeAtual = watch('quantidade_atual');
  const itensAjuste = watch('itens_ajuste');

  useEffect(() => {
    if (id) {
      getAjusteEstoque(id).then((data) => {
        reset({
          ...data,
          tipo: tipoEstoqueOptions.find(
            (option) => option.value === data.tipo.value,
          ),
        });
      });
    }
  }, [id, reset]);

  useEffect(() => {
    if (fields.length !== itensAjuste.length) {
      // Devido bug na tela de update ao deletar ultimo item
      setValue('itens_ajuste', fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, setValue]);

  const loadOptionsProdutos = onDelay(
    (inputValue: string, callback: (options: OptionTypeBase[]) => void) => {
      getProdutos({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => ({
          label: item.nome,
          value: item.id,
          estoque: item.quantidade,
        }));
        callback(values);
      });
    },
    500,
  );

  const onSubmit = useCallback(
    async (data: IAjusteEstoqueForm) => {
      try {
        setLoading(true);
        if (data.itens_ajuste.length === 0) {
          addToast({
            type: 'error',
            title: 'Ops, Erro',
            description: 'Itens são obrigatórios',
          });
          return;
        }

        const formattedData: IAjusteEstoqueForm = {
          ...data,
          tipo: typeof data.tipo === 'object' ? data.tipo.value : data.tipo,
          itens_ajuste: data.itens_ajuste.map((item) => ({
            ...item,
            quantidade: formatCurrencyDataBase(item.quantidade.toString()),
          })),
        };

        if (id) {
          await updateAjusteEstoque(id, formattedData);
          addToast({
            type: 'success',
            title: 'Atualizado',
            description: 'Ajuste de estoque atualizado com sucesso',
          });
        } else {
          await createAjusteEstoque(formattedData);
          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Ajuste de estoque criado com sucesso',
          });
        }
        history.push('/ajuste-estoque');
      } catch (err) {
        let errorMessage = 'Ocorreu um erro ao salvar o ajuste de estoque';

        // Verifica se o erro é um objeto com uma resposta e mensagem
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          errorMessage = err.response.data.message;
        }

        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, id],
  );

  const handleAdd = useCallback(() => {
    if (produtoAtual && quantidadeAtual) {
      const existingItemIndex = itensAjuste.findIndex(
        (item: IItemAjuste) => item.produto_id.value === produtoAtual.value,
      );

      if (existingItemIndex > -1) {
        addToast({
          type: 'info',
          title: 'Item Ja existe',
          description: `O produto ${produtoAtual.label} ja foi adicionado, para alterar a quantidade delete o item e adicione novamente`,
        });
      } else {
        const quantidade = formatCurrencyDataBase(quantidadeAtual);

        append({
          produto_id: produtoAtual,
          quantidade: formatCurrencyBR(quantidade, false),
        });
      }
      setValue('produto_atual', null);
      setValue('quantidade_atual', '');
    }
  }, [append, setValue, produtoAtual, quantidadeAtual, itensAjuste, addToast]);

  const handleRemove = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const totalItens = useMemo(() => {
    return itensAjuste.length;
  }, [itensAjuste]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/ajuste-estoque">
              <MdKeyboardBackspace size={24} color="#fff" />
              Ajuste Estoque
            </Link>
          </S.BackLink>
          <C.HeaderInfo
            title={id ? `Editando ajuste de estoque` : 'Novo ajuste de estoque'}
            icon={FiTruck}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormHeaderContainer>
            <C.FormGroup>
              <C.Label>Data do Ajuste</C.Label>
              <C.InputDatePicker
                {...register('data_ajuste')}
                control={control}
                errors={errors.data_ajuste}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_ajuste')}
                name="data_ajuste"
                placeholder="Data do Ajuste"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Observação</C.Label>
              <C.Input
                {...register('observacao')}
                errors={errors.observacao}
                placeholder="Observação"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Tipo de Ajuste</C.Label>
              <C.ReactSelect
                {...register('tipo')}
                control={control}
                errors={errors.tipo}
                options={tipoEstoqueOptions}
                isClearable
                isSearchable
              />
            </C.FormGroup>
          </S.FormHeaderContainer>

          <S.Table>
            <thead>
              <tr>
                <th style={{ width: '70%' }}>Produto</th>
                <th style={{ width: '20%' }}>Quantidade</th>
                <th style={{ width: '10%' }}>Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <C.ReactSelectAsync
                    {...register('produto_atual')}
                    control={control}
                    errors={errors.produto_atual}
                    loadOptions={loadOptionsProdutos}
                    isClearable
                    isSearchable
                    loadingMessage="Buscando..."
                    noOptionsMessage="Nenhum produto localizado"
                  />
                </td>
                <td>
                  <C.InputCurrency
                    {...register('quantidade_atual')}
                    errors={errors.quantidade_atual}
                    name="quantidade_atual"
                    placeholder="Ex: 50,00"
                  />
                </td>
                <td>
                  <S.IconAdd
                    title="Adicionar"
                    size={24}
                    color="#707070"
                    onClick={handleAdd}
                  />
                </td>
              </tr>
            </tbody>
          </S.Table>

          <S.FormHeader>
            <div>Total de Itens: {totalItens}</div>
          </S.FormHeader>

          <S.Table>
            <tbody>
              {fields.map((item, index) => (
                <tr key={item.id}>
                  <td style={{ width: '70%' }}>
                    <C.ReactSelectAsync
                      {...register(`itens_ajuste[${index}].produto_id`)}
                      control={control}
                      errors={errors.itens_ajuste?.[index]?.produto_id}
                      loadOptions={loadOptionsProdutos}
                      defaultValue={itensAjuste[index]?.produto_id}
                      isDisabled
                    />
                  </td>
                  <td style={{ width: '20%' }}>
                    <C.InputCurrency
                      {...register(`itens_ajuste[${index}].quantidade`)}
                      errors={errors.itens_ajuste?.[index]?.quantidade}
                      name={`itens_ajuste[${index}].quantidade`}
                      defaultValue={itensAjuste[index]?.quantidade}
                    />
                  </td>
                  <td style={{ width: '10%' }}>
                    <S.IconDelete
                      title="Remover"
                      size={24}
                      color="#707070"
                      onClick={() => handleRemove(index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </S.Table>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
