import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { RiBankFill } from 'react-icons/ri';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import { useToast } from 'contexts';

import * as C from 'components';

import { getPlanoContasPais } from 'pages/PlanoContasPai/api';
import { createPlanoContas, getPlanoConta, updatePlanoContas } from '../api';

import * as S from './styles';

import { IPlanoContas, IPlanoContasForm } from '../interfaces';

const schema = Yup.object().shape({
  nome: Yup.string().required('Descrição obrigatória'),
  plano_contas_pai_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Plano contas pai obrigatorio')
    .typeError('Plano contas pai obrigatorio'),
});

export const PlanoContasForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<FieldValues>({
    defaultValues: {
      nome: '',
      plano_contas_pai_id: '',
    },
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [planoContas, setPlanoContas] = useState<IPlanoContas>();

  const loadOptionsPlanoContasPai = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getPlanoContasPais({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  useEffect(() => {
    if (id) {
      getPlanoConta(id).then((data) => {
        setPlanoContas(data);

        reset(data);
      });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data: IPlanoContasForm) => {
      try {
        setLoading(true);

        if (id) {
          const response = await updatePlanoContas(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/plano-contas');
          }
        } else {
          await createPlanoContas(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/plano-contas');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/plano-contas">
              <MdKeyboardBackspace size={24} color="#fff" />
              Plano contas
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={
              id ? `Editando: ${planoContas?.nome}` : 'Novo plano de contas '
            }
            icon={RiBankFill}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Plano contas pai</C.Label>
            <C.ReactSelectAsync
              {...register('plano_contas_pai_id')}
              control={control}
              errors={errors.plano_contas_pai_id}
              loadOptions={loadOptionsPlanoContasPai}
              isClearable
              isSearchable
              loadingMessage="Buscando..."
              noOptionsMessage="Nenhum plano contas localizado"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Descrição</C.Label>
            <C.Input
              type="text"
              {...register('nome')}
              errors={errors.nome}
              name="nome"
              placeholder="Descrição"
            />
          </C.FormGroup>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
