import React from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import pt from 'date-fns/locale/pt-BR';
import { Controller, Control, FieldError } from 'react-hook-form';

import * as S from './styles';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

registerLocale('pt-BR', pt);
setDefaultLocale('pt-BR');

interface InputDatePickerProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  control: Control;
  errors: FieldError | undefined;
  selected?: Date;
  dateFormat?: string;
  showTimeSelect?: boolean;
  highlightDates?: Array<Date>;
}

const InputDatePickerBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputDatePickerProps
> = (
  {
    name,
    control,
    errors,
    selected = null,
    dateFormat = 'dd/MM/yyyy HH:mm',
    showTimeSelect = false,
    highlightDates = [],
    ...rest
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
) => {
  // const [startDate, setStartDate] = React.useState<Date>(selected as Date);

  return (
    <S.Container>
      <Controller
        name={name}
        control={control}
        {...rest}
        render={({ field }) => (
          <S.DatePickerContainer
            {...field}
            selected={selected}
            // onChange={(date: Date) => setStartDate(date)}
            locale="pt-BR"
            timeFormat="HH:mm"
            timeIntervals={15}
            isClearable
            highlightDates={highlightDates}
            timeCaption="Hora"
            showTimeSelect={showTimeSelect}
            /* timeInputLabel="Hora:"
            showTimeInput={showTime} */
            dateFormat={dateFormat}
            // dateFormat={
            //   showTimeSelect ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy HH:mm'
            // }
            todayButton="Hoje"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        )}
      />
      {errors && <S.ErrorMsg>{errors.message || ''}</S.ErrorMsg>}
    </S.Container>
  );
};

export const InputDatePicker = React.forwardRef(InputDatePickerBase);
