import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import {
  IContasPagarCostureiraBaixas,
  IContasPagarCostureiraBaixasForm,
} from 'pages/ContasPagarCostureiraBaixas/interfaces';

import { formatCurrencyBR, formatCurrencyDataBase } from 'utils/helpers';
import { CustomFormats, toFormatDate } from '../../../utils/dateHelpers';

const createBaixaData = (titulo: IContasPagarCostureiraBaixasForm) => {
  const data = {
    ...titulo,
    fornecedor_id: titulo.fornecedor_id ? titulo.fornecedor_id.value : null,
    cliente_id: titulo.cliente_id ? titulo.cliente_id.value : null,
    forma_pagamento_id: titulo.forma_pagamento_id
      ? titulo.forma_pagamento_id.value
      : null,
    centro_custo_id: titulo.centro_custo_id
      ? titulo.centro_custo_id.value
      : null,
    plano_contas_id: titulo.plano_contas_id
      ? titulo.plano_contas_id.value
      : null,
    conta_id: titulo.conta_id ? titulo.conta_id.value : null,
    valor_titulo: formatCurrencyDataBase(titulo.valor_pago),
    valor_pago: formatCurrencyDataBase(titulo.valor_pago),
  };

  return data;
};

const getBaixa = async (id: string): Promise<IContasPagarCostureiraBaixas> => {
  const { data } = await api.get<IContasPagarCostureiraBaixas>(
    `/baixas/contas-pagar-costureira/${id}`,
  );

  const tituloFormatadado = {
    ...data,
    valor_pago: formatCurrencyBR(Number(data.valor_pago), false),
    data_pagamento: data.data_pagamento ? new Date(data.data_pagamento) : null,
    fornecedor_id: data?.fornecedor?.id
      ? {
          label: String(data?.fornecedor?.nome) || '',
          value: String(data?.fornecedor?.id) || '',
        }
      : '',
    cliente_id: data?.cliente?.id
      ? {
          label: String(data?.cliente?.nome) || '',
          value: String(data?.cliente?.id) || '',
        }
      : '',
    forma_pagamento_id: data?.forma_pagamento?.id
      ? {
          label: String(data?.forma_pagamento?.nome) || '',
          value: String(data?.forma_pagamento?.id) || '',
        }
      : '',

    centro_custo_id: data?.centro_custo?.id
      ? {
          label: String(data?.centro_custo?.nome) || '',
          value: String(data?.centro_custo?.id) || '',
        }
      : '',
    plano_contas_id: data?.plano_contas?.id
      ? {
          label: String(data?.plano_contas?.nome) || '',
          value: String(data?.plano_contas?.id) || '',
        }
      : '',
    conta_id: data?.conta?.id
      ? {
          label: String(data?.conta?.nome) || '',
          value: String(data?.conta?.id) || '',
        }
      : '',
  };

  return tituloFormatadado;
};

const getBaixas = async (
  params: IParams,
): Promise<IPaginate<IContasPagarCostureiraBaixas>> => {
  const response = await api.get<IPaginate<IContasPagarCostureiraBaixas>>(
    '/baixas/contas-pagar-costureira',
    {
      params,
    },
  );

  const titulosFormatado = response.data.data.map(
    (item: IContasPagarCostureiraBaixas) => {
      return {
        ...item,
        valor_pago: formatCurrencyBR(Number(item.valor_pago)),
        data_pagamento: item.data_pagamento
          ? toFormatDate(
              new Date(item.data_pagamento),
              CustomFormats.dd_MM_yyyy,
            )
          : null,
      };
    },
  );

  return {
    ...response.data,
    data: titulosFormatado,
  };
};

const createBaixa = async (
  titulo: IContasPagarCostureiraBaixasForm,
): Promise<void> => {
  const data = createBaixaData(titulo);

  await api.post('/baixas/contas-pagar-costureira', data);
};

const updateBaixa = async (
  id: string,
  titulo: IContasPagarCostureiraBaixasForm,
): Promise<IContasPagarCostureiraBaixas> => {
  const data = createBaixaData(titulo);

  const response = await api.put<IContasPagarCostureiraBaixas>(
    `/baixas/contas-pagar-costureira/${id}`,
    data,
  );

  return response.data;
};

const deleteBaixa = async (titulo: {
  id: string;
  motivo_cancelamento?: string;
}): Promise<void> => {
  await api.delete(`/baixas/contas-pagar-costureira/${titulo.id}`, {
    data: { motivo_cancelamento: titulo.motivo_cancelamento },
  });
};

const statusComprovante = async (id: string): Promise<void> => {
  await api.get(`/baixas/contas-pagar/comprovante/${id}`);
};

export {
  getBaixa,
  getBaixas,
  createBaixa,
  updateBaixa,
  deleteBaixa,
  statusComprovante,
};
