import fileSize from 'filesize';
import { IPaginate, IParams } from 'shared/interfaces';
import {
  IProduto,
  IProdutoForm,
  IProdutoImagens,
} from 'pages/Produto/interfaces';
import { UploadFile } from 'components/UploadDropzone';

import { formatCurrencyBR, formatCurrencyDataBase } from 'utils/helpers';

import api from 'services/api';

const createFormDataProduto = (produto: IProdutoForm) => {
  const formData = new FormData();

  if (produto.foto_upload?.length) {
    formData.append('foto', produto.foto_upload[0]);
  }

  const data = {
    nome: produto.nome,
    familia_id: produto.familia_id?.value || null,
    cor_id: produto.cor_id?.value || null,
    preco: formatCurrencyDataBase(produto.preco),
    preco_catalogo: formatCurrencyDataBase(produto.preco_catalogo),
    preco_custo: formatCurrencyDataBase(produto.preco_custo),
    preco_custo_costureira: formatCurrencyDataBase(
      produto.preco_custo_costureira,
    ),
    valor_lucro: formatCurrencyDataBase(produto.valor_lucro),
    quantidade_kit: formatCurrencyDataBase(produto.quantidade_kit),
    envia_mobile: !!produto.envia_mobile,
    grade: !!produto.grade,
  };

  formData.append('data', JSON.stringify(data));

  return formData;
};

const getProduto = async (id: string): Promise<IProduto> => {
  const { data } = await api.get<IProduto>(`/produtos/${id}`);

  const produtoFormatado = {
    ...data,
    cor_id: data?.cor?.id
      ? {
          label: String(data?.cor?.nome) || '',
          value: String(data?.cor?.id) || '',
        }
      : '',
    familia_id: data?.familia?.id
      ? {
          label: String(data?.familia?.nome) || '',
          value: String(data?.familia?.id) || '',
        }
      : '',
    preco: formatCurrencyBR(Number(data.preco), false),
    preco_catalogo: formatCurrencyBR(Number(data.preco_catalogo), false),
    preco_custo: formatCurrencyBR(Number(data.preco_custo), false),
    preco_custo_costureira: formatCurrencyBR(
      Number(data.preco_custo_costureira),
      false,
    ),
    valor_lucro: formatCurrencyBR(Number(data.valor_lucro), false),
    quantidade: formatCurrencyBR(Number(data.quantidade), false),
    quantidade_kit: formatCurrencyBR(Number(data.quantidade_kit), false),
  };

  return produtoFormatado;
};

const getProdutos = async (params: IParams): Promise<IPaginate<IProduto>> => {
  const response = await api.get<IPaginate<IProduto>>('/produtos', {
    params,
  });

  const produtosFormatado = response.data.data.map((item: IProduto) => {
    return {
      ...item,
      preco_custo_costureira: formatCurrencyBR(
        Number(item.preco_custo_costureira),
        false,
      ),
      precoFormatado: formatCurrencyBR(Number(item.preco)),
      quantidadeFormatado: formatCurrencyBR(Number(item.quantidade), false),
    };
  });

  return {
    ...response.data,
    data: produtosFormatado,
  };
};

const getProdutoImagens = async (produto_id: string) => {
  return api
    .get<IProdutoImagens[]>(`/produto/imagens/${produto_id}`)
    .then((response) => {
      const { data } = response;

      const images = data.map(
        (item: IProdutoImagens) =>
          ({
            id: item.id,
            name: item.image,
            readableSize: fileSize(item.size),
            preview: item.image_url,
            uploaded: true,
            progress: 100,
            error: false,
            url: item.image_url,
          } as UploadFile),
      );

      return images;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const createProduto = async (produto: IProdutoForm): Promise<void> => {
  const data = createFormDataProduto(produto);

  await api.post('/produtos', data);
};

const updateProduto = async (
  id: string,
  produto: IProdutoForm,
): Promise<IProduto> => {
  const data = createFormDataProduto(produto);

  const response = await api.put<IProduto>(`/produtos/${id}`, data);

  return response.data;
};

const deleteProduto = async (id: string): Promise<void> => {
  await api.delete(`/produtos/${id}`);
};

export {
  getProduto,
  getProdutos,
  createProduto,
  updateProduto,
  deleteProduto,
  getProdutoImagens,
};
