import React, { ButtonHTMLAttributes } from 'react';
import * as S from './styles';

export type ButtonProps = {
  icon?: JSX.Element;
  loading?: boolean;
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-warning'
    | 'outline-danger';
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<ButtonProps> = ({
  icon,
  children,
  loading,
  variant = 'primary',
  ...rest
}) => {
  return (
    <S.Container type="button" className={variant} {...rest}>
      {icon}
      {loading ? <S.Spinner className="loader" /> : children}
    </S.Container>
  );
};
