import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { RiBankFill } from 'react-icons/ri';
import { MdKeyboardBackspace } from 'react-icons/md';

import { IRouterParams } from 'shared/interfaces';

import { useToast } from 'contexts';

import * as C from 'components';
import * as S from './styles';

import { createCentroCusto, getCentroCusto, updateCentroCusto } from '../api';
import { ICentroCusto, ICentroCustoForm } from '../interfaces';

const schema = Yup.object().shape({
  nome: Yup.string().required('Descrição obrigatória'),
});

export const CentroCustoForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ICentroCustoForm>({
    defaultValues: {
      nome: '',
    },
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [centroCusto, setCentroCusto] = useState<ICentroCusto>();

  useEffect(() => {
    if (id) {
      getCentroCusto(id).then((data) => {
        setCentroCusto(data);

        reset(data);
      });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data: ICentroCusto) => {
      try {
        setLoading(true);

        if (id) {
          const response = await updateCentroCusto(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/centro-custo');
          }
        } else {
          await createCentroCusto(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/centro-custo');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/centro-custo">
              <MdKeyboardBackspace size={24} color="#fff" />
              Centro Custo
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={id ? `Editando: ${centroCusto?.nome}` : 'Novo centro custo'}
            icon={RiBankFill}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Descrição</C.Label>
            <C.Input
              type="text"
              {...register('nome')}
              errors={errors.nome}
              name="nome"
              placeholder="Descrição"
            />
          </C.FormGroup>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
