import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import { IRelatorioConsolidado } from 'pages/ContasPagarRelatorios/interfaces';

import { formatCurrencyBR } from 'utils/helpers';

const getConsolidado = async (
  params: IParams,
): Promise<IPaginate<IRelatorioConsolidado>> => {
  const response = await api.get<IPaginate<IRelatorioConsolidado>>(
    '/relatorios/contas-pagar/consolidado',
    {
      params,
    },
  );

  const titulosFormatado = response.data.data.map(
    (item: IRelatorioConsolidado) => {
      return {
        ...item,
        valor_compra: formatCurrencyBR(Number(item.valor_compra)),
        valor_nota_fiscal: formatCurrencyBR(Number(item.valor_nota_fiscal)),
        valor_romaneio: formatCurrencyBR(Number(item.valor_romaneio)),
        valor_juros: formatCurrencyBR(Number(item.valor_juros)),
        valor_acrescimo: formatCurrencyBR(Number(item.valor_acrescimo)),
        valor_desconto: formatCurrencyBR(Number(item.valor_desconto)),
        valor_pago: formatCurrencyBR(Number(item.valor_pago)),
        valor_saldo: formatCurrencyBR(
          parseFloat(item.valor_pago) - parseFloat(item.valor_compra),
        ),
      };
    },
  );

  return {
    ...response.data,
    data: titulosFormatado,
  };
};

export { getConsolidado };
