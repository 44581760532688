import React from 'react';

import { useSiedeBar } from 'contexts/SideBarProvider';
import * as S from './styles';

const Content: React.FC = ({ children }) => {
  const { isOpened } = useSiedeBar();

  return <S.Container isMenuOpened={isOpened}>{children}</S.Container>;
};

export default Content;
