import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiMail, FiLock, FiUser, FiArrowLeft } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { singUp } from 'services/api/SignUp';
import { useToast } from 'contexts';

import logoImg from 'assets/logo.png';
import * as C from 'components';
import * as S from './styles';

import { ISignUp } from './interfaces';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string()
    .required('Email obrigatório')
    .email('Digite um e-mail válido'),
  password: Yup.string().min(6, 'No mínimo 6 dígitos'),
});

export const SignUp: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignUp>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: ISignUp) => {
      try {
        const { name, email, password } = data;
        await singUp({ name, email, password });

        addToast({
          type: 'success',
          title: 'Cadastro realizado!',
          description: 'Você já pode fazer seu login no Zilook Lingerie!',
        });

        history.push('/signin');
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro no cadastro',
          description: 'Ocorreu um erro ao fazer cadastro, tente novamente',
        });
      }
    },
    [history, addToast],
  );

  return (
    <S.Container>
      <S.Background />
      <S.Content>
        <S.AnimationContainer>
          <img src={logoImg} alt="Zilook Lingerie" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Faça seu cadastro</h1>

            <C.Input
              {...register('name')}
              errors={errors.name}
              type="text"
              name="name"
              icon={FiUser}
              placeholder="Nome"
            />
            <C.Input
              {...register('email')}
              errors={errors.email}
              type="text"
              name="email"
              icon={FiMail}
              placeholder="E-mail"
            />
            <C.Input
              {...register('password')}
              errors={errors.password}
              type="password"
              name="password"
              icon={FiLock}
              autoComplete="off"
              placeholder="Senha"
            />

            <C.Button type="submit">Cadastrar</C.Button>
          </form>

          <Link to="/signin">
            <FiArrowLeft />
            Voltar para logon
          </Link>
        </S.AnimationContainer>
      </S.Content>
    </S.Container>
  );
};
