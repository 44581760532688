import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaUserCog } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';

import { IRouterParams, IParams } from 'shared/interfaces';

import {
  createRole,
  getRoleById,
  updateRole,
  getPermissions,
} from 'services/api/permission';

import { useToast } from 'contexts';
import * as C from 'components';
import * as S from './styles';

import { IRole, IRoleForm } from '../interfaces';

const schema = Yup.object().shape({
  name: Yup.string().required('Descrição obrigatória'),
});

export const PermissionForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IRoleForm>({
    defaultValues: {
      name: '',
      permissions: [],
    },
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<IRole>();
  const [permissions, setPermissions] = useState([]);

  const loadPermissions = async (params: IParams) => {
    try {
      setLoading(true);
      const response = await getPermissions(params);

      setPermissions(response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 200,
      term: '',
    };

    loadPermissions(params);
  }, []);

  const onSubmit = useCallback(
    async (data: IRole) => {
      try {
        setLoading(true);

        if (id) {
          updateRole(id, data).then(() => {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });
            history.push('/permissions');
          });
        } else {
          createRole(data).then(() => {
            addToast({
              type: 'success',
              title: 'Sucesso',
              description: 'Salvo com sucesso',
            });
            reset();
            history.push('/permissions');
          });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [addToast, id],
  );

  useEffect(() => {
    if (id) {
      getRoleById(id).then((data) => {
        setRoles(data);

        const roleFormEdit = {
          name: data.name,
          permissions: data.permissions.map((perm) => perm.id),
        };

        reset(roleFormEdit);
      });
    }

    // eslint-disable-next-line
  }, [id]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/permissions">
              <MdKeyboardBackspace size={24} color="#fff" />
              Permissões
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={id ? `Editando: ${roles?.slug}` : 'Nova Permissão'}
            icon={FaUserCog}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Descrição</C.Label>
            <C.Input
              type="text"
              {...register('name')}
              errors={errors.name}
              name="name"
              placeholder="Descrição"
            />
          </C.FormGroup>

          <S.PermissionContainer>
            {Object.entries(permissions).map((item) => (
              <S.TitlePermission key={item[0]}>
                <h3>{item[0]}</h3>
                <C.Checkbox
                  {...register('permissions')}
                  name="permissions"
                  items={Array.from(item[1])}
                />
              </S.TitlePermission>
            ))}
          </S.PermissionContainer>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
