import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import {
  IContasReceberBaixas,
  IContasReceberBaixasForm,
} from 'pages/ContasReceberBaixas/interfaces';

import { formatCurrencyBR, formatCurrencyDataBase } from 'utils/helpers';
import { CustomFormats, toFormatDate } from '../../../utils/dateHelpers';

const createBaixaData = (titulo: IContasReceberBaixasForm) => {
  const data = {
    ...titulo,
    cliente_id: titulo.cliente_id ? titulo.cliente_id.value : null,
    forma_pagamento_id: titulo.forma_pagamento_id
      ? titulo.forma_pagamento_id.value
      : null,
    conta_id: titulo.conta_id ? titulo.conta_id.value : null,
    valor_titulo: formatCurrencyDataBase(titulo.valor_titulo),
    valor_acrescimo: formatCurrencyDataBase(titulo.valor_acrescimo),
    valor_desconto: formatCurrencyDataBase(titulo.valor_desconto),
    valor_juros: formatCurrencyDataBase(titulo.valor_juros),
    valor_pago: formatCurrencyDataBase(titulo.valor_pago),
    data_pagamento: titulo.data_pagamento
      ? new Date(titulo.data_pagamento)
      : '',
  };

  return data;
};

const getBaixa = async (id: string): Promise<IContasReceberBaixas> => {
  const { data } = await api.get<IContasReceberBaixas>(
    `/baixas/contas-receber/${id}`,
  );

  const tituloFormatadado = {
    ...data,
    valor_titulo: formatCurrencyBR(Number(data.valor_titulo), false),
    valor_acrescimo: formatCurrencyBR(Number(data.valor_acrescimo), false),
    valor_desconto: formatCurrencyBR(Number(data.valor_desconto), false),
    valor_juros: formatCurrencyBR(Number(data.valor_juros), false),
    valor_pago: formatCurrencyBR(Number(data.valor_pago), false),
    data_pagamento: data.data_pagamento ? new Date(data.data_pagamento) : null,
    cliente_id: data?.cliente?.id
      ? {
          label: String(data?.cliente?.nome) || '',
          value: String(data?.cliente?.id) || '',
        }
      : '',
    forma_pagamento_id: data?.forma_pagamento?.id
      ? {
          label: String(data?.forma_pagamento?.nome) || '',
          value: String(data?.forma_pagamento?.id) || '',
        }
      : '',
    conta_id: data?.conta?.id
      ? {
          label: String(data?.conta?.nome) || '',
          value: String(data?.conta?.id) || '',
        }
      : '',
  };

  return tituloFormatadado;
};

const getBaixas = async (
  params: IParams,
): Promise<IPaginate<IContasReceberBaixas>> => {
  const response = await api.get<IPaginate<IContasReceberBaixas>>(
    '/baixas/contas-receber',
    {
      params,
    },
  );

  const titulosFormatado = response.data.data.map(
    (item: IContasReceberBaixas) => {
      return {
        ...item,
        valor_titulo: formatCurrencyBR(Number(item.valor_titulo)),
        valor_acrescimo: formatCurrencyBR(Number(item.valor_acrescimo)),
        valor_desconto: formatCurrencyBR(Number(item.valor_desconto)),
        valor_juros: formatCurrencyBR(Number(item.valor_juros)),
        valor_pago: formatCurrencyBR(Number(item.valor_pago)),
        data_pagamento: item.data_pagamento
          ? toFormatDate(
              new Date(item.data_pagamento),
              CustomFormats.dd_MM_yyyy,
            )
          : null,
      };
    },
  );

  return {
    ...response.data,
    data: titulosFormatado,
  };
};

const createBaixa = async (titulo: IContasReceberBaixasForm): Promise<void> => {
  const data = createBaixaData(titulo);

  await api.post('/baixas/contas-receber', data);
};

const updateBaixa = async (
  id: string,
  titulo: IContasReceberBaixasForm,
): Promise<IContasReceberBaixas> => {
  const data = createBaixaData(titulo);

  const response = await api.put<IContasReceberBaixas>(
    `/baixas/contas-receber/${id}`,
    data,
  );

  return response.data;
};

const deleteBaixa = async (titulo: {
  id: string;
  motivo_cancelamento?: string;
}): Promise<void> => {
  await api.delete(`/baixas/contas-receber/${titulo.id}`, {
    data: { motivo_cancelamento: titulo.motivo_cancelamento },
  });
};

export { getBaixa, getBaixas, createBaixa, updateBaixa, deleteBaixa };
