import React from 'react';

import * as S from './styles';

interface ItemProps {
  id: number;
  name: string;
}

export interface Checkprops {
  items: ItemProps[];
  name: string;
  displayInline?: boolean;
}

const CheckboxBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  Checkprops
> = ({ items, name, displayInline = false, ...rest }, ref) => {
  return (
    <S.Container displayInline={displayInline}>
      {items.map((check: ItemProps) => (
        <label htmlFor={`${name}-${check.id}`} key={check.id}>
          <input
            id={`${name}-${check.id}`}
            type="checkbox"
            name={name}
            value={check.id}
            ref={ref}
            {...rest}
          />
          <span className="checkmark" />
          {check.name}
        </label>
      ))}
    </S.Container>
  );
};

export const Checkbox = React.forwardRef(CheckboxBase);
