import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { EnvioCorteList } from './List';
import { EnvioCorteForm } from './Form';
import { EnvioCortePrint } from './Print';

export const EnvioCorte: React.FC = () => {
  return (
    <Switch>
      <Route path="/envio-corte" component={EnvioCorteList} isPrivate exact />
      <Route path="/envio-corte/new" component={EnvioCorteForm} isPrivate />
      <Route path="/envio-corte/print" component={EnvioCortePrint} isPrivate />
      <Route
        path="/envio-corte/edit/:id"
        component={EnvioCorteForm}
        isPrivate
      />
    </Switch>
  );
};
