import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Consolidado } from './Consolidado';

export const ContasPagarPessoalRelatorios: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/relatorios/contas-pagar-pessoal/consolidado"
        component={Consolidado}
        isPrivate
        exact
      />
    </Switch>
  );
};
