import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ContasPagarPessoalBaixasList } from './List';
import { ContasPagarPessoalBaixasForm } from './Form';
import { ContasPagarPessoalBaixasPrint } from './Print';

export const ContasPagarPessoalBaixas: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/baixas/contas-pagar-pessoal"
        component={ContasPagarPessoalBaixasList}
        isPrivate
        exact
      />
      <Route
        path="/baixas/contas-pagar-pessoal/new"
        component={ContasPagarPessoalBaixasForm}
        isPrivate
      />
      <Route
        path="/baixas/contas-pagar-pessoal/print"
        component={ContasPagarPessoalBaixasPrint}
        isPrivate
      />
      <Route
        path="/baixas/contas-pagar-pessoal/edit/:id"
        component={ContasPagarPessoalBaixasForm}
        isPrivate
      />
    </Switch>
  );
};
