import React, { useState, useEffect } from 'react';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { useDropzone, DropzoneInputProps } from 'react-dropzone';
import { useToast } from 'contexts';
import api from '../../services/api';
import { FileList } from './FileList';
import * as S from './styles';

export interface UploadFile {
  file: File;
  id: string;
  name: string;
  readableSize: string;
  preview: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  url: string | undefined;
}

type KeyValue = {
  [key: string]: string | number;
};

type Props = {
  params?: KeyValue;
  url: string;
  files?: UploadFile[];
};

type PropsWithDropZone = Props & DropzoneInputProps;

const MAX_UPLOAD_FILE = 4;

export const UploadDropzone: React.FC<PropsWithDropZone> = ({
  params,
  url,
  files,
}) => {
  const [isDropedFile, setIsDropedFile] = useState(false);
  // const [currentUpload, setCurrentUpload] = useState(false);
  const { addToast } = useToast();
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>(
    files as UploadFile[],
  );

  function updateFile(id: string, data: UploadFile): void {
    const newList = uploadedFiles?.map((uploadedFile: UploadFile) => {
      // console.log({ uploadedFile });
      return id === uploadedFile.id
        ? { ...uploadedFile, ...data }
        : uploadedFile;
    });

    setUploadedFiles(newList);

    // uploadedFiles.forEach((file) =>
    //   console.log(
    //     `id: ${file.id} -> progress: ${file.progress} -> uploaded: ${file.uploaded}`,
    //   ),
    // );
  }

  function processUpload(uploadedFile: UploadFile): void {
    if (!uploadedFile.file || Number.isNaN(uploadedFile.id)) {
      return;
    }
    const data = new FormData();

    if (params) {
      Object.keys(params).forEach((key: string) => {
        // const val = params[key] as string;
        const val = params[key] as string;
        data.append(key, val);
      });
    }
    data.append('image', uploadedFile.file, uploadedFile.name);
    api
      .post(url, data, {
        onUploadProgress: (e: ProgressEvent) => {
          const progress = Number(Math.round((e.loaded * 100) / e.total));
          Object.assign(uploadedFile, { progress });
          updateFile(uploadedFile.id, uploadedFile);
        },
      })
      .then((response) => {
        Object.assign(uploadedFile, {
          progress: 100,
          uploaded: true,
          id: response.data.id,
          url: response.data.image_url,
        });
        updateFile(uploadedFile.id, uploadedFile);
        setIsDropedFile(false);
      })
      .catch(() => {
        Object.assign(uploadedFile, {
          progress: null,
          error: true,
        });
        updateFile(uploadedFile.id, uploadedFile);
      });
  }

  function onDrop(dropFiles: File[]): void {
    if (dropFiles.length > MAX_UPLOAD_FILE) {
      addToast({
        type: 'info',
        title: 'Aviso',
        description: `Você pode enviar no máximo ${MAX_UPLOAD_FILE} arquivos em paralelo.`,
      });
    } else {
      const newUploadFiles: UploadFile[] = dropFiles.map((file: File) => ({
        file,
        id: uniqueId(),
        name: file.name,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: undefined,
      }));

      setUploadedFiles((oldUploadFiles) =>
        oldUploadFiles.concat(newUploadFiles),
      );

      // uploadedFiles?.forEach(processUpload);
      // setAmountFilesToSend(newUploadFiles.length || 0);
      setIsDropedFile(true);

      // const newList = uploadedFiles.concat(newUploadFiles) as UploadFile[];

      /**
       *  Não está atualizando o STATE
       */

      // setUploadedFiles(newList);
      // uploadedFiles.forEach((file) => processUpload(file));
    }
  }

  useEffect(() => {
    if (isDropedFile) {
      uploadedFiles?.forEach(processUpload);
    }

    // eslint-disable-next-line
  }, [isDropedFile]);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({ onDrop, accept: 'image/*' });

  const renderDragMessage = (
    isActive: boolean,
    isReject: boolean,
  ): React.ReactElement => {
    if (!isActive) {
      return (
        <S.UploadMessage type="default">
          Arraste arquivos aqui...
        </S.UploadMessage>
      );
    }

    if (isReject) {
      return (
        <S.UploadMessage type="error">Arquivo não suportado</S.UploadMessage>
      );
    }

    return (
      <S.UploadMessage type="success">Solte os arquivos aqui</S.UploadMessage>
    );
  };

  async function handleDelete(id: string): Promise<void> {
    await api.delete(`${url}/${id}`);
    setUploadedFiles(uploadedFiles?.filter((item) => item.id !== id));
  }

  useEffect(() => {
    return () => {
      uploadedFiles?.forEach((file) => URL.revokeObjectURL(file.preview));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <S.DropContainer
        {...getRootProps({ refKey: 'innerRef', className: 'dropzone' })}
        isDragActive={isDragActive}
        isDragReject={isDragReject}
      >
        <input {...getInputProps()} />
        {renderDragMessage(isDragActive, isDragReject)}
      </S.DropContainer>
      {!!uploadedFiles?.length && (
        <FileList files={uploadedFiles} onDelete={handleDelete} />
      )}
      {/* <button type="button" onClick={handleStartUpload}>
        Start Upload
      </button> */}
    </>
  );
};
