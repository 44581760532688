import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { FaUserCog } from 'react-icons/fa';
import { MdSearch } from 'react-icons/md';
import { IPaginate, IParams } from 'shared/interfaces';
import { getRoles, removeRole } from 'services/api/permission';
import { useToast } from 'contexts';
import { ModalHandles } from 'components/Modal';

import * as C from 'components';
import * as S from './styles';
import { IRole } from '../interfaces';

export const PermissionList: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [roles, setRoles] = useState<IPaginate<IRole>>();
  const [role, setRole] = useState<IRole>();
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const modalRemoverRef = useRef<ModalHandles>(null);

  const loadRoles = async (params: IParams) => {
    try {
      setLoading(true);
      const response = await getRoles(params);
      setRoles(response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 20,
      term: '',
    };

    loadRoles(params);
  }, []);

  const handleSearch = useCallback(
    async ({ page, per_page }) => {
      const params = {
        page,
        per_page,
        term,
      };

      getRoles(params).then((data) => {
        setRoles(data);
      });
    },
    [term],
  );

  const handleRemove = async (): Promise<void> => {
    try {
      await removeRole(role!.id);
      if (!roles) return;
      const newList = roles.data.filter((item) => item.id !== role?.id);
      setRoles({ ...roles, data: newList });
      modalRemoverRef.current?.closeModal();
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Permissões removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao inserir um novo registro',
      });
    }
  };

  const handleOpenModalRemover = useCallback((roleSelected: IRole): void => {
    setRole(roleSelected);
    modalRemoverRef.current?.openModal();
  }, []);

  return (
    <S.Container>
      <C.HeaderBackground>
        <C.HeaderPage>
          <C.HeaderInfo title="Permissões" icon={FaUserCog} />

          <S.ContainerSearch>
            <C.InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) => {
                e.key === 'Enter'
                  ? handleSearch({ page: 1, per_page: roles?.per_page })
                  : {};
              }}
            />
          </S.ContainerSearch>

          <div>
            <C.Can checkPermission="permissions_create">
              <C.ButtonLink
                title="Adicionar na Permissões"
                to="/permissions/new"
              >
                Adicionar
              </C.ButtonLink>
            </C.Can>
          </div>
        </C.HeaderPage>
      </C.HeaderBackground>

      <S.Panel>
        {loading ? (
          <C.Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <div className="tbl-content">
              <C.Table>
                <thead>
                  <tr>
                    <th className="text-left">Descrição</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {roles &&
                    roles.data.map((item: IRole) => (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td className="text-center">
                          <C.PopUpMenu>
                            <C.Can checkPermission="permissions_update">
                              <button
                                type="button"
                                onClick={() =>
                                  history.push(`/permissions/edit/${item.id}`)
                                }
                              >
                                <S.IconEdit
                                  title="Editar"
                                  size={24}
                                  color="#707070"
                                />
                                Editar
                              </button>
                            </C.Can>

                            <C.Can checkPermission="permissions_delete">
                              <button
                                type="button"
                                onClick={() => handleOpenModalRemover(item)}
                              >
                                <S.IconDelete
                                  title="Remover"
                                  size={24}
                                  color="#707070"
                                />
                                Remover
                              </button>
                            </C.Can>
                          </C.PopUpMenu>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </C.Table>
            </div>
          </>
        )}
        <S.PaginationContainer>
          {roles && (
            <C.Pagination
              page={roles?.page}
              total={roles?.total}
              per_page={roles?.per_page}
              last_page={roles?.last_page}
              onSearch={handleSearch}
            />
          )}
        </S.PaginationContainer>
      </S.Panel>

      <C.Modal ref={modalRemoverRef} options={{ width: '400px' }}>
        <C.ModalHeader>
          <C.ModalTitle>Você Confirma a EXCLUSÃO?</C.ModalTitle>
        </C.ModalHeader>
        <C.ModalBody>
          <p>O registro será removido completamente!</p>
        </C.ModalBody>
        <C.ModalFooter>
          <C.Position align="right">
            <C.Button
              variant="outline-secondary"
              onClick={() => modalRemoverRef.current?.closeModal()}
            >
              Cancelar
            </C.Button>
            <C.Button
              variant="primary"
              onClick={() => handleRemove()}
              style={{ marginLeft: '16px' }}
            >
              SIM, confirmo a exclusão
            </C.Button>
          </C.Position>
        </C.ModalFooter>
      </C.Modal>
    </S.Container>
  );
};
