import { IPaginate, IParams } from 'shared/interfaces';
import { IUser, IUserForm } from 'pages/User/interfaces';

import api from '../../api';

const getUser = async (id: string): Promise<IUser> => {
  const user = await api.get<IUser>(`/users/${id}`);

  const data: IUser = {
    ...user.data,
    role_id: user.data?.roles?.name
      ? {
          label: String(user.data?.roles?.name) || '',
          value: String(user.data?.roles?.id) || '',
        }
      : '',
    resource_id: user.data?.resources?.descricao
      ? {
          label: String(user.data?.resources?.descricao) || '',
          value: String(user.data?.resources?.codigo) || '',
        }
      : '',
  };

  return data;
};

const getUsers = async (params: IParams): Promise<IPaginate<IUser>> => {
  const response = await api.get<IPaginate<IUser>>('/users', { params });

  return response.data;
};

const createUser = async (user: IUserForm): Promise<void> => {
  const data = {
    ...user,
    role_id: user.role_id?.value,
    resource_id: user.resource_id?.value,
  };

  await api.post('/users', data);
};

const updateUser = async (id: string, user: IUserForm): Promise<IUser> => {
  const data = {
    ...user,
    role_id: user.role_id?.value,
    resource_id: user.resource_id?.value,
  };

  const response = await api.put<IUser>(`/users/${id}`, data);

  return response.data;
};

const deleteUser = async (id: string): Promise<void> => {
  await api.delete(`/users/${id}`);
};

export { getUser, getUsers, createUser, updateUser, deleteUser };
