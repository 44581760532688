import styled, { css } from 'styled-components';

interface ContainerProps {
  rounded?: boolean;
  roundedCircle?: boolean;
}

export const Container = styled.div<ContainerProps>`
  > img {
    ${({ rounded }) =>
      rounded
        ? css`
            border-radius: 4px;
          `
        : ''}

    ${({ roundedCircle }) =>
      roundedCircle
        ? css`
            border-radius: 50%;
          `
        : ''}
  }
`;
