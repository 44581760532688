import styled from 'styled-components';

interface ContainerProps {
  height: string;
}

export const Container = styled.div<ContainerProps>`
  max-height: ${({ height }) => (height === 'auto' ? 'auto' : `${height}px`)};
  overflow-x: auto;
  margin-top: 0px;
  border-radius: 8px;
`;
