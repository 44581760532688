import { IPaginate, IParams } from 'shared/interfaces';
import {
  IProdutoFamilia,
  IProdutoFamiliaForm,
} from 'pages/ProdutoFamilia/interfaces';

import api from '../../api';

const createFormDataFamilia = (familia: IProdutoFamiliaForm) => {
  const formData = new FormData();

  if (familia.foto_upload?.length) {
    formData.append('foto', familia.foto_upload[0]);
  }

  const data = {
    nome: familia.nome,
  };

  formData.append('data', JSON.stringify(data));

  return formData;
};

const getFamilia = async (id: string): Promise<IProdutoFamilia> => {
  const response = await api.get<IProdutoFamilia>(`/produto/familias/${id}`);

  return response.data;
};

const getFamilias = async (
  params: IParams,
): Promise<IPaginate<IProdutoFamilia>> => {
  const response = await api.get<IPaginate<IProdutoFamilia>>(
    '/produto/familias',
    {
      params,
    },
  );

  return response.data;
};

const createFamilia = async (familia: IProdutoFamiliaForm): Promise<void> => {
  const data = createFormDataFamilia(familia);

  await api.post('/produto/familias', data);
};

const updateFamilia = async (
  id: string,
  familia: IProdutoFamiliaForm,
): Promise<IProdutoFamilia> => {
  const data = createFormDataFamilia(familia);

  const response = await api.put<IProdutoFamilia>(
    `/produto/familias/${id}`,
    data,
  );

  return response.data;
};

const deleteFamilia = async (id: string): Promise<void> => {
  await api.delete(`/produto/familias/${id}`);
};

export { getFamilia, getFamilias, createFamilia, updateFamilia, deleteFamilia };
