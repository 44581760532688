import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { VendasList } from './List';
import { VendasForm } from './Form';
import { VendasPrint } from './Print';

export const Vendas: React.FC = () => {
  return (
    <Switch>
      <Route path="/vendas" component={VendasList} isPrivate exact />
      <Route path="/vendas/new" component={VendasForm} isPrivate />
      <Route path="/vendas/print" component={VendasPrint} isPrivate />
      <Route path="/vendas/edit/:id" component={VendasForm} isPrivate />
    </Switch>
  );
};
