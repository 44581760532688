import styled, { css } from 'styled-components';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

interface DisabledProps {
  disabled: boolean;
}

export const Container = styled.div`
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--background);
  padding: 0px 16px;
`;

export const PaginantionInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & span {
    display: inline-block;
    min-width: 850px;
    text-align: right;
    padding-right: 16px;
    width: fit-content;
  }
  & > select {
    max-width: 80px;
  }
`;

export const SelectPerPage = styled.select`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const PaginationButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
`;

const IconCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 4px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    background-color: var(--background-pagination-icon-hover);
  }
`;

export const IconPreviusPage = styled(MdKeyboardArrowLeft)<DisabledProps>`
  ${IconCss}

  ${(props) =>
    props.disabled
      ? css`
          fill: var(--background-pagination-icon-disabled);
        `
      : css`
          fill: var(--background-pagination-icon-enabled);
        `}
`;
export const IconNextPage = styled(MdKeyboardArrowRight)<DisabledProps>`
  ${IconCss}
  ${(props) =>
    props.disabled
      ? css`
          fill: var(--background-pagination-icon-disabled);
        `
      : css`
          fill: var(--background-pagination-icon-enabled);
        `}
`;
