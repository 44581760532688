import React, { useCallback, ChangeEvent, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiMail, FiLock, FiUser, FiCamera } from 'react-icons/fi';
import * as Yup from 'yup';

import { FaUser } from 'react-icons/fa';
import { updateProfile, updateAvatar } from 'services/api/Profile';
import avartarImgDefault from 'assets/avatar.png';
import { useAuth, useToast } from 'contexts';

import * as C from 'components';
import * as S from './styles';

import { IProfileFormData } from './interfaces';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string()
    .required('Email obrigatório')
    .email('Digite um e-mail válido'),
  old_password: Yup.string(),
  password: Yup.string().when('old_password', {
    is: (val: string) => !!val.length,
    then: Yup.string().required('Senha Atual Obrigatória'),
    otherwise: Yup.string(),
  }),
  password_confirmation: Yup.string()
    .when('old_password', {
      is: (val: string) => !!val.length,
      then: Yup.string().required('Nova Senha Obrigatória'),
      otherwise: Yup.string(),
    })
    .oneOf([Yup.ref('password'), undefined], 'Confirmação incorreta'),
});

export const Profile: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });
  const { addToast } = useToast();
  const { user, updateUser } = useAuth();

  const avatarImg = user.avatar_url ? user.avatar_url : avartarImgDefault;

  const onSubmit = useCallback(
    async (data: IProfileFormData) => {
      try {
        const { name, email, old_password, password, password_confirmation } =
          data;

        const arrayRoles = data.roles?.map((role) => role.value);
        const formData = {
          name,
          email,
          roles: arrayRoles,
          ...(old_password
            ? {
                old_password,
                password,
                password_confirmation,
              }
            : {}),
        };

        updateProfile(formData)
          .then((userUpdated) => {
            updateUser(userUpdated);
            addToast({
              type: 'success',
              title: 'Perfil atualizado!',
              description:
                'Suas informações do perfil foram atualizadas com sucesso!',
            });
          })
          .catch(() => {
            addToast({
              type: 'error',
              title: 'Ops, Erro na atualização',
              description:
                'Ocorreu um erro ao atualizar perfil, tente novamente',
            });
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro na atualização',
          description: 'Ocorreu um erro ao atualizar perfil, tente novamente',
        });
      }
    },
    [addToast, updateUser],
  );

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('avatar', e.target.files[0]);

        updateAvatar(data).then((response) => {
          updateUser(response);
          addToast({
            type: 'success',
            title: 'Avatar atualizado!',
            description: '',
          });
        });
      }
    },
    [addToast, updateUser],
  );

  useEffect(() => {
    reset(user);
    // eslint-disable-next-line
  }, []);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <C.HeaderInfo title="Meu Perfil" icon={FaUser} />
        </S.HeaderPageDatail>
      </C.HeaderBackground>
      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.AvatarInput>
            <img src={avatarImg} alt={user.name} />
            <label htmlFor="avatar">
              <FiCamera />
              <input type="file" id="avatar" onChange={handleAvatarChange} />
            </label>
          </S.AvatarInput>

          <C.Input
            type="text"
            {...register('name')}
            errors={errors.name}
            name="name"
            icon={FiUser}
            placeholder="Nome"
          />
          <C.Input
            type="text"
            {...register('email')}
            errors={errors.email}
            name="email"
            icon={FiMail}
            placeholder="E-mail"
            readOnly
          />

          <C.Input
            type="password"
            {...register('old_password')}
            errors={errors.old_password}
            containerStyle={{ marginTop: 24 }}
            name="old_password"
            icon={FiLock}
            autoComplete="off"
            placeholder="Senha Atual"
          />

          <C.Input
            type="password"
            {...register('password')}
            errors={errors.password}
            name="password"
            icon={FiLock}
            autoComplete="off"
            placeholder="Nova Senha"
          />

          <C.Input
            type="password"
            {...register('password_confirmation')}
            errors={errors.password_confirmation}
            name="password_confirmation"
            icon={FiLock}
            autoComplete="off"
            placeholder="Confirmar Senha"
          />

          <C.Button type="submit">Confirmar mudanças</C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
