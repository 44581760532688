import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import { ICentroCusto, ICentroCustoForm } from './interfaces';

const getCentroCusto = async (id: string): Promise<ICentroCusto> => {
  const response = await api.get<ICentroCusto>(`/centro-custo/${id}`);

  return response.data;
};

const getCentroCustos = async (
  params: IParams,
): Promise<IPaginate<ICentroCusto>> => {
  const response = await api.get<IPaginate<ICentroCusto>>('/centro-custo', {
    params,
  });

  return response.data;
};

const createCentroCusto = async (conta: ICentroCustoForm): Promise<void> => {
  await api.post('/centro-custo', conta);
};

const updateCentroCusto = async (
  id: string,
  conta: ICentroCustoForm,
): Promise<ICentroCusto> => {
  const response = await api.put<ICentroCusto>(`/centro-custo/${id}`, conta);

  return response.data;
};

const deleteCentroCusto = async (id: string): Promise<void> => {
  await api.delete(`/centro-custo/${id}`);
};

export {
  getCentroCusto,
  getCentroCustos,
  createCentroCusto,
  updateCentroCusto,
  deleteCentroCusto,
};
