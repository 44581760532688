import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PlanoContasList } from './List';
import { PlanoContasForm } from './Form';

export const PlanoContas: React.FC = () => {
  return (
    <Switch>
      <Route path="/plano-contas" component={PlanoContasList} isPrivate exact />
      <Route path="/plano-contas/new" component={PlanoContasForm} isPrivate />
      <Route
        path="/plano-contas/edit/:id"
        component={PlanoContasForm}
        isPrivate
      />
    </Switch>
  );
};
