import React from 'react';

import * as S from './styles';

interface TableResponsiveProps {
  height?: string;
}

export const TableResponsive: React.FC<TableResponsiveProps> = ({
  height = 'auto',
  children,
}) => {
  return <S.Container height={height}>{children}</S.Container>;
};
