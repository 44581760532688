import styled, { css } from 'styled-components';
import { MdCheckCircle } from 'react-icons/md';

interface StatusProps {
  enable: string;
}

export const Status = styled(MdCheckCircle)<StatusProps>`
  ${({ enable }) =>
    enable === 'true' || enable === '1'
      ? css`
          fill: var(--enable);
        `
      : css`
          fill: var(--disable);
        `};
`;
