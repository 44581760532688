import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { Loader } from './Loader';
import { ControlPanel } from './ControlPanel';

import { IPDFReaderProps, IPDFLoadSuccessProps } from './interfaces';

import * as S from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFReader: React.FC<IPDFReaderProps> = ({ fileUrl, fileName }) => {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  function onDocumentLoadSuccess({
    numPages: totalPages,
  }: IPDFLoadSuccessProps) {
    setNumPages(totalPages);
    setIsLoading(false);
  }

  return (
    <S.Container>
      <Loader isLoading={isLoading} />

      <section
        id="pdf-section"
        className="d-flex flex-column align-items-center w-100"
      >
        {!isLoading && (
          <ControlPanel
            scale={scale}
            setScale={setScale}
            numPages={numPages || 1}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            fileUrl={fileUrl}
            fileName={fileName}
          />
        )}
        <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
      </section>
    </S.Container>
  );
};
