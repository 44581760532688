import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { EnvioAjusteEstoqueList } from './List';
import { EnvioAjusteEstoqueForm } from './Form';

export const AjusteEstoque: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/ajuste-estoque"
        component={EnvioAjusteEstoqueList}
        isPrivate
        exact
      />
      <Route
        path="/ajuste-estoque/new"
        component={EnvioAjusteEstoqueForm}
        isPrivate
      />
      <Route
        path="/ajuste-estoque/edit/:id"
        component={EnvioAjusteEstoqueForm}
        isPrivate
      />
    </Switch>
  );
};
