import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ProdutoList } from './List';
import { ProdutosForm } from './Form';

export const Produto: React.FC = () => {
  return (
    <Switch>
      <Route path="/produtos" component={ProdutoList} isPrivate exact />
      <Route path="/produtos/new" component={ProdutosForm} isPrivate />
      <Route path="/produtos/edit/:id" component={ProdutosForm} isPrivate />
    </Switch>
  );
};
