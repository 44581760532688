export const tipoPessoaOptions = [
  { label: 'Física', value: 'F' },
  { label: 'Jurídica', value: 'J' },
];

export const tipoFornecedorOptions = [
  { label: 'Costureira', value: 'C' },
  { label: 'Fornecedor', value: 'F' },
  { label: 'Pessoal', value: 'P' },
];
