import styled, { css } from 'styled-components';
import { MdDelete, MdAddCircle } from 'react-icons/md';

export const Container = styled.div``;

export const Panel = styled.div`
  background: var(--background-panel);
  top: -50px;
  border-radius: 8px;
  position: relative;
  margin: 0px auto;
  height: fit-content;
  width: 100%;
  max-width: 1050px;
  padding: 24px;
  min-height: 600px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;

  & > form {
    display: flex;
    flex-direction: column;
    max-width: 1050px;
  }
`;

export const FormHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 8px;
`;

export const TableGrid = styled.div`
  display: flex;
  width: 100%;
`;

export const FormHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 0 12px;

  span {
    font-size: 24px;
    font-weight: bold;
    color: var(--text);
  }

  div {
    font-size: 20px;
  }
`;

export const HeaderPageDatail = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  & div + div {
    margin: 8px 32px 0px 32px;
    justify-content: flex-start;
  }
`;

export const BackLink = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 32px 0px 32px;

  svg {
    margin-right: 8px;
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--background-header-text);
  }
`;

const IconCss = css`
  flex-shrink: 0;
  padding: 4px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    background: #ffffff12;
  }
`;

export const IconDelete = styled(MdDelete)`
  ${IconCss}
`;

export const IconAdd = styled(MdAddCircle)`
  ${IconCss}
`;

export const Table = styled.table`
  border-collapse: collapse !important;
  color: var(--text);
  margin-top: 20px;
  width: 100%;

  & thead tr th {
    border-width: 0;
    border-style: solid;
    background: var(--background);
    padding: 1.2rem;
    text-align: left;
  }

  & thead tr th:first-child {
    border-top-left-radius: 8px;
  }

  & thead tr th:last-child {
    border-top-right-radius: 8px;
  }

  & tbody tr td {
    padding: 0.8rem;
    border-bottom: 1px solid var(--border-table);
  }

  & tbody > tr:last-child td {
    border-bottom: none;
  }

  & tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  div {
    font-size: 18px;
    font-weight: bold;
  }
`;
