import styled from 'styled-components';

export const Container = styled.div`
  > label {
    margin-bottom: 4px !important;
  }

  > div {
    margin: 8px 0px;
  }
`;
