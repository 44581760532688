import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { FaBoxOpen } from 'react-icons/fa';
import { MdSearch } from 'react-icons/md';
import { IPaginate, IKeyValue } from 'shared/interfaces';
import { useToast } from 'contexts';
import { ModalHandles } from 'components/Modal';
import { UploadFile } from 'components/UploadDropzone';

import {
  getProdutos,
  deleteProduto,
  getProdutoImagens,
} from 'services/api/produto';

import * as C from 'components';
import * as S from './styles';

import { IProduto } from '../interfaces';

export const ProdutoList: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [produtos, setProdutos] = useState<IPaginate<IProduto>>();
  const [produtoAtual, setProdutoAtual] = useState<IProduto>();
  const [paramsDropZone, setParamsDropZone] = useState<IKeyValue>();
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const modalRemoverRef = useRef<ModalHandles>(null);
  const modalUploadRef = useRef<ModalHandles>(null);

  const handleSearch = useCallback(
    ({ page, per_page }) => {
      setLoading(true);

      const params = {
        page,
        per_page,
        term,
      };

      getProdutos(params)
        .then((data) => {
          setProdutos(data);

          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'Ops, Erro',
            description: e.response?.data?.message,
          });

          setLoading(false);
        });
    },
    [addToast, term],
  );

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 20,
    };

    handleSearch(params);
  }, [handleSearch]);

  const handleRemove = async (): Promise<void> => {
    try {
      await deleteProduto(produtoAtual!.id);

      if (!produtos) return;

      const newList = produtos.data.filter(
        (item) => item.id !== produtoAtual?.id,
      );
      setProdutos({ ...produtos, data: newList });

      modalRemoverRef.current?.closeModal();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Registro removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao remover o registro',
      });
    }
  };

  const handleOpenModalRemover = useCallback(
    (produtoSelected: IProduto): void => {
      setProdutoAtual(produtoSelected);
      modalRemoverRef.current?.openModal();
    },
    [],
  );

  const loadProductImages = useCallback(async (produto_id) => {
    const images = await getProdutoImagens(produto_id);

    setUploadedFiles(images);
  }, []);

  const handleOpenModalUpload = useCallback(
    async (productSelected: IProduto): Promise<void> => {
      await loadProductImages(productSelected.id);

      setProdutoAtual(productSelected);

      const paramsDrop = {
        data: `{"produto_id": "${productSelected.id}"}`,
      } as IKeyValue;

      setParamsDropZone(paramsDrop);
      modalUploadRef.current?.openModal();
    },
    [loadProductImages],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <C.HeaderPage>
          <C.HeaderInfo title="Produtos" icon={FaBoxOpen} />

          <S.ContainerSearch>
            <C.InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) => {
                e.key === 'Enter'
                  ? handleSearch({
                      page: 1,
                      per_page: produtos?.per_page,
                    })
                  : {};
              }}
            />
          </S.ContainerSearch>

          <div>
            <C.Can checkPermission="produtos_create">
              <C.ButtonLink title="Adicionar Produto" to="/produtos/new">
                Adicionar
              </C.ButtonLink>
            </C.Can>
          </div>
        </C.HeaderPage>
      </C.HeaderBackground>

      <S.Panel>
        {loading ? (
          <C.Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <div className="tbl-content">
              <C.Table>
                <thead>
                  <tr>
                    <th className="text-center">Foto</th>
                    <th className="text-left">Descrição</th>
                    <th className="text-left">Familia</th>
                    <th className="text-left">Cor</th>
                    <th className="text-right">Quantidade</th>
                    <th className="text-right">Preço</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {produtos &&
                    produtos.data.map((item: IProduto) => (
                      <tr key={item.id}>
                        <td className="text-center">
                          <C.Image
                            roundedCircle
                            height="80"
                            width="80"
                            src={item.foto_url}
                            alt={item.nome}
                          />
                        </td>
                        <td>{item.nome}</td>
                        <td>{item.familia?.nome}</td>
                        <td>{item.cor?.nome}</td>
                        <td className="text-right">
                          {item.quantidadeFormatado}
                        </td>
                        <td className="text-right">{item.precoFormatado}</td>
                        <td className="text-center">
                          <C.PopUpMenu>
                            <C.Can checkPermission="produtos_update">
                              <button
                                type="button"
                                onClick={() =>
                                  history.push(`/produtos/edit/${item.id}`)
                                }
                              >
                                <S.IconEdit
                                  title="Editar"
                                  size={24}
                                  color="#707070"
                                />
                                Editar
                              </button>
                            </C.Can>

                            <C.Can checkPermission="produtos_update">
                              <button
                                type="button"
                                onClick={() => handleOpenModalUpload(item)}
                              >
                                <S.IconUpload
                                  title="Fotos"
                                  size={24}
                                  color="#707070"
                                />
                                Fotos
                              </button>
                            </C.Can>

                            <C.Can checkPermission="produtos_delete">
                              <button
                                type="button"
                                onClick={() => handleOpenModalRemover(item)}
                              >
                                <S.IconDelete
                                  title="Remover"
                                  size={24}
                                  color="#707070"
                                />
                                Remover
                              </button>
                            </C.Can>
                          </C.PopUpMenu>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </C.Table>
            </div>
          </>
        )}
        <S.PaginationContainer>
          {produtos && (
            <C.Pagination
              page={produtos?.page}
              total={produtos?.total}
              per_page={produtos?.per_page}
              last_page={produtos?.last_page}
              onSearch={handleSearch}
            />
          )}
        </S.PaginationContainer>
      </S.Panel>

      <C.Modal ref={modalRemoverRef} options={{ width: '400px' }}>
        <C.ModalHeader>
          <C.ModalTitle>Você Confirma a EXCLUSÃO?</C.ModalTitle>
        </C.ModalHeader>
        <C.ModalBody>
          <p>O registro será removido completamente!</p>
        </C.ModalBody>
        <C.ModalFooter>
          <C.Position align="right">
            <C.Button
              variant="outline-secondary"
              onClick={() => modalRemoverRef.current?.closeModal()}
            >
              Cancelar
            </C.Button>
            <C.Button
              variant="primary"
              onClick={() => handleRemove()}
              style={{ marginLeft: '16px' }}
            >
              SIM, confirmo a exclusão
            </C.Button>
          </C.Position>
        </C.ModalFooter>
      </C.Modal>

      <C.Modal ref={modalUploadRef} options={{ width: '800px' }}>
        <C.ModalHeader>
          <C.ModalTitle>Fotos: {produtoAtual?.nome}</C.ModalTitle>
        </C.ModalHeader>
        <C.ModalBody>
          <C.UploadDropzone
            files={uploadedFiles}
            url="produto/imagens"
            params={paramsDropZone}
          />
        </C.ModalBody>
      </C.Modal>
    </S.Container>
  );
};
