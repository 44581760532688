import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PlanoContasPaiList } from './List';
import { PlanoContasPaiForm } from './Form';

export const PlanoContasPai: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/plano-contas-pai"
        component={PlanoContasPaiList}
        isPrivate
        exact
      />
      <Route
        path="/plano-contas-pai/new"
        component={PlanoContasPaiForm}
        isPrivate
      />
      <Route
        path="/plano-contas-pai/edit/:id"
        component={PlanoContasPaiForm}
        isPrivate
      />
    </Switch>
  );
};
