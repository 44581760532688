import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { FaMoneyBill } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';
import axios from 'axios';
import FileDownload from 'js-file-download';

import { onDelay } from 'utils/debounce';

import { IOptionReactSelect } from 'shared/interfaces';

// Supondo que getCostureiras seja renomeado para getPessoal
import { getPessoal } from 'services/api/fornecedor';
import { getFormasPagamento } from 'services/api/formaPagamento';
import { getRelatorioTitulo } from 'services/api/contasPagarPessoal';
import { getCentroCustos } from 'pages/CentroCusto/api';
import { getPlanoContas } from 'pages/PlanoContas/api';

import { useToast } from 'contexts';
import * as C from 'components';

import { statusTituloOption } from './options';

import * as S from './styles';

import { IRelatorioContasPagarPessoalForm } from '../interfaces';

export const ContasPagarPessoalPrint: React.FC = () => {
  const { addToast } = useToast();

  const currentDate = new Date();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<FieldValues>({
    defaultValues: {
      status: { label: 'Abertos', value: 'A' },
      data_vencimento_inicial: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
      ),
      data_vencimento_final: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0,
      ),
    },
  });

  const [loading, setLoading] = useState(false);

  const loadOptionsFormas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getFormasPagamento({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsCentroCusto = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getCentroCustos({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsPlanoContas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getPlanoContas({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsFornecedores = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getPessoal({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const onSubmit = useCallback(
    async (data: IRelatorioContasPagarPessoalForm) => {
      try {
        setLoading(true);

        const response = await getRelatorioTitulo(data);

        if (response.link) {
          axios({
            url: `${response.link}`,
            method: 'GET',
            responseType: 'blob',
          }).then((relatorio) => {
            FileDownload(relatorio.data, 'Titulos pessoal.pdf');
          });
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/contas-pagar-pessoal">
              <MdKeyboardBackspace size={24} color="#fff" />
              Títulos pagar pessoal
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title="Relatórios contas a pagar pessoal"
            icon={FaMoneyBill}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <C.FormGroup>
              <C.Label>Data corte inicial</C.Label>
              <C.InputDatePicker
                {...register('data_compra_inicial')}
                control={control}
                errors={errors.data_compra_inicial}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_compra_inicial')}
                name="data_compra_inicial"
                placeholder="Data Compra"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Data corte final</C.Label>
              <C.InputDatePicker
                {...register('data_compra_final')}
                control={control}
                errors={errors.data_compra_final}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_compra_final')}
                name="data_compra_final"
                placeholder="Data Compra"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Data vencimento inicial</C.Label>
              <C.InputDatePicker
                {...register('data_vencimento_inicial')}
                control={control}
                errors={errors.data_vencimento_inicial}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_vencimento_inicial')}
                name="data_vencimento_inicial"
                placeholder="Data Vencimento"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Data vencimento final</C.Label>
              <C.InputDatePicker
                {...register('data_vencimento_final')}
                control={control}
                errors={errors.data_vencimento_final}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_vencimento_final')}
                name="data_vencimento_final"
                placeholder="Data Vencimento"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Costureira</C.Label>
              <C.ReactSelectAsync
                {...register('fornecedor_id')}
                control={control}
                errors={errors.fornecedor_id}
                loadOptions={loadOptionsFornecedores}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum costureira localizada"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Forma</C.Label>
              <C.ReactSelectAsync
                {...register('forma_pagamento_id')}
                control={control}
                errors={errors.forma_pagamento_id}
                loadOptions={loadOptionsFormas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma forma pagamento localizada"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Centro Custo</C.Label>
              <C.ReactSelectAsync
                {...register('centro_custo_id')}
                control={control}
                errors={errors.centro_custo_id}
                loadOptions={loadOptionsCentroCusto}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum centro custo localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Plano Contas</C.Label>
              <C.ReactSelectAsync
                {...register('plano_contas_id')}
                control={control}
                errors={errors.plano_contas_id}
                loadOptions={loadOptionsPlanoContas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma plano de contas localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Status título</C.Label>
              <C.ReactSelect
                {...register('status')}
                name="status"
                control={control}
                errors={errors.status}
                options={statusTituloOption}
              />
            </C.FormGroup>
          </S.FormContainer>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Gerar relatorio"
          >
            Gerar relatório
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
