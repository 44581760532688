import React, { InputHTMLAttributes } from 'react';
import Select from 'react-select/async';
import { Controller, Control, FieldError } from 'react-hook-form';

import { IOptionReactSelect } from 'shared/interfaces';
import { OptionTypeBase } from 'react-select';
import * as S from './styles';

interface ReactSelectAsyncProps extends InputHTMLAttributes<HTMLSelectElement> {
  name: string;
  control: Control;
  errors: FieldError | undefined;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  cacheOptions?: boolean;
  loadOptions?: (
    inputValue: string,
    callback: (options: OptionTypeBase[]) => void,
  ) => void;
  defaultOptions?: IOptionReactSelect[];
  loadingMessage?: string;
  noOptionsMessage?: string;
}

const ReactSelectAsyncBase: React.ForwardRefRenderFunction<
  HTMLSelectElement,
  ReactSelectAsyncProps
> = (
  {
    name,
    control,
    errors,
    isClearable = true,
    isDisabled = false,
    isLoading = false,
    isRtl = false,
    isSearchable = true,
    isMulti = false,
    cacheOptions = true,
    loadOptions,
    defaultOptions = [],
    loadingMessage = 'Buscando...',
    noOptionsMessage = 'Nenhum registro localizado',
    ...rest
  },
  ref,
) => {
  return (
    <>
      <S.Container>
        <Controller
          name={name}
          control={control}
          {...rest}
          render={({ field }) => (
            <Select
              {...field}
              isClearable={isClearable}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isRtl={isRtl}
              isSearchable={isSearchable}
              isMulti={isMulti}
              loadingMessage={() => loadingMessage}
              noOptionsMessage={() => noOptionsMessage}
              selectRef={ref}
              loadOptions={loadOptions}
              defaultOptions={defaultOptions}
              cacheOptions={cacheOptions}
              onChange={(selectedOption) => {
                field.onChange(selectedOption);
              }}
              styles={{
                control: (styles) => ({
                  ...styles,
                  backgroundColor: 'var(--background-input)',
                  color: '#999',
                  padding: '0.3rem',
                  border: '1px solid var(--border-input)',
                  borderRadius: 4,
                  fontSize: '1.6rem',
                }),
                option: (base) => ({
                  ...base,
                  height: '100%',
                  fontSize: '1.6rem',
                  // backgroundColor: 'var(--background-input)',
                  // color: '#999',
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 4,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--primary-light)',
                  primary: 'var(--primary)',
                },
              })}
            />
          )}
        />
      </S.Container>
      {errors && <S.ErrorMsg>{errors.message || ''}</S.ErrorMsg>}
    </>
  );
};

export const ReactSelectAsync = React.forwardRef(ReactSelectAsyncBase);
