import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ImScissors } from 'react-icons/im';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import { getEnvioCorteByNumber } from 'services/api/cortes';

import {
  createRecebimentoCorte,
  updateRecebimentoCorte,
  getRecebimentoCorte,
} from 'services/api/cortesRecebimento';
import { getFormasPagamento } from 'services/api/formaPagamento';

import { useToast } from 'contexts';
import { formatCurrencyDataBase, formatCurrencyBR } from 'utils/helpers';

import * as C from 'components';
import * as S from './styles';

import { IRecebimentoCorte, IRecebimentoCorteForm } from '../interfaces';

const schema = Yup.object().shape({
  fornecedor_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Costureira obrigatorio')
    .typeError('Costureira obrigatorio'),
  produto_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Produto obrigatorio')
    .typeError('Produto obrigatorio'),
  forma_pagamento_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Forma pagamento obrigatorio')
    .typeError('Forma pagamento obrigatorio'),
  data_recebimento: Yup.string()
    .typeError('Data recebimento obrigatório')
    .required('Data recebimento obrigatório'),
  data_vencimento: Yup.string()
    .typeError('Data vencimento obrigatório')
    .required('Data vencimento obrigatório'),
  quantidade_recebimento: Yup.string()
    .typeError('Quantidade é obrigatório')
    .required('Quantidade é obrigatório'),
  valor_total_recebimento: Yup.string()
    .typeError('Valor total obrigatório')
    .required('Valor total obrigatório'),
});

export const RecebimentoCorteForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
    setError,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [corte, setCorte] = useState<IRecebimentoCorte>();
  const [quantidadeEnvioSelected, setQuantidadeEnvioSelected] = useState('');
  const [precoCustoSelected, setPrecoCustoSelected] = useState('');
  const [fornecedorSelected, setFornecedorSelected] =
    useState<IOptionReactSelect>();
  const [produtoSelected, setProdutoSelected] = useState<IOptionReactSelect>();

  const quantidadeEnvio = watch('quantidade_envio');
  const quantidadeRecebimento = watch('quantidade_recebimento');
  const precoCusto = watch('preco_custo_costureira');
  const numeroCorte = watch('numero_corte');

  useEffect(() => {
    if (numeroCorte?.value && !id) {
      setValue('fornecedor_id', fornecedorSelected);
      setValue('produto_id', produtoSelected);
      setValue('quantidade_envio', quantidadeEnvioSelected);
      setValue('preco_custo_costureira', precoCustoSelected);
    }

    if (!numeroCorte?.value && !id) {
      setValue('fornecedor_id', []);
      setValue('produto_id', []);
      setValue('quantidade_envio', '');
      setValue('preco_custo_costureira', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numeroCorte, setValue]);

  useEffect(() => {
    const total =
      formatCurrencyDataBase(quantidadeRecebimento) *
      formatCurrencyDataBase(precoCusto);
    const saldo =
      formatCurrencyDataBase(quantidadeEnvio) -
      formatCurrencyDataBase(quantidadeRecebimento);
    setValue('valor_total_recebimento', formatCurrencyBR(total, false));
    setValue('saldo', formatCurrencyBR(saldo, false));
  }, [precoCusto, quantidadeEnvio, quantidadeRecebimento, setValue]);

  const loadOptionsNumeroCorte = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getEnvioCorteByNumber(inputValue).then((response) => {
        if (response.numero_corte) {
          const values: IOptionReactSelect = {
            label: String(response.numero_corte) || '',
            value: String(response.numero_corte) || '',
          };

          setQuantidadeEnvioSelected(String(response.quantidade_envio));
          setPrecoCustoSelected(String(response.preco_custo_costureira));
          setFornecedorSelected({
            label: response.fornecedor.nome,
            value: response.fornecedor.id,
          });
          setProdutoSelected({
            label: response.produto.nome,
            value: response.produto.id,
          });

          return callback([values]);
        }

        return callback([]);
      });
    },
    500,
  );

  const loadOptionsFormas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getFormasPagamento({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  useEffect(() => {
    if (id) {
      getRecebimentoCorte(id).then((data) => {
        setCorte(data);

        reset(data);
      });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data: IRecebimentoCorteForm) => {
      try {
        setLoading(true);

        if (id) {
          if (data.quantidade_recebimento === '0,00') {
            setError('quantidade_recebimento', {
              message: 'Quantidade é obrigatório',
            });

            return;
          }

          if (data.preco_custo_costureira === '0,00') {
            setError('preco_custo_costureira', {
              message: 'Preço custo é obrigatório',
            });

            return;
          }

          if (data.valor_total_recebimento === '0,00') {
            setError('valor_total_recebimento', {
              message: 'Valor total maior que 0 obrigatório',
            });

            return;
          }

          const response = await updateRecebimentoCorte(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/recebimento-corte');
          }
        } else {
          if (data.quantidade_recebimento === '0,00') {
            setError('quantidade_recebimento', {
              message: 'Quantidade é obrigatório',
            });

            return;
          }

          if (data.preco_custo_costureira === '0,00') {
            setError('preco_custo_costureira', {
              message: 'Preço custo é obrigatório',
            });

            return;
          }

          if (data.valor_total_recebimento === '0,00') {
            setError('valor_total_recebimento', {
              message: 'Valor total maior que 0 obrigatório',
            });

            return;
          }

          await createRecebimentoCorte(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/recebimento-corte');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset, setError],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/recebimento-corte">
              <MdKeyboardBackspace size={24} color="#fff" />
              Recebimento Corte
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={
              id
                ? `Editando corte: ${corte?.numero_corte}`
                : 'Novo recebimento corte'
            }
            icon={ImScissors}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <C.FormGroup>
              <C.Label>Numero Corte</C.Label>

              {id ? (
                <C.ReactSelectAsync
                  {...register('numero_corte')}
                  control={control}
                  errors={errors.numero_corte}
                  loadOptions={loadOptionsNumeroCorte}
                  isDisabled
                />
              ) : (
                <C.ReactSelectAsync
                  {...register('numero_corte')}
                  control={control}
                  errors={errors.numero_corte}
                  loadOptions={loadOptionsNumeroCorte}
                  isClearable
                  isSearchable
                  loadingMessage="Buscando..."
                  noOptionsMessage="Nenhum corte localizado"
                />
              )}
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Costureira</C.Label>
              <C.ReactSelect
                {...register('fornecedor_id')}
                control={control}
                errors={errors.fornecedor_id}
                isDisabled
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Produto</C.Label>
              <C.ReactSelect
                {...register('produto_id')}
                control={control}
                errors={errors.produto_id}
                isDisabled
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Forma</C.Label>
              <C.ReactSelectAsync
                {...register('forma_pagamento_id')}
                control={control}
                errors={errors.forma_pagamento_id}
                loadOptions={loadOptionsFormas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma forma pagamento localizada"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Data Recebimento</C.Label>
              <C.InputDatePicker
                {...register('data_recebimento')}
                control={control}
                errors={errors.data_recebimento}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_recebimento')}
                name="data_recebimento"
                placeholder="Data Recebimento"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Data Vencimento</C.Label>
              <C.InputDatePicker
                {...register('data_vencimento')}
                control={control}
                errors={errors.data_vencimento}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_vencimento')}
                name="data_vencimento"
                placeholder="Data Recebimento"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Preço custo</C.Label>
              <C.InputCurrency
                {...register('preco_custo_costureira')}
                errors={errors.preco_custo_costureira}
                name="preco_custo_costureira"
                placeholder="Ex: 19,90"
                readOnly
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Quantidade Envio</C.Label>
              <C.InputCurrency
                {...register('quantidade_envio')}
                errors={errors.quantidade_envio}
                name="quantidade_envio"
                placeholder="Ex: 50"
                readOnly
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Quantidade Recebimento</C.Label>
              <C.InputCurrency
                {...register('quantidade_recebimento')}
                errors={errors.quantidade_recebimento}
                name="quantidade_recebimento"
                placeholder="Ex: 50"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Saldo</C.Label>
              <C.InputCurrency
                {...register('saldo')}
                errors={errors.saldo}
                name="saldo"
                placeholder="Quantidade Envio - Quantidade Recebimento "
                readOnly
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor total pagar</C.Label>
              <C.InputCurrency
                {...register('valor_total_recebimento')}
                errors={errors.valor_total_recebimento}
                name="valor_total_recebimento"
                placeholder="Quantidade Recebimento * Preço custo"
                readOnly
              />
            </C.FormGroup>
          </S.FormContainer>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
