import api from '../../api';

interface IProfileData {
  name: string;
  email: string;
  roles: string[];
  old_password?: string;
  password?: string;
  password_confirmation?: string;
}

export const updateProfile = async (formData: IProfileData) => {
  return api
    .put('/profile', formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const updateAvatar = async (data: FormData) => {
  return api
    .patch('/users/avatar', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
