import { IPermission, IRole } from 'pages/Permission/interfaces';
import { IPaginate, IParams } from 'shared/interfaces';

import { groupBy } from 'utils/groupBy';
import api from '../../api';

export const getRoles = async (params: IParams) => {
  return api
    .get<IPaginate<IRole>>('roles', { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getPermissions = async (params: IParams) => {
  return api
    .get<IPaginate<IPermission>>('permissions', { params })
    .then((response) => {
      const { data } = response.data;

      const perm: any = data; // group by aceita tipo any

      const newPermissions = groupBy(perm, 'menu_name');

      return newPermissions;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getRoleById = async (id: string) => {
  return api
    .get<IRole>(`roles/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const updateRole = async (id: string, data: IRole) => {
  const newData = { ...data, slug: data.name };

  return api
    .put<IRole>(`roles/${id}`, newData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const createRole = async (data: IRole) => {
  const newData = { ...data, slug: data.name };

  return api
    .post<IRole>('roles', newData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const removeRole = async (id: string) => {
  return api.delete(`/roles/${id}`).catch((error) => {
    throw new Error(error);
  });
};
