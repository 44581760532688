import React, { createContext, useCallback, useState, useContext } from 'react';
import { ISideBarContextData, ISiedeBarState } from './types';

const SiedeBarContext = createContext<ISideBarContextData>(
  {} as ISideBarContextData,
);

const SiedeBarProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ISiedeBarState>(() => {
    const isSideBarOpened = localStorage.getItem('@projetos:isSideBarOpened');
    if (isSideBarOpened && isSideBarOpened === 'true') {
      return { isOpened: true } as ISiedeBarState;
    }
    return { isOpened: false } as ISiedeBarState;
  });

  const onToogleOpenSideBar = useCallback(() => {
    const isOpened = !data.isOpened;
    localStorage.setItem('@projetos:isSideBarOpened', String(isOpened));
    setData({ isOpened });
  }, [data.isOpened]);

  return (
    <SiedeBarContext.Provider
      value={{ isOpened: data.isOpened, onToogleOpenSideBar }}
    >
      {children}
    </SiedeBarContext.Provider>
  );
};

function useSiedeBar(): ISideBarContextData {
  const context = useContext(SiedeBarContext);

  if (!context) {
    throw new Error('useSiedeBar must be used within an SiedeBarProvider');
  }

  return context;
}

export { SiedeBarProvider, useSiedeBar };
