import React from 'react';

import * as S from './styles';

interface StatusProps {
  enable: string;
}

export const Status: React.FC<StatusProps> = ({ enable }) => {
  return <S.Status enable={enable} size={24} />;
};
