import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { CoresList } from './List';
import { CoresForm } from './Form';

export const Cor: React.FC = () => {
  return (
    <Switch>
      <Route path="/produto/cores" component={CoresList} isPrivate exact />
      <Route path="/produto/cores/new" component={CoresForm} isPrivate />
      <Route path="/produto/cores/edit/:id" component={CoresForm} isPrivate />
    </Switch>
  );
};
