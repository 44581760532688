import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { FamiliasList } from './List';
import { FamiliasForm } from './Form';

export const Familia: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/produto/familias"
        component={FamiliasList}
        isPrivate
        exact
      />
      <Route path="/produto/familias/new" component={FamiliasForm} isPrivate />
      <Route
        path="/produto/familias/edit/:id"
        component={FamiliasForm}
        isPrivate
      />
    </Switch>
  );
};
