export const groupBy = (array: Array<any>, key: string): any => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    // eslint-disable-next-line no-param-reassign
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue,
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};
