import React, { useState, useEffect, useCallback } from 'react';

import { MdSearch } from 'react-icons/md';
import { IPaginate } from 'shared/interfaces';
import { useToast } from 'contexts';
import { FieldValues, useForm, SubmitHandler } from 'react-hook-form';

import { getConsolidado } from 'services/api/contasPagarRelatorios';
import { toFormatDateTZ } from 'utils/dateHelpers';

import * as C from 'components';
import * as S from './styles';
import { CustomPagination } from './Pagination';

import {
  IRelatorioConsolidado,
  IParamRelatorioConsolidado,
} from '../interfaces';

import { comSaldoOptions } from './options';

export const Consolidado: React.FC = () => {
  const { addToast } = useToast();
  const [titulos, setTitulos] = useState<IPaginate<IRelatorioConsolidado>>();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<FieldValues>({
    defaultValues: {
      data_inicial: '',
      data_final: '',
      com_saldo: { value: 'S', label: 'SIM' },
    },
  });

  const dataInicio = watch('data_inicial');
  const dataFim = watch('data_final');
  const fornecedor = watch('fornecedor');
  const comSaldo = watch('com_saldo');

  const handleSearch = useCallback(
    ({ page, per_page, data_inicial, data_final, com_saldo, term }) => {
      setLoading(true);

      const params = {
        page,
        per_page,
        term,
        data_inicial: data_inicial ? toFormatDateTZ(data_inicial) : '',
        data_final: data_inicial ? toFormatDateTZ(data_final) : '',
        com_saldo,
      };

      getConsolidado(params)
        .then((data) => {
          setTitulos(data);

          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'Ops, Erro',
            description: e.response?.data?.message,
          });

          setLoading(false);
        });
    },
    [addToast],
  );

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    async (data: FieldValues) => {
      const sub = async (formData: IParamRelatorioConsolidado) => {
        try {
          handleSearch({
            page: titulos?.page || 1,
            per_page: titulos?.per_page || 20,
            data_inicial: formData.data_inicial,
            data_final: formData.data_final,
            com_saldo: formData.com_saldo?.value || 'S',
            term: formData.fornecedor || '',
          });
        } catch (err) {
          addToast({
            type: 'error',
            title: 'Ops, Erro',
            description: 'Ocorreu um erro ao buscar os dados',
          });
        }
      };
      await sub(data as IParamRelatorioConsolidado);
    },
    [addToast, handleSearch, titulos?.page, titulos?.per_page],
  );

  useEffect(() => {
    const params = {
      page: titulos?.page || 1,
      per_page: titulos?.per_page || 20,
      data_inicial: '',
      data_final: '',
      com_saldo: 'S',
      term: '',
    };

    handleSearch(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSearch]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.Header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <S.FormGroup style={{ paddingRight: '8px' }}>
              <C.Label>Fornecedor</C.Label>
              <C.Input
                type="text"
                {...register('fornecedor')}
                errors={errors.fornecedor}
                name="fornecedor"
                placeholder="fornecedor"
              />
            </S.FormGroup>
            <S.FormGroup>
              <C.Label>Data Inicio</C.Label>
              <C.InputDatePicker
                {...register('data_inicial')}
                control={control}
                errors={errors?.data_inicial?.message?.toString()}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_inicial')}
                name="data_inicial"
                placeholder="Data Inicio"
              />
            </S.FormGroup>

            <S.FormGroup>
              <C.Label>Data Fim</C.Label>
              <C.InputDatePicker
                {...register('data_final')}
                control={control}
                errors={errors?.data_final?.message?.toString()}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_final')}
                name="data_final"
                placeholder="Data Fim"
              />
            </S.FormGroup>

            <S.FormGroup style={{ width: '120px' }}>
              <C.Label>Saldo</C.Label>
              <C.ReactSelect
                {...register('com_saldo')}
                name="com_saldo"
                control={control}
                errors={errors.tipo_fornecedor}
                options={comSaldoOptions}
                isClearable={false}
                noOptionsMessage="Nenhuma tipo localizado"
              />
            </S.FormGroup>

            <C.FormGroup>
              <C.Button
                variant="success"
                disabled={loading}
                loading={loading}
                type="submit"
                title="Salvar"
              >
                <MdSearch size={24} />
              </C.Button>
            </C.FormGroup>
          </form>
        </S.Header>
      </C.HeaderBackground>

      <S.Panel>
        {loading ? (
          <C.Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <div className="tbl-content">
              <C.Table>
                <thead>
                  <tr>
                    <th className="text-left">Fornecedor</th>
                    <th className="text-center">Valor Compra</th>
                    <th className="text-center">Valor Nota</th>
                    <th className="text-center">Valor Romaneio</th>
                    <th className="text-center">Valor Juros</th>
                    <th className="text-center">Valor Acréscimo</th>
                    <th className="text-center">Valor Desconto</th>
                    <th className="text-center">Valor Pago</th>
                    <th className="text-center">Valor Saldo</th>
                  </tr>
                </thead>
                <tbody>
                  {titulos &&
                    titulos.data.map((item: IRelatorioConsolidado) => (
                      <tr key={item.id}>
                        <td>{item.nome}</td>
                        <td className="text-right">{item.valor_compra}</td>
                        <td className="text-right">{item.valor_nota_fiscal}</td>
                        <td className="text-right">{item.valor_romaneio}</td>
                        <td className="text-right">{item.valor_juros}</td>
                        <td className="text-right">{item.valor_acrescimo}</td>
                        <td className="text-right">{item.valor_desconto}</td>
                        <td className="text-right">{item.valor_pago}</td>
                        <S.Saldo isDebit={item.valor_saldo?.includes('-R$')}>
                          {item.valor_saldo}
                        </S.Saldo>
                      </tr>
                    ))}
                </tbody>
              </C.Table>
            </div>
          </>
        )}
        <S.PaginationContainer>
          {titulos && (
            <CustomPagination
              page={titulos?.page}
              total={titulos?.total}
              per_page={titulos?.per_page}
              last_page={titulos?.last_page}
              onSearch={handleSearch}
              data_inicial={dataInicio}
              data_final={dataFim}
              com_saldo={comSaldo.value}
              fornecedor={fornecedor}
            />
          )}
        </S.PaginationContainer>
      </S.Panel>
    </S.Container>
  );
};
