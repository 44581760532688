import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { useHistory, Link } from 'react-router-dom';
import { GoNote } from 'react-icons/go';
import { MdSearch } from 'react-icons/md';
import { IPaginate } from 'shared/interfaces';
import { useToast } from 'contexts';
import { ModalHandles } from 'components/Modal';

import { getVendas, deleteVenda } from 'services/api/vendas';

import * as C from 'components';
import * as S from './styles';

import { IVendas } from '../interfaces';

export const VendasList: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [vendas, setVendas] = useState<IPaginate<IVendas>>();
  const [vendaAtual, setVendaAtual] = useState<IVendas>();
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const modalRef = useRef<ModalHandles>(null);
  const [modalType, setModalType] = useState('remove');

  const handleSearch = useCallback(
    ({ page, per_page }) => {
      setLoading(true);

      const params = {
        origem: 'S',
        page,
        per_page,
        term,
      };

      getVendas(params)
        .then((data) => {
          setVendas(data);

          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'Ops, Erro',
            description: e.response?.data?.message,
          });

          setLoading(false);
        });
    },
    [addToast, term],
  );

  useEffect(() => {
    const params = {
      origem: 'S',
      page: 1,
      per_page: 20,
    };

    handleSearch(params);
  }, [handleSearch]);

  const handleRemove = useCallback(async () => {
    try {
      await deleteVenda(vendaAtual!.id);

      if (!vendas) return;

      const newList = vendas.data.filter((item) => item.id !== vendaAtual?.id);
      setVendas({ ...vendas, data: newList });

      modalRef.current?.closeModal();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Registro removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao remover o registro',
      });
    }
  }, [addToast, vendaAtual, vendas]);

  const handleOpenModal = useCallback(
    (vendaSelected: IVendas, type: string): void => {
      setModalType(type);

      setVendaAtual(vendaSelected);

      modalRef.current?.openModal();
    },
    [],
  );

  const renderModal = useMemo(() => {
    if (modalType === 'remove') {
      return (
        <C.Modal ref={modalRef} options={{ width: '400px' }}>
          <C.ModalHeader>
            <C.ModalTitle>Você Confirma a EXCLUSÃO?</C.ModalTitle>
          </C.ModalHeader>
          <C.ModalBody>
            <p>O registro será removido completamente!</p>
          </C.ModalBody>
          <C.ModalFooter>
            <C.Position align="right">
              <C.Button
                variant="outline-secondary"
                onClick={() => modalRef.current?.closeModal()}
              >
                Cancelar
              </C.Button>
              <C.Button
                variant="primary"
                onClick={() => handleRemove()}
                style={{ marginLeft: '16px' }}
              >
                SIM, confirmo a exclusão
              </C.Button>
            </C.Position>
          </C.ModalFooter>
        </C.Modal>
      );
    }

    return <></>;
  }, [handleRemove, modalType]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <C.HeaderPage>
          <C.HeaderInfo title="Vendas" icon={GoNote} />

          <S.ContainerSearch>
            <C.InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) => {
                e.key === 'Enter'
                  ? handleSearch({
                      page: 1,
                      per_page: vendas?.per_page,
                    })
                  : {};
              }}
            />
          </S.ContainerSearch>

          <S.HeaderButtonContainer>
            <Link to="/vendas/print">
              <S.IconPrint />
            </Link>

            <C.Can checkPermission="vendas_create">
              <C.ButtonLink title="Adicionar Venda" to="/vendas/new">
                Adicionar
              </C.ButtonLink>
            </C.Can>
          </S.HeaderButtonContainer>
        </C.HeaderPage>
      </C.HeaderBackground>

      <S.Panel>
        {loading ? (
          <C.Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <div className="tbl-content">
              <C.Table>
                <thead>
                  <tr>
                    <th className="text-center">Numero</th>
                    <th className="text-center">Data Emissão</th>
                    <th className="text-left">Cliente</th>
                    <th className="text-left">Forma</th>
                    <th className="text-center">Valor Total</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {vendas &&
                    vendas.data.map((item: IVendas) => (
                      <tr key={item.id}>
                        <td className="text-center">{item.numero_venda}</td>
                        <td className="text-center">{item.data_emissao}</td>
                        <td>{item.cliente.nome}</td>
                        <td>{item.forma_pagamento.nome}</td>
                        <td className="text-right">{item.valor_total}</td>
                        <td className="text-center">
                          <C.PopUpMenu>
                            <C.Can checkPermission="vendas_update">
                              <button
                                type="button"
                                onClick={() =>
                                  history.push(`/vendas/edit/${item.id}`)
                                }
                              >
                                <S.IconEdit
                                  title="Editar"
                                  size={24}
                                  color="#707070"
                                />
                                Editar
                              </button>
                            </C.Can>

                            <C.Can checkPermission="vendas_delete">
                              <button
                                type="button"
                                onClick={() => handleOpenModal(item, 'remove')}
                              >
                                <S.IconDelete
                                  title="Remover"
                                  size={24}
                                  color="#707070"
                                />
                                Remover
                              </button>
                            </C.Can>

                            <S.ButtonPrint
                              to={{
                                pathname: `${process.env.REACT_APP_API_URL}/vendas/print/${item.id}`,
                              }}
                              target="_blank"
                            >
                              <S.IconPrintVenda
                                title="Imprimir Venda"
                                size={24}
                                color="#707070"
                              />
                              Imprimir
                            </S.ButtonPrint>
                          </C.PopUpMenu>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </C.Table>
            </div>
          </>
        )}
        <S.PaginationContainer>
          {vendas && (
            <C.Pagination
              page={vendas?.page}
              total={vendas?.total}
              per_page={vendas?.per_page}
              last_page={vendas?.last_page}
              onSearch={handleSearch}
            />
          )}
        </S.PaginationContainer>
      </S.Panel>

      {renderModal}
    </S.Container>
  );
};
