import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { CentroCustoList } from './List';
import { CentroCustoForm } from './Form';

export const CentroCusto: React.FC = () => {
  return (
    <Switch>
      <Route path="/centro-custo" component={CentroCustoList} isPrivate exact />
      <Route path="/centro-custo/new" component={CentroCustoForm} isPrivate />
      <Route
        path="/centro-custo/edit/:id"
        component={CentroCustoForm}
        isPrivate
      />
    </Switch>
  );
};
