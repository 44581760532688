import { IPaginate, IParams } from 'shared/interfaces';
import { IProdutoCor, IProdutoCorForm } from 'pages/ProdutoCor/interfaces';

import api from '../../api';

const getCor = async (id: string): Promise<IProdutoCor> => {
  const response = await api.get<IProdutoCor>(`/produto/cores/${id}`);

  return response.data;
};

const getCores = async (params: IParams): Promise<IPaginate<IProdutoCor>> => {
  const response = await api.get<IPaginate<IProdutoCor>>('/produto/cores', {
    params,
  });

  return response.data;
};

const createCor = async (familia: IProdutoCorForm): Promise<void> => {
  await api.post('/produto/cores', familia);
};

const updateCor = async (
  id: string,
  conta: IProdutoCorForm,
): Promise<IProdutoCor> => {
  const response = await api.put<IProdutoCor>(`/produto/cores/${id}`, conta);

  return response.data;
};

const deleteCor = async (id: string): Promise<void> => {
  await api.delete(`/produto/cores/${id}`);
};

export { getCor, getCores, createCor, updateCor, deleteCor };
