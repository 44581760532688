import styled from 'styled-components';

export const Container = styled.ul``;

export const Item = styled.li`
  > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px 8px 0px;
    text-decoration: none;
  }

  transition: background-color 0.2s;

  & a.sideBarLinkActive {
    text-decoration: none;
    background-color: var(--backgrount-item-menu-hover);
    border-right: 3px solid var(--link-border-active-sidebar);
  }

  & a:hover {
    text-decoration: none;
    background-color: var(--backgrount-item-menu-hover);
    border-right: 3px solid var(--link-border-active-sidebar);
  }

  & > a:hover {
    > svg,
    > span {
      color: var(--text-item-menu-hover) !important;
      font-weight: 700;
    }
  }
`;

export const LabelMenu = styled.span`
  margin-left: 64px;
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--text-item-menu);
`;
