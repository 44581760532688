import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';

import { reactSelectMapper } from 'utils/mappers';
import { IOptionReactSelect } from 'shared/interfaces';

import { useToast } from '../../../contexts';

import { IUser, IUserForm, IRouterParams } from '../interfaces';

import { getUser, createUser, updateUser } from '../../../services/api/user';
import { getRoles } from '../../../services/api/permission';

import * as S from './styles';
import * as C from '../../../components';

const UserForm: React.FC = () => {
  const { id } = useParams<IRouterParams>();
  const history = useHistory();

  let schema;

  if (id) {
    schema = Yup.object().shape({
      name: Yup.string().required('Relatório obrigatório'),
      role_id: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .required('Permissão é obrigatória')
        .typeError('Permissão obrigatoria'),
      email: Yup.string()
        .required('Email obrigatório')
        .email('Digite um e-mail válido'),
    });
  } else {
    schema = Yup.object().shape({
      name: Yup.string().required('Relatório obrigatório'),
      role_id: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .typeError('Permissão obrigatoria')
        .required('Permissão é obrigatória'),

      email: Yup.string()
        .required('Email obrigatório')
        .email('Digite um e-mail válido'),
      password: Yup.string().required('Senha obrigatória'),
      confirm_password: Yup.string()
        .required('Confirmação de senha obrigatoria')
        .oneOf([Yup.ref('password'), 'Confirmação invalida']),
    });
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setError,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<IUser>();
  const [optionsRole, setOptionsRole] = useState<IOptionReactSelect[]>([]);

  useEffect(() => {
    getRoles({
      page: 1,
      per_page: 50,
    }).then((response) => {
      const values: IOptionReactSelect[] = reactSelectMapper(response.data);

      setOptionsRole(values);
    });
  }, []);

  const onSubmit = useCallback(
    async (data: IUserForm) => {
      try {
        setLoading(true);

        if (id) {
          await updateUser(id, data);

          history.push('/users');
        } else {
          if (!data.role_id.value) {
            setError('role_id', { message: 'Permissão obrigatoria' });

            return;
          }

          await createUser(data);

          reset();

          history.push('/users');
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, id, reset, setError],
  );

  useEffect(() => {
    if (id) {
      getUser(id).then((data) => {
        reset(data);
        setUser(data);
      });
    }
  }, [id, reset]);

  const renderPassword = useCallback(() => {
    return (
      <>
        <C.FormGroup>
          <C.Label>Senha</C.Label>
          <C.Input
            type="password"
            {...register('password')}
            errors={errors.password}
            name="password"
            placeholder="Senha"
            autoComplete="off"
          />
        </C.FormGroup>

        <C.FormGroup>
          <C.Label>Confirmação Senha</C.Label>
          <C.Input
            type="password"
            {...register('confirm_password')}
            errors={errors.confirm_password}
            name="confirm_password"
            placeholder="Confirmação da senha"
            autoComplete="off"
          />
        </C.FormGroup>
      </>
    );
  }, [errors.confirm_password, errors.password, register]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/users">
              <MdKeyboardBackspace size={24} color="#fff" />
              Usuários
            </Link>
          </S.BackLink>
          <C.HeaderInfo
            title={id ? `Editando: ${user?.name}` : 'Novo Usuário'}
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>
      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Usuário</C.Label>
            <C.Input
              type="text"
              {...register('name')}
              errors={errors.name}
              name="name"
              placeholder="Usuário"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Email</C.Label>
            <C.Input
              type="text"
              {...register('email')}
              errors={errors.email}
              name="email"
              placeholder="Email"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Permissão</C.Label>
            <C.ReactSelect
              {...register('role_id')}
              control={control}
              errors={errors.role_id}
              options={optionsRole}
              isClearable
              isSearchable
              noOptionsMessage="Nenhuma permissão localizada"
            />
          </C.FormGroup>

          {id ? <></> : renderPassword()}

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default UserForm;
