import React, { InputHTMLAttributes } from 'react';
import Select from 'react-select';
import { Controller, Control, FieldError } from 'react-hook-form';

import { IOptionReactSelect } from 'shared/interfaces';
import * as S from './styles';

interface ReactSelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  name: string;
  control: Control;
  errors: FieldError | undefined;
  options?: IOptionReactSelect[];
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  noOptionsMessage?: string;
}

const ReactSelectBase: React.ForwardRefRenderFunction<
  HTMLSelectElement,
  ReactSelectProps
> = (
  {
    name,
    control,
    errors,
    options = [],
    isClearable = true,
    isDisabled = false,
    isLoading = false,
    isRtl = false,
    isSearchable = true,
    isMulti = false,
    noOptionsMessage = 'Nenhum registro localizado',
    ...rest
  },
  ref,
) => {
  return (
    <>
      <S.Container>
        <Controller
          name={name}
          control={control}
          {...rest}
          render={({ field }) => (
            <Select
              {...field}
              isClearable={isClearable}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isRtl={isRtl}
              isSearchable={isSearchable}
              isMulti={isMulti}
              noOptionsMessage={() => noOptionsMessage}
              selectRef={ref}
              options={options}
              onChange={(selectedOption) => {
                field.onChange(selectedOption);
              }}
              styles={{
                control: (styles) => ({
                  ...styles,
                  backgroundColor: 'var(--background-input)',
                  color: '#999',
                  padding: '0.6rem',
                  border: '1px solid var(--border-input)',
                  borderRadius: 4,
                  fontSize: '1.6rem',
                }),
                option: (base) => ({
                  ...base,
                  height: '100%',
                  fontSize: '1.6rem',
                  // backgroundColor: 'var(--background-input)',
                  // color: '#999',
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 4,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--primary-light)',
                  primary: 'var(--primary)',
                },
              })}
            />
          )}
        />
      </S.Container>
      {errors && <S.ErrorMsg>{errors.message || ''}</S.ErrorMsg>}
    </>
  );
};

export const ReactSelect = React.forwardRef(ReactSelectBase);
