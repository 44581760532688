import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ContasPagarList } from './List';
import { ContasPagarForm } from './Form';
import { ContasPagarPrint } from './Print';

export const ContasPagar: React.FC = () => {
  return (
    <Switch>
      <Route path="/contas-pagar" component={ContasPagarList} isPrivate exact />
      <Route path="/contas-pagar/new" component={ContasPagarForm} isPrivate />
      <Route
        path="/contas-pagar/print"
        component={ContasPagarPrint}
        isPrivate
      />
      <Route
        path="/contas-pagar/edit/:id"
        component={ContasPagarForm}
        isPrivate
      />
    </Switch>
  );
};
