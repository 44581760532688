import styled from 'styled-components';

export const Container = styled.div``;

export const ErrorMsg = styled.p`
  margin-top: 8px;
  margin-bottom: 8px;
  color: #c53030;
  font-weight: bold;
`;
