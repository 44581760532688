import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';

import * as S from './styles';

export interface ModalHandles {
  openModal: () => void;
  closeModal: () => void;
}

interface ModalOptionsProps {
  width?: string;
  zIndex?: number;
  // height?: string;
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  options?: ModalOptionsProps;
}

const ModalRef: React.ForwardRefRenderFunction<ModalHandles, Props> = (
  { options, children },
  ref,
) => {
  const [visible, setVisible] = useState(false);

  const openModal = useCallback((): void => {
    setVisible(true);
  }, []);

  const closeModal = useCallback((): void => {
    setVisible(false);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      openModal,
      closeModal,
    };
  });

  if (!visible) {
    return null;
  }

  return (
    <S.Container visible={visible} options={options}>
      <S.IconClose size={24} onClick={closeModal} />
      {children}
    </S.Container>
  );
};

export const Modal = forwardRef(ModalRef);
