import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { GoNote } from 'react-icons/go';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import { createVenda, getVenda, updateVenda } from 'services/api/vendas';

import { getClientes } from 'services/api/cliente';
import { getFormasPagamento } from 'services/api/formaPagamento';
import { getProdutos } from 'services/api/produto';

import { useToast } from 'contexts';
import { formatCurrencyDataBase, formatCurrencyBR } from 'utils/helpers';

import * as C from 'components';

import * as S from './styles';

import { IVendas, IVendasForm, IItensVenda, IItensVenda2 } from '../interfaces';

const schema = Yup.object().shape({
  cliente_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Cliente obrigatorio')
    .typeError('Cliente obrigatorio'),
  forma_pagamento_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Forma pagamento obrigatorio')
    .typeError('Forma pagamento obrigatorio'),
  data_emissao: Yup.string()
    .typeError('Data emissão obrigatório')
    .required('Data emissão obrigatório'),
  data_vencimento: Yup.string()
    .typeError('Data vencimento obrigatório')
    .required('Data vencimento obrigatório'),
  itens_venda: Yup.array()
    .of(
      Yup.object()
        .shape({
          produto_id: Yup.object()
            .shape({
              label: Yup.string().required('Produto é obrigatório'),
              value: Yup.string().required('Produto é obrigatório'),
              preco: Yup.string(),
            })
            .typeError('Produto é obrigatório')
            .required('Produto é obrigatório'),
          quantidade: Yup.string()
            .typeError('Quantidade é obrigatório')
            .required('Quantidade é obrigatório'),
          // preco: Yup.string()
          //   .typeError('Preço obrigatório')
          //   .required('Preço obrigatório'),
          // valor_total: Yup.string()
          //   .typeError('Total obrigatório')
          //   .required('Rotal obrigatório'),
        })
        .required('Itens obrigatório')
        .typeError('Itens obrigatório'),
    )
    .required('Itens obrigatório'),
});

export const VendasForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
    setError,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      data_emissao: new Date(),
      data_vencimento: new Date(),
      itens_venda: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'itens_venda',
  });

  const [loading, setLoading] = useState(false);
  const [venda, setVenda] = useState<IVendas>();

  const itensVenda = watch('itens_venda');

  const produtoAtual = watch('produto_atual');
  const quantidadeAtual = watch('quantidade_atual');
  const precoAtual = watch('preco_atual');
  const valorTotalAtual = watch('valor_total_atual');

  useEffect(() => {
    if (produtoAtual?.preco) {
      const total =
        formatCurrencyDataBase(quantidadeAtual) *
        formatCurrencyDataBase(produtoAtual.preco);

      setValue('preco_atual', produtoAtual?.preco);
      setValue('valor_total_atual', formatCurrencyBR(total, false));
      setValue('estoque_atual', produtoAtual?.estoque);
    } else {
      setValue('preco_atual', '0,00');
      setValue('valor_total_atual', '0,00');
      setValue('estoque_atual', '0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produtoAtual, setValue]);

  useEffect(() => {
    const total =
      formatCurrencyDataBase(quantidadeAtual) *
      formatCurrencyDataBase(precoAtual);

    setValue('valor_total_atual', formatCurrencyBR(total, false));
  }, [precoAtual, quantidadeAtual, setValue]);

  const loadOptionsProdutos = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getProdutos({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
            preco: formatCurrencyBR(Number(item.preco), false),
            estoque: item.quantidade,
            preco_custo: item.preco_custo,
            preco_custo_costureira: item.preco_custo_costureira,
            valor_lucro: item.valor_lucro,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsClientes = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getClientes({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsFormas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getFormasPagamento({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  useEffect(() => {
    if (id) {
      getVenda(id).then((data) => {
        setVenda(data);

        reset(data);
      });
    }
  }, [id, reset]);

  useEffect(() => {
    if (fields.length !== itensVenda.length) {
      // Devido bug na tela de update ao deletar ultimo item
      setValue('itens_venda', fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, setValue]);

  const onSubmit = useCallback(
    async (data: IVendasForm) => {
      if (data.itens_venda.length === 0) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Itens são obrigatório',
        });

        return;
      }

      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Vendas em teste não pode ser alterada',
      });

      // try {
      //   setLoading(true);

      //   if (id) {
      //     if (!data.cliente_id?.value) {
      //       setError('cliente_id', { message: 'Cliente obrigatorio' });

      //       return;
      //     }

      //     if (!data.forma_pagamento_id?.value) {
      //       setError('forma_pagamento_id', {
      //         message: 'Forma pagamento obrigatorio',
      //       });

      //       return;
      //     }

      //     const response = await updateVenda(id, data);

      //     if (response) {
      //       addToast({
      //         type: 'success',
      //         title: 'Atualizado',
      //         description: 'Atualizado com sucesso',
      //       });

      //       history.push('/mobile/vendas');
      //     }
      //   } else {
      //     if (!data.cliente_id?.value) {
      //       setError('cliente_id', { message: 'Cliente obrigatorio' });

      //       return;
      //     }

      //     if (!data.forma_pagamento_id?.value) {
      //       setError('forma_pagamento_id', {
      //         message: 'Forma pagamento obrigatorio',
      //       });

      //       return;
      //     }

      //     await createVenda(data);

      //     addToast({
      //       type: 'success',
      //       title: 'Sucesso',
      //       description: 'Salvo com sucesso',
      //     });

      //     reset();

      //     history.push('/mobile/vendas');
      //   }
      // } catch (err) {
      //   addToast({
      //     type: 'error',
      //     title: 'Ops, Erro',
      //     description: 'Ocorreu um erro ao inserir um novo registro',
      //   });
      // } finally {
      //   setLoading(false);
      // }
    },

    [addToast, history, id, reset, setError],
  );

  const handleAdd = useCallback(() => {
    if (produtoAtual && produtoAtual.preco && quantidadeAtual) {
      const quantidade = formatCurrencyDataBase(quantidadeAtual);
      const preco = formatCurrencyDataBase(precoAtual);

      const itens: IItensVenda2[] = itensVenda;

      const qtdItem = itens
        .filter((i) => i.produto_id.value === produtoAtual.value)
        .reduce(
          (acc, item) => {
            acc.qtd += formatCurrencyDataBase(item.quantidade);

            return acc;
          },
          { qtd: 0 },
        );

      if (
        formatCurrencyDataBase(produtoAtual.estoque) -
          qtdItem.qtd -
          formatCurrencyDataBase(quantidadeAtual) >=
        0
      ) {
        if (
          Math.round(
            (formatCurrencyDataBase(produtoAtual.preco_custo) +
              formatCurrencyDataBase(produtoAtual.preco_custo_costureira) +
              formatCurrencyDataBase(produtoAtual.valor_lucro)) *
              1e2,
          ) /
            1e2 >
          Math.round(formatCurrencyDataBase(precoAtual) * 1e2) / 1e2
        ) {
          addToast({
            type: 'error',
            title: 'Ops, Erro',
            description: `Produto com preço menor que R$ ${(
              formatCurrencyDataBase(produtoAtual.preco_custo) +
              formatCurrencyDataBase(produtoAtual.preco_custo_costureira) +
              formatCurrencyDataBase(produtoAtual.valor_lucro)
            ).toFixed(2)}`,
          });
        } else {
          append({
            produto_id: produtoAtual,
            quantidade: formatCurrencyBR(quantidade, false),
            preco: formatCurrencyBR(preco, false),
            valor_total: valorTotalAtual,
          });

          setValue('produto_atual', '');
          setValue('quantidade_atual', '');
        }
      } else {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Produto sem estoque suficiente',
        });
      }
    }
  }, [
    addToast,
    append,
    itensVenda,
    precoAtual,
    produtoAtual,
    quantidadeAtual,
    setValue,
    valorTotalAtual,
  ]);

  const handleRemove = useCallback(
    (index) => {
      remove(index);
    },
    [remove],
  );

  const totalItens = useMemo(() => {
    const itens: IItensVenda[] = itensVenda;

    if (itens.length) {
      const { total } = itens.reduce(
        (accumulator, produto) => {
          accumulator.total +=
            formatCurrencyDataBase(produto.quantidade) *
            formatCurrencyDataBase(produto.preco);

          return accumulator;
        },
        { total: 0 },
      );

      return formatCurrencyBR(total);
    }

    return 'R$ 0,00';
  }, [itensVenda]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/mobile/vendas">
              <MdKeyboardBackspace size={24} color="#fff" />
              Vendas Mobile
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={
              id
                ? `Editando venda cliente: ${venda?.cliente.nome}`
                : 'Nova venda'
            }
            icon={GoNote}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormHeaderContainer>
            <C.FormGroup>
              <C.Label>Data Emissão</C.Label>
              <C.InputDatePicker
                {...register('data_emissao')}
                control={control}
                errors={errors.data_emissao}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_emissao')}
                name="data_emissao"
                placeholder="Data Emissão"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Data Vencimento</C.Label>
              <C.InputDatePicker
                {...register('data_vencimento')}
                control={control}
                errors={errors.data_vencimento}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_vencimento')}
                name="data_vencimento"
                placeholder="Data Vencimento"
              />
            </C.FormGroup>
            <C.FormGroup>
              <C.Label>Cliente</C.Label>
              <C.ReactSelectAsync
                {...register('cliente_id')}
                control={control}
                errors={errors.cliente_id}
                loadOptions={loadOptionsClientes}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum cliente localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Forma</C.Label>
              <C.ReactSelectAsync
                {...register('forma_pagamento_id')}
                control={control}
                errors={errors.forma_pagamento_id}
                loadOptions={loadOptionsFormas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma forma pagamento localizada"
              />
            </C.FormGroup>
          </S.FormHeaderContainer>

          <S.Table>
            <thead>
              <tr>
                <S.TableGrid>
                  <th className="text-left" style={{ width: '30%' }}>
                    Produto
                  </th>
                  <th className="text-left" style={{ width: '15%' }}>
                    Quantidade
                  </th>
                  <th className="text-left" style={{ width: '15%' }}>
                    Estoque
                  </th>
                  <th className="text-left" style={{ width: '15%' }}>
                    Preço
                  </th>
                  <th className="text-left" style={{ width: '15%' }}>
                    Total
                  </th>
                  <th className="text-center" style={{ width: '10%' }}>
                    Ação
                  </th>
                </S.TableGrid>
              </tr>
            </thead>
            <tbody>
              <tr>
                <S.TableGrid>
                  <td width="30%">
                    <C.ReactSelectAsync
                      {...register('produto_atual')}
                      control={control}
                      errors={errors.produto_atual}
                      loadOptions={loadOptionsProdutos}
                      isClearable
                      isSearchable
                      loadingMessage="Buscando..."
                      noOptionsMessage="Nenhum produto localizado"
                    />
                  </td>

                  <td className="text-center" width="15%">
                    <C.InputCurrency
                      {...register('quantidade_atual')}
                      errors={errors.quantidade_atual}
                      name="quantidade_atual"
                      placeholder="Ex: 50,00"
                    />
                  </td>

                  <td className="text-center" width="15%">
                    <C.InputCurrency
                      {...register('estoque_atual')}
                      errors={errors.estoque_atual}
                      name="estoque_atual"
                      readOnly
                    />
                  </td>

                  <td className="text-left" width="15%">
                    <C.InputCurrency
                      {...register('preco_atual')}
                      errors={errors.preco_atual}
                      name="preco_atual"
                      placeholder="Ex: 29,90"
                    />
                  </td>

                  <td className="text-center" width="15%">
                    <C.InputCurrency
                      {...register('valor_total_atual')}
                      errors={errors.valor_total_atual}
                      name="valor_total_atual"
                      placeholder="Quantidade * Preço"
                      readOnly
                    />
                  </td>
                  <td className="text-center" width="10%">
                    <S.IconAdd
                      title="Adicionar"
                      size={24}
                      color="#707070"
                      onClick={() => handleAdd()}
                    />
                  </td>
                </S.TableGrid>
              </tr>
            </tbody>
          </S.Table>

          <S.FormHeader>
            <div>Total ({itensVenda.length})</div>
            <span>{totalItens}</span>
          </S.FormHeader>

          <S.Table>
            <tbody>
              {fields.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td width="40%" className="text-center">
                      <C.ReactSelectAsync
                        {...register(`itens_venda[${index}].produto_id`)}
                        control={control}
                        errors={
                          errors.itens_venda &&
                          errors.itens_venda[index]?.produto_id
                        }
                        defaultValue={itensVenda[index]?.produto_id}
                        isDisabled
                      />
                    </td>

                    <td className="text-center">
                      <C.InputCurrency
                        {...register(`itens_venda[${index}].quantidade`)}
                        errors={
                          errors.itens_venda &&
                          errors.itens_venda[index]?.quantidade
                        }
                        name={`itens_venda[${index}].quantidade`}
                        defaultValue={itensVenda[index]?.quantidade}
                        placeholder="Ex: 50,00"
                        readOnly
                      />
                    </td>

                    <td className="text-left">
                      <C.InputCurrency
                        {...register(`itens_venda[${index}].preco`)}
                        errors={
                          errors.itens_venda && errors.itens_venda[index]?.preco
                        }
                        name={`itens_venda[${index}].preco`}
                        defaultValue={itensVenda[index]?.preco}
                        placeholder="Ex: 29,90"
                        readOnly
                      />
                    </td>

                    <td className="text-center">
                      <C.InputCurrency
                        {...register(`itens_venda[${index}].valor_total`)}
                        errors={
                          errors.itens_venda &&
                          errors.itens_venda[index]?.valor_total
                        }
                        name={`itens_venda[${index}].valor_total`}
                        placeholder="Quantidade * Preço"
                        defaultValue={itensVenda[index]?.valor_total}
                        readOnly
                      />
                    </td>
                    <td>
                      <S.IconDelete
                        title="Remover"
                        size={24}
                        color="#707070"
                        onClick={() => handleRemove(index)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </S.Table>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
