import styled from 'styled-components';

export const Container = styled.div``;

export const Panel = styled.div`
  background: var(--background-panel);

  top: -50px;
  border-radius: 8px;
  position: relative;
  margin: 0px auto;
  height: fit-content;
  width: 100%;
  max-width: 1050px;
  padding: 24px;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
  & > form {
    display: flex;
    flex-direction: column;
  }
`;

export const PermissionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

export const HeaderPageDatail = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  & div + div {
    margin: 8px 32px 0px 32px;
    justify-content: flex-start;
  }
`;
export const BackLink = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 32px 0px 32px;

  svg {
    margin-right: 8px;
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--background-header-text);
  }
`;

export const TitlePermission = styled.div`
  margin-top: 12px;

  h3 {
    font-weight: bold;
    margin-bottom: 8px;
    text-transform: capitalize;
  }

  label {
    font-weight: normal;
  }
`;
