import { useAuth } from 'contexts';

interface ICheckAuthProps {
  permissions: string[];
  checkRole: string;
  checkPermission: string;
}

interface ICanProps {
  checkPermission: string;
  children: JSX.Element;
}

const checkAuth = ({
  permissions,
  checkRole,
  checkPermission,
}: ICheckAuthProps): boolean => {
  if (checkRole === 'admin') return true;

  if (!permissions.includes(checkPermission)) return false;

  return true;
};

const Can = ({ children, checkPermission }: ICanProps) => {
  const { permissions, user } = useAuth();

  const canPermission = checkAuth({
    permissions,
    checkRole: user.role,
    checkPermission,
  });

  if (canPermission) {
    return <>{children}</>;
  }

  return <></>;

  // return typeof children === 'function'
  //   ? checkAuth({ permissions, checkRole: user.role, checkPermission })
  //   : checkAuth({ permissions, checkRole: user.role, checkPermission }) &&
  //       children;
};

export { Can };
