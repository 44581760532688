import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ContasList } from './List';
import { ContaForm } from './Form';

export const Conta: React.FC = () => {
  return (
    <Switch>
      <Route path="/contas" component={ContasList} isPrivate exact />
      <Route path="/contas/new" component={ContaForm} isPrivate />
      <Route path="/contas/edit/:id" component={ContaForm} isPrivate />
    </Switch>
  );
};
