import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 32px 32px 8px 32px;

  @media (max-width: 768px) {
    padding-top: 4px;
    flex-direction: column;
  }

  > div {
    @media (max-width: 768px) {
      padding-top: 4px;
    }
  }
`;
