import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Consolidado } from './Consolidado';

export const ContasPagarCostureiraRelatorios: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/relatorios/contas-pagar-costureira/consolidado"
        component={Consolidado}
        isPrivate
        exact
      />
    </Switch>
  );
};
