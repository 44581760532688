import React from 'react';
import { MdMoreHoriz } from 'react-icons/md';
import Popup from 'reactjs-popup';

import * as S from './styles';
import './default.css';

export const PopUpMenu: React.FC = ({ children, ...rest }) => {
  return (
    <Popup
      trigger={() => (
        <S.PopUpButton type="button">
          <MdMoreHoriz color="#707070" size={24} />
        </S.PopUpButton>
      )}
      position="bottom center"
      contentStyle={{
        width: '150px',
        borderRadius: '4px',
      }}
      {...rest}
    >
      <S.PopUpMenuConainer>{children}</S.PopUpMenuConainer>
    </Popup>
  );
};
