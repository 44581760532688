import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { parseISO } from 'date-fns';

import * as C from 'components';
import { useParams } from 'react-router-dom';
import { IOptionReactSelect, IRouterParams } from 'shared/interfaces';

import * as S from './styles';

import { plansMock, profilesMock } from './mock';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  description: Yup.string().required('Descrição obrigatória'),
  birthdate: Yup.date().required('Data Nascimento obrigatória'),
  date_test: Yup.date().required('Data prova obrigatória'),
  plan: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required('Plano é obrigatório'),
  profiles: Yup.array()
    .of(
      Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .nullable(),
    )
    .required('Perfil é obrigatório'),
  category: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required('Categoria é obrigatório'),
});

type IFormValues = {
  name: string;
  description: string;
  birthdate: Date;
  date_test: Date;
  plan: IOptionReactSelect;
  profiles: IOptionReactSelect[];
  category: IOptionReactSelect;
};

export function FormTest() {
  const { id } = useParams<IRouterParams>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = React.useState(false);

  const formData = watch();

  const onSubmit = React.useCallback(async () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  React.useEffect(() => {
    if (id) {
      const initValues: IFormValues = {
        name: 'Jonh',
        description: 'Software enginner',
        birthdate: parseISO('2021-05-23'),
        date_test: new Date(),
        plan: { label: 'Plano Bronze', value: '1' },
        profiles: [
          { label: 'Administrador', value: '1' },
          { label: 'Gerente', value: '2' },
        ],
        category: { label: '', value: '' },
      };
      reset(initValues);
    }

    // eslint-disable-next-line
  }, [id]);

  React.useEffect(() => {
    // const initValues: IFormValues = {
    //   name: '',
    //   description: '',
    //   birthdate: new Date(),
    //   date_test: new Date(),
    //   plan: { label: '', value: '' },
    //   profiles: [],
    //   category: { label: '', value: '' },
    // };
    // reset(initValues);
  }, []);

  return (
    <S.Container>
      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Data Nascimento</C.Label>
            <C.InputDatePicker
              {...register('birthdate')}
              control={control}
              errors={errors.birthdate}
              dateFormat="dd/MM/yyyy"
              selected={watch('birthdate')}
              name="birthdate"
              placeholder="Data Nascimento"
            />
          </C.FormGroup>
          <C.FormGroup>
            <C.Label>Data da Prova</C.Label>
            <C.InputDatePicker
              {...register('date_test')}
              control={control}
              errors={errors.date_test}
              name="date_test"
              showTimeSelect
              selected={watch('date_test')}
              highlightDates={[new Date()]}
              placeholder="Data da Prova"
            />
          </C.FormGroup>
          <C.FormGroup>
            <C.Label>Nome</C.Label>
            <C.Input
              type="text"
              {...register('name')}
              errors={errors.name}
              name="name"
              placeholder="Nome"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Descrição</C.Label>
            <C.Input
              type="text"
              {...register('description')}
              errors={errors.description}
              name="description"
              placeholder="Descrição"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Planos (Lista fixa)</C.Label>
            <C.ReactSelect
              {...register('plan')}
              name="plan"
              control={control}
              errors={errors.plan}
              options={plansMock}
              isClearable
              isSearchable
              noOptionsMessage="Nenhuma plano localizado"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Multi - Perfil (Lista fixa)</C.Label>
            <C.ReactSelect
              {...register('profiles')}
              name="profiles"
              control={control}
              errors={errors.profiles}
              options={profilesMock}
              isClearable
              isSearchable
              isMulti
              noOptionsMessage="Nenhuma plano localizado"
            />
          </C.FormGroup>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>

        <pre>{JSON.stringify(formData, null, 2)}</pre>
      </S.Panel>
    </S.Container>
  );
}
