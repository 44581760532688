import React from 'react';
import { IconBaseProps } from 'react-icons';

import * as S from './styles';

interface HeaderInfoProps {
  title: string | undefined;
  icon?: React.ComponentType<IconBaseProps>;
}

export const HeaderInfo: React.FC<HeaderInfoProps> = ({
  title,
  icon: Icon,
}) => {
  return (
    <S.Container>
      {Icon && <Icon size={24} />}
      <S.Title>{title}</S.Title>
    </S.Container>
  );
};
