import React, { useEffect, useState } from 'react';
import { MdCameraAlt } from 'react-icons/md';

import * as S from './styles';
import './styles';

interface Props {
  imageUrl?: string;
  fileSelected?: FileList;
}

const UploadBase: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { imageUrl = '', fileSelected = null, ...rest },
  ref,
) => {
  const [preview, setPreview] = useState(imageUrl);

  useEffect(() => {
    // setPreview('');

    if (fileSelected && fileSelected.length > 0) {
      const file = fileSelected[0];
      const previewURL = URL.createObjectURL(file);
      setPreview(previewURL);
    }
  }, [fileSelected]);

  useEffect(() => {
    if (imageUrl) {
      setPreview(imageUrl);
    }
  }, [imageUrl]);

  return (
    <S.Container preview={preview}>
      <input type="file" ref={ref} {...rest} />
      {!preview && <MdCameraAlt size={24} />}
    </S.Container>
  );
};

export const Upload = React.forwardRef(UploadBase);
