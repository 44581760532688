import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ClienteList } from './List';
import { ClienteForm } from './Form';

export const Cliente: React.FC = () => {
  return (
    <Switch>
      <Route path="/clientes" component={ClienteList} isPrivate exact />
      <Route path="/clientes/new" component={ClienteForm} isPrivate />
      <Route path="/clientes/edit/:id" component={ClienteForm} isPrivate />
    </Switch>
  );
};
