import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { FormaPagamentoList } from './List';
import { FormaPagamentoForm } from './Form';

export const FormaPagamento: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/formas-pagamento"
        component={FormaPagamentoList}
        isPrivate
        exact
      />
      <Route
        path="/formas-pagamento/new"
        component={FormaPagamentoForm}
        isPrivate
      />
      <Route
        path="/formas-pagamento/edit/:id"
        component={FormaPagamentoForm}
        isPrivate
      />
    </Switch>
  );
};
