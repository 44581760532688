import { ISignUp } from '../../../pages/SignUp/interfaces/index';
import api from '../../api';

export const singUp = async ({ name, email, password }: ISignUp) => {
  return api
    .post('users', {
      name,
      email,
      password,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
