import styled from 'styled-components';

export const Container = styled.table`
  width: 100%;
  border-collapse: collapse !important;
  color: var(--text);

  & thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    /* z-index: 2; */
    /* box-shadow: 1px 1px 1px 0px rgba(255, 255, 255, 0.3); */
  }

  & thead tr th {
    border-width: 0;
    border-style: solid;
  }

  & thead tr th:first-child {
    border-top-left-radius: 8px;
  }

  & thead tr th:last-child {
    border-top-right-radius: 8px;
  }

  & thead tr th {
    background: var(--background);
    border-width: 0;
    padding: 1.2rem;
  }

  & tbody tr td.btn-actions {
    svg {
      cursor: pointer;
    }
    & > svg + svg {
      margin-left: 16px;
    }
  }

  & tbody tr td {
    padding: 1.2rem;
    border-bottom: 1px solid var(--border-table);
  }

  & tbody > tr:last-child td {
    border-bottom: none;
  }

  & tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
