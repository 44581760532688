import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import { IPlanoContas, IPlanoContasForm } from './interfaces';

const createPlanoContasData = (conta: IPlanoContasForm) => {
  const data = {
    ...conta,
    plano_contas_pai_id: conta.plano_contas_pai_id.value,
  };

  return data;
};

const getPlanoConta = async (id: string): Promise<IPlanoContas> => {
  const { data } = await api.get<IPlanoContas>(`/plano-contas/${id}`);

  const contaFormatado = {
    ...data,
    plano_contas_pai_id: data?.plano_contas_pai?.id
      ? {
          label: String(data?.plano_contas_pai?.nome) || '',
          value: String(data?.plano_contas_pai?.id) || '',
        }
      : '',
  };

  return contaFormatado;
};

const getPlanoContas = async (
  params: IParams,
): Promise<IPaginate<IPlanoContas>> => {
  const response = await api.get<IPaginate<IPlanoContas>>('/plano-contas', {
    params,
  });

  return response.data;
};

const createPlanoContas = async (conta: IPlanoContasForm): Promise<void> => {
  const data = createPlanoContasData(conta);

  await api.post('/plano-contas', data);
};

const updatePlanoContas = async (
  id: string,
  conta: IPlanoContasForm,
): Promise<IPlanoContas> => {
  const data = createPlanoContasData(conta);

  const response = await api.put<IPlanoContas>(`/plano-contas/${id}`, data);

  return response.data;
};

const deletePlanoContas = async (id: string): Promise<void> => {
  await api.delete(`/plano-contas/${id}`);
};

export {
  getPlanoConta,
  getPlanoContas,
  createPlanoContas,
  updatePlanoContas,
  deletePlanoContas,
};
