// import { Tooltip } from 'components/Tooltip';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const Container = styled.div``;

export const DatePickerContainer = styled(DatePicker)`
  background: var(--background-input);
  border-radius: 4px;
  padding: 1.2rem;
  border: 1px solid var(--border-input);
  /* color: #999; */
  display: flex;
  align-items: center;
`;

export const ErrorMsg = styled.p`
  margin-top: 8px;
  margin-bottom: 8px;
  color: #c53030;
  font-weight: bold;
`;
