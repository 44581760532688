import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ContasPagarCostureiraList } from './List';
import { ContasPagarCostureiraForm } from './Form';
import { ContasPagarCostureiraPrint } from './Print';

export const ContasPagarCostureira: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/contas-pagar-costureira"
        component={ContasPagarCostureiraList}
        isPrivate
        exact
      />
      <Route
        path="/contas-pagar-costureira/new"
        component={ContasPagarCostureiraForm}
        isPrivate
      />
      <Route
        path="/contas-pagar-costureira/print"
        component={ContasPagarCostureiraPrint}
        isPrivate
      />
      <Route
        path="/contas-pagar-costureira/edit/:id"
        component={ContasPagarCostureiraForm}
        isPrivate
      />
    </Switch>
  );
};
