import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import * as S from './styles';
import { ItemSubMenu } from '../ItemSubMenu';

import { Can } from '../../Can';

interface Menu {
  name: string;
  route: string;
  icon?: ReactElement | null;
  perm?: string;
  children: Menu[];
}

interface Props {
  menu: Menu;
}

export const ItemMenu: React.FC<Props> = ({ menu }) => {
  return (
    <>
      <S.Item key={menu.name}>
        <NavLink
          to={menu.route}
          activeClassName={menu.children.length > 0 ? '' : 'sideBarLinkActive'}
        >
          {menu.icon}
          <S.LabelMenu>{menu.name}</S.LabelMenu>
          {menu.children.length > 0 && <S.IconArrowDown />}
        </NavLink>
        {menu.children.length > 0 && (
          <S.SubMenu className="submenu">
            {menu.children.map((child: Menu) => (
              <Can key={child.name} checkPermission={child.perm || ''}>
                <ItemSubMenu menu={child} />
              </Can>
            ))}
          </S.SubMenu>
        )}
      </S.Item>
    </>
  );
};
