import { MdClose } from 'react-icons/md';
import styled from 'styled-components';

interface ModalOptionsProps {
  width?: string;
  zIndex?: number;
}

interface ContainerProps {
  visible: boolean;
  options?: ModalOptionsProps;
}

export const Container = styled.div<ContainerProps>`
  display: table;
  position: fixed;
  z-index: ${(props) => (props.options?.zIndex ? props.options?.zIndex : 4)};
  background: var(--background);
  color: var(--text);
  visibility: ${(props) => (props.visible ? 'unset' : 'hidden')};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  max-width: ${(props) =>
    props.options?.width ? props.options?.width : 'fit-content'};
  width: ${(props) =>
    props.options?.width ? props.options?.width : 'fit-content'};

  height: fit-content;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`;

export const IconClose = styled(MdClose)`
  position: absolute;
  right: 16px;
  margin-top: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 4px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  cursor: pointer;

  transition: background 0.2s;

  &:hover {
    /* background: rgba(0, 0, 0, 0.1); */
    /* background: rgba(255, 255, 255, 0.3); */
    background: var(--backgroun-icon-hover);
  }
`;
