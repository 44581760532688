import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ContasPagarBaixasList } from './List';
import { ContasPagarBaixasForm } from './Form';
import { ContasPagarBaixasPrint } from './Print';

export const ContasPagarBaixas: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/baixas/contas-pagar"
        component={ContasPagarBaixasList}
        isPrivate
        exact
      />
      <Route
        path="/baixas/contas-pagar/new"
        component={ContasPagarBaixasForm}
        isPrivate
      />
      <Route
        path="/baixas/contas-pagar/print"
        component={ContasPagarBaixasPrint}
        isPrivate
      />
      <Route
        path="/baixas/contas-pagar/edit/:id"
        component={ContasPagarBaixasForm}
        isPrivate
      />
    </Switch>
  );
};
