import { IPaginate, IParams } from 'shared/interfaces';
import {
  IFormaPagamento,
  IFormaPagamentoForm,
} from 'pages/FormaPagamento/interfaces';

import api from '../../api';

const getFormaPagamento = async (id: string): Promise<IFormaPagamento> => {
  const response = await api.get<IFormaPagamento>(`/formas-pagamento/${id}`);

  return response.data;
};

const getFormasPagamento = async (
  params: IParams,
): Promise<IPaginate<IFormaPagamento>> => {
  const response = await api.get<IPaginate<IFormaPagamento>>(
    '/formas-pagamento',
    {
      params,
    },
  );

  return response.data;
};

const createFormaPagamento = async (
  formaPagamento: IFormaPagamentoForm,
): Promise<void> => {
  await api.post('/formas-pagamento', formaPagamento);
};

const updateFormaPagamento = async (
  id: string,
  formaPagamento: IFormaPagamentoForm,
): Promise<IFormaPagamento> => {
  const response = await api.put<IFormaPagamento>(
    `/formas-pagamento/${id}`,
    formaPagamento,
  );

  return response.data;
};

const deleteFormaPagamento = async (id: string): Promise<void> => {
  await api.delete(`/formas-pagamento/${id}`);
};

export {
  getFormaPagamento,
  getFormasPagamento,
  createFormaPagamento,
  updateFormaPagamento,
  deleteFormaPagamento,
};
