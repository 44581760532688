import styled, { css } from 'styled-components';

interface ContainerProps {
  align?: 'left' | 'center' | 'right';
}

export const Container = styled.div<ContainerProps>`
  display: flex;

  ${(props) =>
    props.align === 'left' &&
    css`
      justify-content: flex-start;
    `}
  ${(props) =>
    props.align === 'center' &&
    css`
      justify-content: center;
    `}
  ${(props) =>
    props.align === 'right' &&
    css`
      justify-content: flex-end;
    `}

    align-items: center;
`;
